import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import { useTranslation } from 'react-i18next';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import { connect } from 'react-redux';
import { getBetValue } from '../../../../../../common/services/data-service';
import infoImg from '../../../../../../images/redesign/player/info.svg';

const EndResultFines = React.memo(({
  points,
  totalPnts,

  myPos,
  bet,
  party,
  gameType,
  closeReason,

  player1ShortName,
  player2ShortName,
  player3ShortName,
  player4ShortName,

  player1Uid,
  player2Uid,
  player3Uid,
  player4Uid,
  fetchPartyLog,
  screenMode,
}) => {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      <Row className="end-result-table-score">
        <Col sm="12">
          <Row className="end-result-table-header">
            <Col className="end-result-table-header-col">
              #
            </Col>
            <Col className="end-result-table-header-col">
              {
                myPos === 'player1' && player2ShortName
              }
              {
                myPos === 'player2' && player3ShortName
              }
              {
                myPos === 'player3' && player1ShortName
              }
            </Col>
            <Col className="end-result-table-header-col">
            {
                myPos === 'player1' && player1ShortName
              }
              {
                myPos === 'player2' && player2ShortName
              }
              {
                myPos === 'player3' && player3ShortName
              }
            </Col>
            <Col className="end-result-table-header-col">
            {
                myPos === 'player1' && player3ShortName
              }
              {
                myPos === 'player2' && player1ShortName
              }
              {
                myPos === 'player3' && player2ShortName
              }
            </Col>
            <Col className="end-result-table-header-col justify-content-center">
              {t('game:pules')}
            </Col>
            <Col className="end-result-table-header-col" />
          </Row>
          <ScrollAreaWrapper
            className="end-result-table-scrollarea end-result-table-scrollarea-scoretable"
            contentClassName="end-result-table-body"
            show
            rightOffset={0}
            topOffset={23}
            bottomOffset={-3}
            noScrollButton={true}
          >
            {points && Object.keys(points).map((key, index) => {
              return (
                <Row
                  key={key}
                  className={`end-result-table-row`}
                >
                  <Col className="end-result-table-col d-flex align-self-center">
                    {points[key].id}
                  </Col>
                  <Col className="end-result-table-header-col">
                    {
                      myPos === 'player1' && (
                        <div className={`${points[key].player2 !== undefined && points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 !== undefined && points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player2 !== undefined ? points[key].player2 : 0}
                        </div>
                      )
                    }
                    {
                      myPos === 'player2' && (
                        <div className={`${points[key].player3 !== undefined && points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 !== undefined && points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player3 !== undefined ? points[key].player3 : 0}
                        </div>
                      )
                    }
                    {
                      myPos === 'player3' && (
                        <div className={`${points[key].player1 !== undefined && points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 !== undefined && points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player1 !== undefined ? points[key].player1 : 0}
                        </div>
                      )
                    }
                    
                  </Col>
                  <Col className="end-result-table-header-col">
                    {
                      myPos === 'player1' && (
                        <div className={`${points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player1}
                        </div>
                      )
                    }
                    {
                      myPos === 'player2' && (
                        <div className={`${points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player2}
                        </div>
                      )
                    }
                    {
                      myPos === 'player3' && (
                        <div className={`${points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player3}
                        </div>
                      )
                    }
                    
                  </Col>
                  <Col className="end-result-table-header-col">
                    {
                      myPos === 'player1' && (
                        <div className={`${points[key].player3 === 0 ? 'player-history-table-col-neutral' : (points[key].player3 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player3}
                        </div>
                      )
                    }
                    {
                      myPos === 'player2' && (
                        <div className={`${points[key].player1 === 0 ? 'player-history-table-col-neutral' : (points[key].player1 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player1}
                        </div>
                      )
                    }
                    {
                      myPos === 'player3' && (
                        <div className={`${points[key].player2 === 0 ? 'player-history-table-col-neutral' : (points[key].player2 > 0 ? 'player-history-table-col-positive' : 'player-history-table-col-negative')}`}>
                          {points[key].player2}
                        </div>
                      )
                    }
                    
                  </Col>

                  <Col className="end-result-table-col d-flex align-items-center">
                    {points[key].pule === 'P' && (
                      <div> P </div>
                    )}
                    {points[key].pule === 'P-' && (
                      <span className={`strikeout-new-${screenMode}`}> P </span>
                    )}
                    {points[key].pule === 'player1' && (
                      <div className="score-table-pules-col-div">
                        { player1ShortName }
                      </div>
                    )}
                    {points[key].pule === 'player1-' && (
                      <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                        { player1ShortName }
                      </span>
                    )}
                    {points[key].pule === 'player2' && (
                      <div className="score-table-pules-col-div">
                        { player2ShortName }
                      </div>
                    )}
                    {points[key].pule === 'player2-' && (
                      <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                        { player2ShortName }
                      </span>
                    )}
                    {points[key].pule === 'player3' && (
                      <div className="score-table-pules-col-div">
                        { player3ShortName }
                      </div>
                    )}
                    {points[key].pule === 'player3-' && (
                      <span className={`score-table-pules-col-div strikeout-new-${screenMode}`}>
                        { player3ShortName }
                      </span>
                    )}
                  </Col>
                  <Col className="end-result-table-col end-result-table-col-last">
                    <Media className="end-result-table-col-last-info-button" onClick={() => fetchPartyLog(index + 1)} src={infoImg} alt="x" />
                    {/* <Button color="link" className="menu-table-row-default-button" onClick={() => fetchPartyLog(index + 1)}>
                      Info
                    </Button> */}
                  </Col>
                </Row>
              );
            })}
          </ScrollAreaWrapper>
        </Col>
      </Row>
    </Fragment>
  );
});

EndResultFines.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
};

EndResultFines.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
};

const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  }
  return null;
};

export default connect(mapStateToProps, null)(EndResultFines);
