import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

import { map, filter, includes, intersection, size } from 'lodash';
// import Lottie from 'react-lottie';

import ReactGA from 'react-ga';
import Iframe from 'react-iframe';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
// import ModalFooter from 'reactstrap/lib/ModalFooter';

// import { loadStripe } from '@stripe/stripe-js';

import moment from 'moment';
import Moment from 'react-moment';

import ScrollAreaWrapper from '../Components/ScrollAreaWrapper';
import RedeemGiftCode from './Shop/RedeemGiftCode';

import CustomDate from '../Components/Components/CustomDate';

import config from '../../../../constants/config';
import * as constants from '../../../../constants/constants';
import { generalMessages } from '../../../../constants/messages';

// import InviteFriend from './InviteFriend';

import coins1 from '../../../../images/redesign/buy-page/coins_1.webp';
import coins2 from '../../../../images/redesign/buy-page/coins_2.webp';
import coins3 from '../../../../images/redesign/buy-page/coins_3.webp';
import coins4 from '../../../../images/redesign/buy-page/coins_4.webp';
import coins5 from '../../../../images/redesign/buy-page/coins_5.webp';

// import shopImg from '../../../images/icons/shoping_cart.webp';

import bonus1 from '../../../../images/icons/money_bonus_1.svg';
import bonus2 from '../../../../images/icons/money_bonus_2.svg';
import bonus3 from '../../../../images/icons/money_bonus_3.svg';
import bonus4 from '../../../../images/icons/money_bonus_4.svg';

import coinImg from '../../../../images/redesign/common/coin.svg';
// import glitterAnimImg from '../../../images/BuyMoney/glitter.json';

import balanceResetImg from '../../../../images/redesign/buy-page/balance_reset.svg';
import pointsResetImg from '../../../../images/redesign/buy-page/points_reset.svg';
import profileResetImg from '../../../../images/redesign/buy-page/profile_reset.svg';
import hideAdsMonthImg from '../../../../images/redesign/buy-page/hideAdsMonth.svg';
import hideAdsYearImg from '../../../../images/redesign/buy-page/hideAdsYear.svg';

import noTransactionImgDark from '../../../../images/redesign/buy-page/no-transactions.svg';
import noTransactionImgLight from '../../../../images/redesign/light-mode/buy-page/no-transactions.svg';



import infoImg from '../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../images/redesign/light-mode/player/info.svg';
import mobileImg from '../../../../images/redesign/buy-page/mobile.png';

import closeImg from '../../../../images/icons/close.png';

import buttonClickedSound from '../../../../sounds/click_feedback.flac';
import DateFormat from '../../UI/DateFormat';
import DiscountTimer from '../Components/Components/Timer';
import CustomModal from '../Components/Components/Modal';
import PaymentTypeContent from './Modals/PaymentTypeContent';

import {
  initFBPayment,
  initDraugiemPayment,
  initInboxPayment,
  initEveryPayPayment,
  fbPaymentCallback,
  initStripePayment,
  getTransactionsHistory,
  claimGiftCode,
  verifyGiftCode,
} from '../../../../actions/member';

import {
  getDiscounts, setLastShopTab, setTransactionComplete
} from '../../../../actions/shop';

class BuyPage extends React.Component {
  static propTypes = {
    //  t: PropTypes.func.isRequired,
    initFBPay: PropTypes.func.isRequired,
    fbCallback: PropTypes.func.isRequired,
    initDraugiemPay: PropTypes.func.isRequired,
    initInboxPay: PropTypes.func.isRequired,
    initStripePay: PropTypes.func.isRequired,
    initEveryPay: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fetchTransactionsHistory: PropTypes.func.isRequired,
    totalPoints: PropTypes.number,
    balance: PropTypes.number,
    parentActiveTab: PropTypes.string,
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    toggleFullscreen: PropTypes.func,
    socProvider: PropTypes.string,
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
   // }),
    claimGift: PropTypes.func.isRequired,
    verifyGift: PropTypes.func.isRequired,
    switchToggle: PropTypes.func.isRequired,
    toggleStripeLoading: PropTypes.func.isRequired,
    screenMode: PropTypes.bool,
    fetchDiscounts: PropTypes.func.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
    toggleAboutUs: PropTypes.func.isRequired,
    toggleTerms: PropTypes.func.isRequired,
    setLastShopTabFunc: PropTypes.func.isRequired,
    lastShopTab: PropTypes.string,
    transactionComplete: PropTypes.bool,
  }

  static defaultProps = {
    totalPoints: null,
    balance: null,
    parentActiveTab: null,
    isFullscreen: false,
  //  userSettings: {},
    socProvider: null,
    screenMode: constants.SCREEN_MODE.normal,
    discounts: []
  }

  constructor(props) {
    super(props);
    this.state = {
      // activeTab: this.props.parentActiveTab === constants.MENU_NAVIGATION.giftPage ? constants.SHOP_NAVIGATION.discountEvent : this.props.parentActiveTab === constants.MENU_NAVIGATION.resetBalancePage ? constants.SHOP_NAVIGATION.premiumPacks : constants.SHOP_NAVIGATION.moneyPacks,
      pauseGlitter: [true, true, true, true, true],
      processing: false,
      activeHover: null,
      showInboxPayment: false,
      inboxIframeLink: null,
      historyData: [],
      scrollPos: 0,
      code: '',
      loading: false,
      selectCode: '',
      selectValue: '',
      redeemFlag: false,
      verifiedCode: '',
      giftStatus: '',
      giftMessage: '',
      giftMessageVisible: false,
      curDate: new Date(),
      curDay: 0,
      buyModal: false,
      paymentErrorModal: false,
      selectedPaymentOption: constants.PAYMENT_TYPES.swedbank,
      selectedProduct: {},
      selectedProductId: 0,
      isOnline: navigator.onLine,
      devMode: false,
      checkedTerms: false,
      paymentError: '',
      smsPaymentModal: false,
      curState: null,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  componentDidMount() {
    const { t } = this.props;
    const { pauseGlitter, curDate, curDay } = this.state;
    const pauseGlitterCopy = [...pauseGlitter];

    const { fetchDiscounts } = this.props;
    const { hostname } = window.location;

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);

    let devModeTemp = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devModeTemp = true;
      this.setState({
        devMode: devModeTemp,
      });
    }


    fetchDiscounts(devModeTemp);

    this.intervalID = setInterval(() => {
      this.setState({
        curDate: Date.now(),
      });
      if (curDay !== curDate.getDate()) {
        this.setState({
          curDay: curDate.getDate(),
        });
      }
    }, 1000);


    setTimeout(() => {
      pauseGlitterCopy[0] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 0);

    setTimeout(() => {
      pauseGlitterCopy[4] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 270);

    setTimeout(() => {
      pauseGlitterCopy[1] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 500);

    setTimeout(() => {
      pauseGlitterCopy[3] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 750);

    setTimeout(() => {
      pauseGlitterCopy[2] = false;
      this.setState({ pauseGlitter: pauseGlitterCopy });
    }, 1000);

    this.setState({
      giftMessage: generalMessages.enterGiftCode,
    });

    this.fetchTransactionsHistory();
  }

  componentDidUpdate(prevProps, prevState) {
    const { parentActiveTab: prevParentActiveTab } = prevProps;
    const { parentActiveTab, lastShopTab, transactionComplete, setTransactionCompleteFunc, switchToggle } = this.props;

    const { activeTab: prevActiveTab } = prevState;
    const { activeTab, showInboxPayment } = this.state;

    console.log('showInboxPayment', showInboxPayment, transactionComplete);

    if (lastShopTab && transactionComplete) {
      // this.setState({
      //   activeTab: lastShopTab === 'moneyPacks' ? constants.SHOP_NAVIGATION.moneyPacks : constants.SHOP_NAVIGATION.premiumPacks,
      // });
      switchToggle(lastShopTab === 'moneyPacks' ? constants.MENU_NAVIGATION.buyPageMoneyPacks : constants.MENU_NAVIGATION.buyPagePremiumPacks);
      setTransactionCompleteFunc(false);

      if (showInboxPayment) {
        this.closeInboxModal();
      }
    }

    // if (prevParentActiveTab === constants.MENU_NAVIGATION.giftPage) {
    //   if (activeTab !== constants.MENU_NAVIGATION.buyPage) {
    //     this.setState({
    //       activeTab: constants.SHOP_NAVIGATION.discountEvent,
    //     });
    //   }
    // }
    // if (prevParentActiveTab !== parentActiveTab) {

    //   if (parentActiveTab === constants.MENU_NAVIGATION.resetBalancePage) {
    //     this.setState({
    //       activeTab: constants.SHOP_NAVIGATION.premiumPacks,
    //     });
    //   }
    //   if (parentActiveTab === constants.MENU_NAVIGATION.moneyPacks) {
    //     this.setState({
    //       activeTab: constants.SHOP_NAVIGATION.moneyPacks,
    //     });
    //   }
    // }

    // if (activeTab !== prevActiveTab && activeTab === constants.SHOP_NAVIGATION.redeemGiftCode) {
    //   this.setState({giftMessageVisible: false});
    // }

    // console.log(activeTab, prevParentActiveTab, parentActiveTab, "check parent activate tab");
  }

  componentWillUnmount() {
    try {
      clearInterval(this.intervalID);
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
    } catch (err) {
      console.log('err componentWillUnmount');
    }
  }

  handleOnline = () => {
    this.setState({ isOnline: true });
  }

  handleOffline = () => {
    this.setState({ isOnline: false });
  }

  toggle = (tab) => {
    const { switchToggle, parentActiveTab } = this.props;
    // const { activeTab } = this.state;
    if (parentActiveTab !== tab) {
      // this.setState({
      //   activeTab: tab,
      // });
      switchToggle(tab);

      if (tab === constants.MENU_NAVIGATION.buyPageMoneyPacks) {
        ReactGA.event({
          category: 'Menu',
          action: 'Shop - Money Packages',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPagePremiumPacks) {
        ReactGA.event({
          category: 'Menu',
          action: 'Shop - Premium Packages',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPageHistory) {
        ReactGA.event({
          category: 'Menu',
          action: 'Open Purchase History',
        });
      } else if (tab === constants.MENU_NAVIGATION.buyPageRedeemCode) {
        ReactGA.event({
          category: 'Menu',
          action: 'Shop - Redeem Gift',
        });
      }

      /*  if (tab === '3') {
          ReactGA.event({
            category: 'Menu',
            action: 'Open Purchase History',
          });
        } */
    }
  }

  onPaymentOptionClick = (option) => {
    this.setState({
      selectedPaymentOption: option
    });
  }

  toggleBuyModal = () => {
    this.setState({buyModal: !this.state.buyModal});
  }

  togglePaymentErrorModal = (paymentError) => {
    this.setState({
      paymentErrorModal: !this.state.paymentErrorModal,
      paymentError
    });
  }
  
  pay = () => {
    const { selectedPaymentOption, selectedProduct, selectedProductId, checkedTerms } = this.state;
    const { toggleStripeLoading, initEveryPay, t, i18n } = this.props;

    if (!checkedTerms) {
      this.togglePaymentErrorModal(t('paymentType.acceptTerms'));
    } else if (![constants.PAYMENT_TYPES.paypal.id].includes(selectedPaymentOption.id)) {
      if (constants.PAYMENT_TYPES.textMessage.id !== selectedPaymentOption.id) {
        toggleStripeLoading(true);

        initEveryPay(selectedProduct, selectedPaymentOption, selectedProductId, i18n.language).then((res) => {
          const paymentLink = res.data.payment_link;
          if (paymentLink) {
            window.location.href = paymentLink;
          }
        }).catch((error) => {
          toggleStripeLoading(false);
          console.log(error);
        });
      } else {
        this.toggleSmsPaymentModal();
      }
    } else {
      this.togglePaymentErrorModal(t('paymentType.notAvailable'));
    }
  }
  

  buyMoney = (prod) => {
    const {
      initFBPay, fbCallback, socProvider, initDraugiemPay, initInboxPay, toggleStripeLoading, discounts, isFullscreen, isFullscreenEnabled, toggleFullscreen, setLastShopTabFunc,
    } = this.props;
    const { devMode, curState, processingPayClick } = this.state;

    if (prod <= 5) {
      setLastShopTabFunc('moneyPacks');
    } else {
      setLastShopTabFunc('premiumPacks');
    }

    console.log('buyMoney processingPayClick', processingPayClick);

    if (processingPayClick) {
      return null;
    }

    this.setState({ processingPayClick: true });

    if (config.isInAppFrame() && isFullscreenEnabled) {
      toggleFullscreen();
    }

    if (!config.isInAppFrame()) {
      this.playButtonSound();

      this.setState({ processingPayClick: false });

      if (devMode || !devMode) {
        const selectedProduct = { ...constants.BUY_PAGE_PRODUCTS[prod] };
        if (discounts && discounts[0] && discounts[0].type === constants.SPECIALPRICE && curState === constants.CURRENT) {
          selectedProduct.realPrice = discounts[0].specialPrice[`money${prod}`];
          selectedProduct.discountType = constants.SPECIALPRICE;
        } else if (discounts && discounts[0] && discounts[0].type === constants.DISCOUNTPRICE && curState === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === prod; })[0];
          selectedProduct.discountType = constants.DISCOUNTPRICE;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = parseFloat(productMoney.realPrice - ((productMoney.realPrice/100) * discounts[0].discountPrice)).toFixed(2)
          }
        } else if (discounts && discounts[0] && discounts[0].type === constants.BONUS && curState === constants.CURRENT) {
          const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === prod; })[0];
          selectedProduct.discountType = constants.BONUS;

          if (includes(discounts[0].selectedDiscountPackage, productMoney.label)) {
            selectedProduct.realPrice = discounts[0].discountPrice;
          }
        }
       
        this.setState({ selectedProduct, selectedProductId: prod });
        this.toggleBuyModal();
      } else {
        // this.playButtonSound();

       //  this.setState({ processing: true });
       //  toggleStripeLoading(true);
       //  const { initStripePay } = this.props;

         //  const failTxt = t('menu.stripeShopNotWorking');

         /* initStripePay(prod).then((result) => {
           if (result.data && result.data.data.status && result.data.data.status === 'success' && result.data.data.id) {
             const paymentSessionId = result.data.data.id;

             loadStripe(config.stripeBublicKey).then((stripe) => {
               stripe.redirectToCheckout({
                 sessionId: paymentSessionId,
               })
                 .then(() => {
                   //  alert(failTxt);
                   toggleStripeLoading(false);
                   this.setState({ processing: false });
                 });
             }).catch(() => {
               //  alert(failTxt);
               toggleStripeLoading(false);
               this.setState({ processing: false });
             });
           } else if (result.data.data && !result.data.data.status) {
             //  alert(failTxt);
             toggleStripeLoading(false);
             this.setState({ processing: false });
           } else if (result.data.data && result.data.data.status && result.data.data.status === 'error') {
             //  alert(failTxt);
             toggleStripeLoading(false);
             this.setState({ processing: false });
           }
         }).catch((e) => {
           //  alert(failTxt);
           console.log(e);
           toggleStripeLoading(false);
           this.setState({ processing: false });
         }); */
      }

      // return;
    } else if (socProvider === 'facebook') { // In App Iframe (fb/dra)
      this.playButtonSound();

      this.setState({ processingPayClick: false });

      initFBPay(prod).then((res) => {
        console.log(res.data.data);
        if (res.data && res.data.data && res.data.data.status === 'success') {
          const { product } = res.data.data;

          if (product) {
            if (window.FB && socProvider === 'facebook') {
              window.FB.ui({
                method: 'pay',
                action: 'purchaseitem',
                product,
                quantity: 1, // optional, defaults to 1
                request_id: res.data.data.token, // optional, must be unique for each payment
              },
                (resp) => {
                  console.log('fb pay resp', { resp });
                  fbCallback(resp);
                  return null;
                });
            } else {
              console.log('no FB.ui or not facebook provider');
            }
          } else {
            console.log('error with product');
          }
        }
      }).catch((err) => {
        this.setState({ processingPayClick: false });
      });
    } else if (socProvider === 'draugiem') {
      this.playButtonSound();

      this.setState({ processingPayClick: false });

      initDraugiemPay(prod).then((res) => {
        console.log('res');
        console.log(res.data.data);
        if (res.data && res.data.data && res.data.data.status === 'success') {
          const { product } = res.data.data;

          if (product) {
            if (window.draugiemWindowOpen && socProvider === 'draugiem') {
              window.draugiemWindowOpen(product, 350, 400);
            } else {
              console.log('no draugiemWindowOpen or not draugiem provider');
            }
          } else {
            console.log('error with product');
          }
        }
      }).catch((err) => {
        this.setState({ processingPayClick: false });
      });
    } else if (socProvider === 'inbox') {
      this.playButtonSound();
      initInboxPay(prod).then((res) => {
        this.setState({ processingPayClick: false });

        console.log('res');
        console.log(res.data.data);
        if (res.data && res.data.data && res.data.data.status === 'success') {
          const { product } = res.data.data;

          if (product) {
            this.setState({ showInboxPayment: true, inboxIframeLink: product });
          } else {
            console.log('error with product');
          }
        }
      }).catch((err) => {
        this.setState({ processingPayClick: false });
      });
    }
  }

  closeInboxModal = () => {
    console.log('closeInboxModal');
    this.setState({ showInboxPayment: false, inboxIframeLink: null });
  }

  mouseEnter = (key) => {
    //  console.log(key);

    this.setState({ activeHover: key });
  }

  mouseLeave = () => {
    //  console.log(key);

    this.setState({ activeHover: null });
  }

  playButtonSound = () => {
    const { soundOn } = this.props;

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  }


  fetchTransactionsHistory = () => {
    const { fetchTransactionsHistory } = this.props;

    fetchTransactionsHistory().then((res) => {
      if (res) {
        const array = [];

        Object.keys(res).map((key) => {
          array.push({ key, ...res[key] });
          return null;
        });

        array.sort((a, b) => (b.dateInitiated - a.dateInitiated));

        this.setState({ openHistory: false, historyData: array });

        //  this.toggle('3');
      } else {
        this.setState({ openHistory: false, historyData: [] });

        //  this.toggle('3');
      }
    });
  }

  closeHistory = () => {
    this.setState({ openHistory: false, historyData: null });
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.messagesScrollbar.scrollYTo(newVal);
  }

  handleInputChange = (val) => {
    this.setState({
      code: val,
    });
  }

  verifyGift = () => {
    const {
      verifyGift,
      t,
    } = this.props;

    const {
      code,
    } = this.state;

    this.setState({ loading: true });
    let giftMessage = '';
    let giftStatus = '';
    if (code.length > 10 || code.length <= 3) {
      return this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: generalMessages.giftCodeLongFailed,
        giftMessageVisible: true,
      });
    }

    verifyGift(code).then((res) => {
      if (res.status === constants.FROUNT_SIDE_STATUS.success) {
        // showNotification(t('common.success'), t('common.verifySuccess'), 'success');
        this.setState({
          loading: false,
          selectCode: res.selCode,
          selectValue: res.selVal,
          verifiedCode: code,
          giftMessageVisible: true,
          giftStatus: constants.FROUNT_SIDE_STATUS.success,
          giftMessage: generalMessages.verifySuccessMessage,
        });
      }
      if (res.status === constants.FROUNT_SIDE_STATUS.error) {
        giftStatus = constants.FROUNT_SIDE_STATUS.error;
        if (res.error === constants.GIFT_CODE_ERROR_STATUS.missingGiftCode) {
          giftMessage = generalMessages.enterGiftCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.wrongCode) {
          giftMessage = generalMessages.wrongCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.codeExpired) {
          giftMessage = generalMessages.codeExpire;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.limitClaim) {
          giftMessage = generalMessages.limitClaim;
        } else {
          giftMessage = res.error;
          // showNotification(t('common.error!'), res.error, 'danger');
        }
        this.setState({
          loading: false,
          giftStatus,
          giftMessage,
          giftMessageVisible: true,
        });
      } else {
        this.claimGift();
      }
    }).catch(error => {
      this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: error.message,
        giftMessageVisible: true,
      });
    });
  }

  claimGift = () => {
    const {
      claimGift,
      t,
    } = this.props;

    const {
      verifiedCode,
      selectValue,
      selectCode,
      code,
    } = this.state;
    this.setState({ loading: true });
    let giftMessage = '';
    let giftStatus = '';
    if (code.length > 10) {
      return this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: generalMessages.giftCodeLongFailed,
        giftMessageVisible: true,
      });
    }
    claimGift(verifiedCode).then(res => {
      if (res.status === constants.FROUNT_SIDE_STATUS.success) {
        this.setState({
          redeemFlag: true,
          verifiedCode: '',
          giftStatus: constants.FROUNT_SIDE_STATUS.completed,
          giftMessage: generalMessages.claimSuccessMessage,
          code: '',
          loading: false,
          giftMessageVisible: true,
        });
      }
      if (res.status === constants.FROUNT_SIDE_STATUS.error) {
        giftStatus = constants.FROUNT_SIDE_STATUS.error;
        if (res.error === constants.GIFT_CODE_ERROR_STATUS.missingGiftCode) {
          giftMessage = generalMessages.enterGiftCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.wrongCode) {
          giftMessage = generalMessages.wrongCode;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.codeExpired) {
          giftMessage = generalMessages.codeExpire;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.limitClaim) {
          giftMessage = generalMessages.limitClaim;
        } else if (res.error === constants.GIFT_CODE_ERROR_STATUS.alreadyClaimed) {
          giftMessage = generalMessages.alreadyClaimed;
        } else {
          giftMessage = res.error;
        }
        this.setState({
          loading: false,
          giftStatus,
          giftMessage,
          giftMessageVisible: true,
        });
      }
    }).catch((error) => {
      this.setState({
        loading: false,
        giftStatus: constants.FROUNT_SIDE_STATUS.error,
        giftMessage: error.message,
        giftMessageVisible: true,
      });
      // showNotification(t('common.error!'), error.message, 'danger');
    });
  }

  renderSpecialAndDiscount = (product, discount, processing) => {
    const { screenMode, balance, totalPoints } = this.props;
    const { curState } = this.state;

    const productMoney = filter(constants.SHOP_PRODUCT_TYPES, (item) => { return item.id === product; })[0];

    if (includes(discount.selectedDiscountPackage, productMoney.label)) {
      if (discount.type === constants.SPECIALPRICE) {
        return (
          <>
            <Button
              disabled={curState === constants.CURRENT}
              style={{ textDecorationLine: 'line-through' }}
              color="link"
              className={classNames('layout-default-button', {
                'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                'layout-redHighlighted-disabled-button': screenMode === constants.SCREEN_MODE.light,
              })}
              onClick={() => this.buyMoney(product)}
            >
              {`${productMoney.realPrice} EUR`}
            </Button>
            <Button
              disabled={
                product === 6 ? (processing || curState === constants.PLANNED || (!balance || (balance > 0)))
                  : product === 7 ? (processing || curState === constants.PLANNED || (!totalPoints || (totalPoints > 0)))
                    : processing || curState === constants.PLANNED
              }
              color="link"
              className='layout-default-button layout-redHighlighted-button'
              onClick={() => this.buyMoney(product)}
            >
              {`${discount.specialPrice[product < 10 ? `money${product}` : product === 10 ? 'hideAdsMonth' : 'hideAdsYear']} EUR`}
            </Button>
          </>
        )
      }
      return (
        <>
          <Button
            disabled={curState === constants.CURRENT}
            style={{ textDecorationLine: curState === constants.CURRENT ? 'line-through' : '' }}
            color="link"
            className={classNames('layout-default-button', {
              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
              'layout-redHighlighted-disabled-button': screenMode === constants.SCREEN_MODE.light,
            })}
            onClick={() => this.buyMoney(product)}
          >
            {`${productMoney.realPrice} EUR`}
          </Button>
          <Button
            disabled={
              product === 6 ? (processing || curState === constants.PLANNED || (!balance || (balance > 0)))
                : product === 7 ? (processing || curState === constants.PLANNED || (!totalPoints || (totalPoints > 0)))
                  : processing || curState === constants.PLANNED
            }
            color="link"
            className='layout-default-button layout-redHighlighted-button'
            onClick={() => this.buyMoney(product)}
          >
            {`${parseFloat(productMoney.realPrice - ((productMoney.realPrice/100) * discount.discountPrice)).toFixed(2)} EUR`}
          </Button>
        </>
      );
    }
    return (
      <Button
        disabled={
          product === 6 ? (processing || (!balance || (balance > 0)))
            : product === 7 ? (processing || (!totalPoints || (totalPoints > 0)))
              : processing
        }
        color="link"
        className={classNames('layout-default-button', {
          'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
          'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
        })}
        onClick={() => this.buyMoney(1)}
      >
        {`${productMoney.realPrice} EUR`}
      </Button>
    );
  }

  renderBonus = (product, discount, processing) => {
    return (
      <div className="buy-page-coins-wrapper-bonus-coins-amount">
        <Media src={coinImg} className="buy-page-coins-coin" />
        <span>{`+${discount.discountPrice}`}</span>
      </div>
    )
  }

  toggleCheckedTerms = () => {
    this.setState(prevState => ({
      checkedTerms: !prevState.checkedTerms
    }));
  };

  toggleSmsPaymentModal = () => {
    this.setState(prevState => ({
      smsPaymentModal: !prevState.smsPaymentModal,
    }));
  };

  handleCurState = (state) => {
    this.setState({
      curState: state
    })
  }

  render() {
    const {
      t,
      i18n,
      socProvider,
      balance,
      totalPoints,
      parentActiveTab,
      isFullscreen,
      screenMode,
      discounts,
      toggleAboutUs,
      toggleTerms,
      switchToggle,
      adsHidden,
    } = this.props;

    const {
      pauseGlitter,
      processing,
      activeHover,
      showInboxPayment,
      inboxIframeLink,
      openHistory,
      historyData,
      scrollPos,
      code,
      loading,
      selectCode,
      selectValue,
      redeemFlag,
      verifiedCode,
      giftStatus,
      giftMessage,
      giftMessageVisible,
      curDate,
      curDay,
      buyModal,
      paymentErrorModal,
      selectedPaymentOption,
      selectedProduct,
      isOnline,
      checkedTerms,
      paymentError,
      smsPaymentModal,
      devMode,
      curState
    } = this.state;

    console.log(balance, totalPoints, "checkPB", parentActiveTab, constants.MENU_NAVIGATION.buyPage, constants.MENU_NAVIGATION.buyPageMoneyPacks);

    return (
      <div className="layout-body layout-body-background">
        {(parentActiveTab && (constants.BUY_PAGE_TABS.includes(parentActiveTab))) ? (
          <Helmet>
            <title>
              Zole - {t('route.buyPage')}
            </title>
          </Helmet>
        ) : (null)}
        <div className="layout-body-top layout-body-top-buy-page">
          <Row className="layout-subheader">
            <Col xs="12" className="layout-subheader-title">{t('route.buyPage')}</Col>
            <Col xs="12" className="noPadding">
              <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${parentActiveTab === constants.MENU_NAVIGATION.buyPage || parentActiveTab === constants.MENU_NAVIGATION.buyPageMoneyPacks ? 'layout-subheader-link-text-active' : null}`} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPageMoneyPacks)}>
                  {t('buyPage.moneyPacks')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${parentActiveTab === constants.MENU_NAVIGATION.buyPagePremiumPacks ? 'layout-subheader-link-text-active' : null}`} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPagePremiumPacks)}>
                  {t('buyPage.premiumPacks')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${parentActiveTab === constants.MENU_NAVIGATION.buyPageHistory ? 'layout-subheader-link-text-active' : null}`} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPageHistory)}>
                  {t('common.transactionsHistory')}
                </Button>
              </div>
              <div className="layout-subheader-link">
                <Button color="link" className={`layout-subheader-link-text ${parentActiveTab === constants.MENU_NAVIGATION.buyPageRedeemCode ? 'layout-subheader-link-text-active' : null}`} onClick={() => this.toggle(constants.MENU_NAVIGATION.buyPageRedeemCode)}>
                  {t('common.redeemGiftCode')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="layout-body-main layout-body-main-shop-menu">
          <TabContent className="buy-page-tabContent" activeTab={parentActiveTab}>
            <TabPane className={classNames("buy-page-tab", {
              'buy-page-tab-discounts': size(discounts) > 0
            })} tabId={constants.MENU_NAVIGATION.buyPageMoneyPacks}>
              <Row className='d-flex flex-column align-items-center buy-page-end-time-section mt-2'>
                {
                  discounts.length > 0 && (size(intersection(discounts[0].selectedDiscountPackage, constants.MONEYPACKS_TYPES)) > 0) && map(discounts, item => (
                    <Col className="d-flex flex-row align-items-center">
                      <DiscountTimer item={item} handleCurState={this.handleCurState} curState={curState} />
                    </Col>
                  ))
                }
              </Row>
              <Row className="buy-page-row">
                <Col>
                  <div className="buy-page-coins-imageWrapper" onClick={() => this.buyMoney(1)}>
                    <Media className="buy-page-coins-image" src={coins1} alt="" />
                  </div>
                  <div className="buy-page-coins-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money1') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    <Media src={coinImg} className="buy-page-coins-coin" />
                    <div className="buy-page-coins-amount">
                      250
                    </div>
                  </div>
                  <div className="buy-page-coins-button-wrapper">
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money1'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(1)}
                          >
                            0.85 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(1)}
                        >
                          0.85 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
                <Col>
                  <div className="buy-page-coins-imageWrapper" onClick={() => this.buyMoney(2)}>
                    <Media className="buy-page-coins-image" src={coins2} alt="" />
                    <Media className="buy-page-coins-bonus-image" src={bonus1} alt="" />
                  </div>
                  <div className="buy-page-coins-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money2') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    <Media src={coinImg} className="buy-page-coins-coin" />
                    <div className="buy-page-coins-amount">
                      500
                    </div>
                  </div>
                  <div className="buy-page-coins-button-wrapper">
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money2'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money2, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(2)}
                          >
                            1.41 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(2)}
                        >
                          1.41 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
                <Col>
                  <div className="buy-page-coins-imageWrapper" onClick={() => this.buyMoney(3)}>
                    <Media className="buy-page-coins-image" src={coins3} alt="" />
                    <Media className="buy-page-coins-bonus-image" src={bonus2} alt="" />
                  </div>
                  <div className="buy-page-coins-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money3') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    <Media src={coinImg} className="buy-page-coins-coin" />
                    <div className="buy-page-coins-amount">
                      1500
                    </div>
                  </div>
                  <div className="buy-page-coins-button-wrapper">
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money3'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money3, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(3)}
                          >
                            2.85 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(3)}
                        >
                          2.85 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
                <Col>
                  <div className="buy-page-coins-imageWrapper" onClick={() => this.buyMoney(4)}>
                    <Media className="buy-page-coins-image" src={coins4} alt="" />
                    <Media className="buy-page-coins-bonus-image" src={bonus3} alt="" />
                  </div>
                  <div className="buy-page-coins-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money4') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    <Media src={coinImg} className="buy-page-coins-coin" />
                    <div className="buy-page-coins-amount">
                      4000
                    </div>
                  </div>
                  <div className="buy-page-coins-button-wrapper">
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money4'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money4, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(4)}
                          >
                            4.27 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(4)}
                        >
                          4.27 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
                <Col>
                  <div className="buy-page-coins-imageWrapper" onClick={() => this.buyMoney(5)}>
                    <Media className="buy-page-coins-image" src={coins5} alt="" />
                    <Media className="buy-page-coins-bonus-image" src={bonus4} alt="" />
                  </div>
                  <div className="buy-page-coins-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money5') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    <Media src={coinImg} className="buy-page-coins-coin" />
                    <div className="buy-page-coins-amount">
                      15000
                    </div>
                  </div>
                  <div className="buy-page-coins-button-wrapper">
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money5'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money5, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(5)}
                          >
                            14.23 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(5)}
                        >
                          14.23 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane className="buy-page-tab" tabId={constants.MENU_NAVIGATION.buyPagePremiumPacks}>
              <Row className="d-flex flex-column align-items-center buy-page-end-time-section mt-2">
                {
                  discounts.length > 0 && (includes(discounts[0].selectedDiscountPackage, 'money6') || includes(discounts[0].selectedDiscountPackage, 'money7') || includes(discounts[0].selectedDiscountPackage, 'money8')) && map(discounts, item => (
                    <Col className="d-flex flex-row align-items-center">
                      <DiscountTimer item={item} handleCurState={this.handleCurState} curState={curState} />
                    </Col>
                  ))
                }
              </Row>
              <Row className="buy-page-packs-row">
                <Col style={{paddingLeft: 30, paddingRight: 30}}>
                  <div className={classNames('buy-page-premium-imageWrapper', {'buy-page-premium-imageWrapper-disabled': processing || (!balance || (balance > 0))})} onClick={() => this.buyMoney(6)}>
                    <Media className="buy-page-premium-image" src={balanceResetImg} alt="" />
                  </div>
                  <div className="buy-page-premium-wrapper">
                    <div className="buy-page-premium-amount">
                      {t('buyPage.balanceReset')}
                    </div>
                    <div className="buy-page-info-imageWrapper">
                      <Media onMouseEnter={() => this.mouseEnter('6')} onMouseLeave={() => this.mouseLeave('6')} className="buy-page-info-image" src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="" />
                      <div className={`buy-page-info-hover ${activeHover === '6' ? null : 'display-none'}`}>
                        <label className="mr-1">{`${t('buyPage.balanceReset')}:`}</label>
                        {t('buyPage.balanceResetDesc')}
                      </div>
                    </div>
                  </div>
                  <div className="buy-page-premium-button-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money6') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money6'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money6, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing || (!balance || (balance > 0))}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(6)}
                          >
                            5.00 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing || !balance || (balance > 0)}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(6)}
                        >
                          5.00 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
                <Col style={{paddingLeft: 30, paddingRight: 30}}>
                  <div className={classNames('buy-page-premium-imageWrapper', {'buy-page-premium-imageWrapper-disabled': processing || (!totalPoints || (totalPoints > 0))})} onClick={() => this.buyMoney(7)}>
                    <Media className="buy-page-premium-image" src={pointsResetImg} alt="" />
                  </div>
                  <div className="buy-page-premium-wrapper">
                    <div className="buy-page-premium-amount">
                      {t('buyPage.pointsReset')}
                    </div>
                    <div className="buy-page-info-imageWrapper">
                      <Media className="buy-page-info-image" onMouseEnter={() => this.mouseEnter('7')} onMouseLeave={() => this.mouseLeave('7')} src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="" />
                      <div className={`buy-page-info-hover ${activeHover === '7' ? null : 'display-none'}`}>
                        <label className="mr-1">{`${t('buyPage.pointsReset')}:`}</label>
                        {t('buyPage.pointsResetDesc')}
                      </div>
                    </div>
                  </div>
                  <div className="buy-page-premium-button-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money7') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money7'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money7, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing || (!totalPoints || (totalPoints > 0))}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(7)}
                          >
                            5.00 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing || (!totalPoints || (totalPoints > 0))}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(7)}
                        >
                          5.00 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>
                <Col style={{paddingLeft: 30, paddingRight: 30}}>
                  <div className={classNames('buy-page-premium-imageWrapper', {'buy-page-premium-imageWrapper-disabled': processing })} onClick={() => this.buyMoney(8)}>
                    <Media className="buy-page-premium-image" src={profileResetImg} alt="" />
                  </div>
                  <div className="buy-page-premium-wrapper">
                    <div className="buy-page-premium-amount">
                      {t('buyPage.profileRestart')}
                    </div>
                    <div className="buy-page-info-imageWrapper">
                      <Media className="buy-page-info-image" onMouseEnter={() => this.mouseEnter('8')} onMouseLeave={() => this.mouseLeave('8')} src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="" />
                      <div className={`buy-page-info-hover buy-page-info-hover-profile ${activeHover === '8' ? null : 'display-none'}`}>
                        <label className="mr-1">{`${t('buyPage.profileRestart')}:`}</label>
                        {t('buyPage.profileRestartDesc')}
                      </div>
                    </div>
                  </div>
                  <div className="buy-page-premium-button-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'money8') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['money8'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.money8, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(8)}
                          >
                            5.00 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(8)}
                        >
                          5.00 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>

                <Col style={{ paddingLeft: 30, paddingRight: 30 }}>
                  <div className={classNames('buy-page-premium-imageWrapper', { 'buy-page-premium-imageWrapper-disabled': processing })} onClick={() => this.buyMoney(10)}>
                    <Media className="buy-page-premium-image" src={hideAdsMonthImg} alt="" />
                  </div>
                  <div className="buy-page-premium-wrapper">
                    <div className="buy-page-premium-amount">
                      {t('buyPage.hideAdsMonth')}
                      {adsHidden ? (
                        <>
                          <span> {`(${t('buyPage.activeUntil')}`} </span>
                          <span>
                            <Moment format="DD.MM.YYYY" locale="lv">{adsHidden}</Moment>
                          </span>
                          <span>)</span>
                        </>
                      ) : ''}
                    </div>
                    <div className="buy-page-info-imageWrapper">
                      <Media className="buy-page-info-image" onMouseEnter={() => this.mouseEnter('10')} onMouseLeave={() => this.mouseLeave('10')} src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="" />
                      <div className={`buy-page-info-hover buy-page-info-hover-profile ${activeHover === '10' ? null : 'display-none'}`}>
                        <label className="mr-1">{`${t('buyPage.hideAdsMonth')}:`}</label>
                        {t('buyPage.hideAdsMonthDesc')}
                      </div>
                    </div>
                  </div>
                  <div className="buy-page-premium-button-wrapper">
                    {
                      discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'hideAdsMonth') && (
                        this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                      )
                    }
                    {
                      discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                        (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['hideAdsMonth'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                          this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.hideAdsMonth, discounts[0], processing)
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(10)}
                          >
                            3.00 EUR
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={processing}
                          color="link"
                          className={classNames('layout-default-button', {
                            'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                            'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                          })}
                          onClick={() => this.buyMoney(10)}
                        >
                          3.00 EUR
                        </Button>
                      )
                    }
                  </div>
                </Col>

                {!config.isInDraugiemAppFrame() && (
                  <Col style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <div className={classNames('buy-page-premium-imageWrapper', { 'buy-page-premium-imageWrapper-disabled': processing })} onClick={() => this.buyMoney(11)}>
                      <Media className="buy-page-premium-image" src={hideAdsYearImg} alt="" />
                    </div>
                    <div className="buy-page-premium-wrapper">
                      <div className="buy-page-premium-amount">
                        {t('buyPage.hideAdsYear')}
                        {adsHidden ? (
                          <>
                            <span> {`(${t('buyPage.activeUntil')}`} </span>
                            <span>
                              <Moment format="DD.MM.YYYY" locale="lv">{adsHidden}</Moment>
                            </span>
                            <span>)</span>
                          </>
                        ) : ''}
                      </div>
                      <div className="buy-page-info-imageWrapper">
                        <Media className="buy-page-info-image" onMouseEnter={() => this.mouseEnter('11')} onMouseLeave={() => this.mouseLeave('11')} src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="" />
                        <div className={`buy-page-info-hover buy-page-info-hover-profile ${activeHover === '11' ? null : 'display-none'}`}>
                          <label className="mr-1">{`${t('buyPage.hideAdsYear')}:`}</label>
                          {t('buyPage.hideAdsYearDesc')}
                        </div>
                      </div>
                    </div>
                    <div className="buy-page-premium-button-wrapper">
                      {
                        discounts.length > 0 && discounts[0].type === constants.BONUS && includes(discounts[0].selectedDiscountPackage, 'hideAdsYear') && (
                          this.renderBonus(constants.SHOP_PRODUCT_NAMES.money1, discounts[0], processing)
                        )
                      }
                      {
                        discounts.length > 0 && (curState === constants.PLANNED || curState === constants.CURRENT) ? (
                          (discounts[0].type === constants.SPECIALPRICE && discounts[0].specialPrice['hideAdsYear'] > 0) || discounts[0].type === constants.DISCOUNTPRICE ? (
                            this.renderSpecialAndDiscount(constants.SHOP_PRODUCT_NAMES.hideAdsYear, discounts[0], processing)
                          ) : (
                            <Button
                              disabled={processing}
                              color="link"
                              className={classNames('layout-default-button', {
                                'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                                'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                              })}
                              onClick={() => this.buyMoney(11)}
                            >
                             25.00 EUR
                            </Button>
                          )
                        ) : (
                          <Button
                            disabled={processing}
                            color="link"
                            className={classNames('layout-default-button', {
                              'layout-darkHighlighted-button': screenMode !== constants.SCREEN_MODE.light,
                              'layout-redHighlighted-button': screenMode === constants.SCREEN_MODE.light,
                            })}
                            onClick={() => this.buyMoney(11)}
                          >
                            25.00 EUR
                          </Button>
                        )
                      }
                    </div>
                  </Col>
                )}

              </Row>
            </TabPane>
            <TabPane tabId={constants.MENU_NAVIGATION.buyPageHistory} className="buy-page-tab">
              <Row className="player-history-table-header transaction-history-table-header">
                <Col sm="3" className="player-history-table-header-col">
                  {t('moneyHistory.time')}
                </Col>
                <Col sm="2" className="player-history-table-header-col">
                  {t('moneyHistory.purchase')}
                </Col>
                <Col sm="3" className="player-history-table-header-col">
                  {t('moneyHistory.amount')}
                </Col>
                <Col sm="2" className="player-history-table-header-col">
                  {t('moneyHistory.paymentMethod')}
                </Col>
                <Col sm="2" className="player-history-table-header-col-last player-history-table-header-col layout-float-right">
                  {t('moneyHistory.status')}
                </Col>
              </Row>
              <div
                // className="chat-body-scroll-area shop-transaction-history-table"
                className={`layout-body-main-scrollarea weekly-status-scrollbar shop-transaction-history-table ${(historyData && historyData.length == 0) ? "d-flex justify-content-center" : ""}`}
                contentClassName="online-users-ReactTableContainer"
                show={historyData && Object.keys(historyData).length > 7 ? true : null}
                rightOffset={-1}
                topOffset={22}
                bottomOffset={-15}
                horizontal={false}
              >
                {(historyData && historyData.length > 0) ? historyData.sort((a, b) => (b.dateInitiated - a.dateInitiated)).map((data, index) => (
                  // (Math.abs(data.dateInitiated - Date.now())/(1000 * 60 * 60 * 24) <= 30 && data.status !== 'completed') &&
                  <Row key={data.key} className={`player-history-table-row ${index % 2 === 0 ? 'odd' : 'even'}`}>
                    {console.log("historyData: ",)}
                    <Col sm="3" className='player-history-table-col'>
                      <CustomDate
                        format="DD.MM.YYYY hh:mm"
                        date={data.dateInitiated}
                      />
                    </Col>
                    <Col sm="2" className='player-history-table-col'>
                      {(data.productNr === 1
                        && '0.85 EUR')
                        || (data.productNr === 2
                          && '1.41 EUR')
                        || (data.productNr === 3
                          && '2.85 EUR')
                        || (data.productNr === 4
                          && '4.27 EUR')
                        || (data.productNr === 5
                          && '14.23 EUR')
                        || (data.productNr === 6
                          && '5.00 EUR')
                        || (data.productNr === 7
                          && '5.00 EUR')
                        || (data.productNr === 8
                          && '5.00 EUR')
                        || (data.productNr === 9
                        && '10.00 EUR')
                        || (data.productNr === 10
                        && '3.00 EUR')
                        || (data.productNr === 11
                          && '25.00 EUR')
                      }
                    </Col>
                    <Col sm="3" className='player-history-table-col'>
                      {(data.productNr === 1
                        && (
                          <div className='buy-page-coins-coin-amount'>
                            <Media src={coinImg} className="buy-page-coins-coin" />
                            <div className="buy-page-coins-amount">
                              250
                            </div>
                          </div>
                        ))
                        || (data.productNr === 2
                          && (
                            <div className='buy-page-coins-coin-amount'>
                              <Media src={coinImg} className="buy-page-coins-coin" />
                              <div className="buy-page-coins-amount">
                                500
                              </div>
                            </div>
                          ))
                        || (data.productNr === 3
                          && (
                            <div className='buy-page-coins-coin-amount'>
                              <Media src={coinImg} className="buy-page-coins-coin" />
                              <div className="buy-page-coins-amount">
                                1500
                              </div>
                            </div>
                          ))
                        || (data.productNr === 4
                          && (
                            <div className='buy-page-coins-coin-amount'>
                              <Media src={coinImg} className="buy-page-coins-coin" />
                              <div className="buy-page-coins-amount">
                                4000
                              </div>
                            </div>
                          ))
                        || (data.productNr === 5
                          && (
                            <div className='buy-page-coins-coin-amount'>
                              <Media src={coinImg} className="buy-page-coins-coin" />
                              <div className="buy-page-coins-amount">
                                15000
                              </div>
                            </div>
                          ))
                        || (data.productNr === 6
                          && t('buyPage.balanceReset'))
                        || (data.productNr === 7
                          && t('buyPage.pointsReset'))
                        || (data.productNr === 8
                          && t('buyPage.profileRestart'))
                        || (data.productNr === 9
                        && t('buyPage.paymentUnBan'))
                        || (data.productNr === 10
                        && t('buyPage.hideAdsMonth'))
                        || (data.productNr === 11
                          && t('buyPage.hideAdsYear'))
                      }
                    </Col>
                    <Col sm="2" className='player-history-table-col'>
                      {(data.provider === constants.SOC_PROVIDERS.facebook
                        && t('buyPage.facebook'))
                        || (data.provider === constants.SOC_PROVIDERS.draugiem
                        && t('buyPage.draugiem'))
                        || (data.provider === constants.SOC_PROVIDERS.inbox
                        && t('buyPage.inbox'))
                        || (data.provider === constants.SOC_PROVIDERS.mobile
                          && t('buyPage.mobile'))
                        || (data.provider === constants.SOC_PROVIDERS.stripe
                          && t('buyPage.stripe'))
                        || (data.provider === constants.SOC_PROVIDERS.everyPay
                          && data.paymentOptionName)
                      }
                    </Col>
                    <Col sm="2" className='player-history-table-col player-history-table-col-last'>
                      <div className={`layout-float-right ${data.status === 'completed' ? 'player-history-table-col-positive' : 'player-history-table-col-negative'}`}>
                        { data.status === 'completed'
                          ? t('buyPage.finished')
                          : t('buyPage.unfinished')
                        }
                      </div>
                    </Col>
                  </Row>
                )) : <div className="shop-transaction-history-empty-row d-flex align-items-center flex-column"><Media
                  src={(screenMode === (constants.SCREEN_MODE.light)) ? noTransactionImgLight : (screenMode === constants.SCREEN_MODE.dark || screenMode === constants.SCREEN_MODE.normal)
                    ? noTransactionImgDark : null}
                  className="no-friends mb-3"
                /> {t('buyPage.purchaseHistoryNoEntries')}</div>}
              </div>
            </TabPane>
            <TabPane tabId={constants.MENU_NAVIGATION.buyPageRedeemCode} className="buy-page-tab">
              { parentActiveTab === constants.MENU_NAVIGATION.buyPageRedeemCode &&
                <RedeemGiftCode t={t} loading={loading} code={code} verifyGift={this.verifyGift} giftStatus={giftStatus} giftMessage={giftMessage} selectCode={selectCode} selectValue={selectValue} handleInputChange={this.handleInputChange} giftMessageVisible={giftMessageVisible} />
              }
            </TabPane>
          </TabContent>
        </div>

        <CustomModal
          isOpen={buyModal}
          toggle={this.toggleBuyModal}
          size="lg"
          addClassName={"payment-type-content-modal"}
          title={t('paymentType.chooseTitle')}
          body={
            <PaymentTypeContent
              toggleAboutUs={toggleAboutUs}
              toggleTerms={toggleTerms}
              selectedProduct={selectedProduct}
              selectedPaymentOption={selectedPaymentOption}
              setSelectedPaymentOption={this.onPaymentOptionClick}
              checkedTerms={checkedTerms}
              toggleCheckedTerms={this.toggleCheckedTerms}
              t={t}
              devMode={devMode}
            />
          }
          footer={
            <>
              <Button onClick={this.pay} disabled={!isOnline}>
                {t('common.continue')}
              </Button>
              <hr className={`payment-type-line-${screenMode}`} />
              <p className={`payment-type-footnote-text-${screenMode}`}>SIA 'Draxo Games'</p>
              <p className={`payment-type-footnote-text-${screenMode}`}>Liepājas 49-6, Kuldīga</p>
              <p className={`payment-type-footnote-text-${screenMode}`}>Latvija</p>
            </>
          }
        />

        <CustomModal
          isOpen={paymentErrorModal}
          toggle={() => this.togglePaymentErrorModal('')}
          size="md"
          verticalCentering
          largeText
          title={t('common.zoleInfo')}
          body={
            <p className="text-center">{paymentError}</p>
          }
          footer={
            <Button onClick={() => this.togglePaymentErrorModal('')}>
              {t('common.ok')}
            </Button>
          }
        />

        <CustomModal
          isOpen={smsPaymentModal}
          toggle={() => this.toggleSmsPaymentModal('')}
          size="md"
          verticalCentering
          largeText
          title={t('common.zoleInfo')}
          body={
            <div className="payment-type-scrollarea">
              <div className="d-flex justify-content-center flex-column payment-type-message">
                <div className="d-flex justify-content-center">
                  {t('paymentType.youArePurchasing')}
                  {selectedProduct.type === 'gameMoney' ? (
                    <div className='payment-type-message-coin-wrapper'>
                      <Media src={coinImg} className="payment-type-message-coin" />
                      <span className="payment-type-coin-amount">{selectedProduct.amount}</span>
                      {' '}
                    </div>
                  ) : (
                    t(`paymentType.${selectedProduct.type}`)
                  )}

                  &nbsp;{t('paymentType.for')}
                  {
                    selectedProduct.discountType &&
                      (selectedProduct.discountType === constants.SPECIALPRICE || selectedProduct.discountType === constants.DISCOUNTPRICE) &&
                      selectedProduct.realPrice ? (
                        <>
                          &nbsp;
                          <span className="payment-type-old-price">
                            {selectedProduct.price}
                            &nbsp;
                            EUR
                          </span>
                          &nbsp;
                          <span className="payment-type-new-price">
                            {selectedProduct.realPrice}
                            &nbsp;EUR
                          </span>
                        </>
                      ) : selectedProduct.discountType && selectedProduct.discountType === constants.BONUS && selectedProduct.realPrice ? (
                        <>
                          &nbsp;
                          <span className="payment-type-price">
                            {selectedProduct.price}
                            &nbsp;
                            EUR
                          </span>
                          &nbsp;
                          <span className="payment-type-new-price">
                            {`+${selectedProduct.realPrice}`}
                            <Media src={coinImg} className="buy-page-coins-coin" />
                          </span>
                        </>
                      ) : (
                        <>
                          &nbsp;{selectedProduct.price}
                          &nbsp;EUR
                        </>
                      )
                  }
                </div>
                <div>
                  {selectedProduct.descriptionId && (
                    t(selectedProduct.descriptionId)
                  )}
                </div>
              </div>

              <Row className="payment-type-row d-flex align-items-center">
                <Media src={mobileImg} />
              </Row>
              <Row className="payment-type-row pt-1">
                <Col className="payment-type-col text-align-center">
                    {t('paymentType.smsInfo1')}
                </Col>
              </Row>
              <Row className="payment-type-row pt-1">
                <Col className="payment-type-col text-align-center">
                    {t('paymentType.smsInfo2', {price: selectedProduct.price})}
                </Col>
              </Row>
              <Row className="payment-type-row pt-2">
                <Col className="payment-type-col text-align-center sms-payment-footer-info">
                  {t('paymentType.smsInfo3')}
                </Col>
              </Row>
            </div>
          }
          footer={
            <Button onClick={() => this.toggleSmsPaymentModal('')}>
              {t('common.ok')}
            </Button>
          }
        />

        {socProvider && socProvider === 'inbox' ? (
          <Modal container={'div > div > div'} returnFocusAfterClose={false} isOpen={showInboxPayment} size="xl" className="inbox-payment-modal" toggle={this.closeInboxModal}>
            <ModalHeader
              toggle={this.closeInboxModal}
              style={{ padding: 0, height: 0 }}
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.closeInboxModal} />
              }
            />
            <ModalBody style={{ padding: 10 }} >
              <Iframe
                url={inboxIframeLink}
                width="100%"
                height="680px"
                id="inboxPaymentIframe"
                className="inboxPaymentIframe"
                display="initial"
                position="relative"
              />
            </ModalBody>
          </Modal>
        ) : (null)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  socProvider: state.member.socProvider,
  balance: state.member.balance,
  totalPoints: state.member.totalPoints,
  uid: state.member.uid || '',
  adsHidden: state.member.adsHidden || null,
  discounts: state.shop.discounts,
  lastShopTab: state.shop.lastShopTab || null,
  transactionComplete: state.shop.transactionComplete || false,
});

const mapDispatchToProps = {
  initFBPay: initFBPayment,
  fbCallback: fbPaymentCallback,
  initDraugiemPay: initDraugiemPayment,
  initInboxPay: initInboxPayment,
  initStripePay: initStripePayment,
  initEveryPay: initEveryPayPayment,
  fetchTransactionsHistory: getTransactionsHistory,
  claimGift: claimGiftCode,
  verifyGift: verifyGiftCode,
  fetchDiscounts: getDiscounts,
  setLastShopTabFunc: setLastShopTab,
  setTransactionCompleteFunc: setTransactionComplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(BuyPage));
