import React, { Fragment, useEffect, useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'reactstrap/lib/Spinner';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';

import { map, get, size, sortBy } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import NavLink from 'reactstrap/lib/NavLink';
import { ModalBody, ModalHeader, Modal, ModalFooter } from 'reactstrap';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';
import MyInfoSubHeader from './MyInfoSubHeader';
import PartyLog from '../PartyLog';

import myInfoImg from '../../../../../../images/icons/my_profile.webp';
import infoImg from '../../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';
// import defaultImage from '../../../../../images/redesign/common/default_image.svg';

import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import normalDefaultImage from '../../../../../../images/redesign/normal-mode/common/default_image.webp';
import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';

import {
  getGameLogs,
} from '../../../../../../actions/member';

import * as constants from '../../../../../../constants/constants';
import PaginationNew from '../../PaginationNew';

const GameLogs = ({achievementsNotification, userSettings, uid, changeTab, activeTab, showUserSettingsModal, handleClickStart, OpenMobileDropdownModal}) => {

  const [openPartyModal, setOpenPartyModal] = useState(false);
  const [party, setParty] = useState({});
  const [selectRoom, setSelectRoom] = useState(null);
  const [partyRoomData, setPartyRoomData] = useState({});
  const [countRoom, setCountRoom] = useState(0);
  const [roomPlayerData, setRoomPlayerData] = useState({});
  const [roomPlayerImgData, setRoomPlayerImgData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const gameLogs = useSelector(state => state.member.gameLogs);
  const isloading = useSelector(state => state.member.isloading);
  const screenMode = useSelector(state => state.member.screenMode);
  const fontMode = useSelector(state => state.member.fontMode);

  useEffect(() => {
    dispatch(getGameLogs());
  }, []);

  const changeTabA = (item) => {
    changeTab(item);
  };

  const togglePartyLog = (roomId, idx) => {
    setOpenPartyModal(true);
    setParty(gameLogs[roomId].roomData[idx + 1]);
    setSelectRoom(roomId);
    setPartyRoomData(gameLogs[roomId].roomData);
    setRoomPlayerData(gameLogs[roomId].playerData);
    setRoomPlayerImgData(gameLogs[roomId].playerImg);
  };

  const closePartyLog = () => {
    setOpenPartyModal(false);
    setParty({});
    setSelectRoom(null);
    setPartyRoomData({});
    setRoomPlayerData({});
    setRoomPlayerImgData({});
  };

  const totalNum = useMemo(() => {
    let count = countRoom;
    setCountRoom(count++);
    return (
      <div>
        {count++}
      </div>
    );
  }, [countRoom])

  const renderPlayerImg = (playerPhoto) => {
    if (playerPhoto) {
      return playerPhoto;
    } else {
      if (screenMode === constants.SCREEN_MODE.dark) return defaultImage;
      if (screenMode === constants.SCREEN_MODE.normal) return normalDefaultImage;
      if (screenMode === constants.SCREEN_MODE.light) return lightDefaultImage;
    }
  }

  const handlePagination = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  }

  let pagesCount = Math.ceil(size(gameLogs) / pageSize);

  return (
    <div className="layout-mobile-body layout-mobile-body-game-logs-page">
      <Helmet>
        <title>
          Zole - {t('myInfo.gameLogs')}
        </title>
      </Helmet>

      <div className="layout-mobile-body-top">
        {/* <Row className="layout-mobile-subheader d-none">
          <MyInfoSubHeader activeTab={activeTab} changeTab={changeTab} />
        </Row> */}
      </div>
      <div className="layout-mobile-body-main" style={{display: 'flex', flexDirection: 'column'}}>
        <div className="layout-mobile-body-main-title d-none">{t('myInfo.gameLogs')}</div>
        <div sm="12" className="new-game-logs layout-mobile-body-main-scrollarea " style={{maxHeight: '95%'}}>
          {
            isloading ? (
              <div className="top-table-spinner-loading-section d-flex align-items-center">
                <Spinner color={screenMode === constants.SCREEN_MODE.light ? constants.SCREEN_MODE.dark : constants.SCREEN_MODE.light} style={{ width: '5rem', height: '5rem' }} />
              </div>
            ) : (
              gameLogs && size(gameLogs) > 0 ? (
                // map(sortBy(gameLogs, 'orderDate', 'desc'), (item, key) => (
                Object.keys(gameLogs).sort((a, b) => gameLogs[b].orderDate - gameLogs[a].orderDate).slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((key, index) => (
                  <div
                    className="new-game-logs-scrollarea"
                  >
                    <table className="new-game-logs-table mt-2" key={key}>
                      <thead>
                        <tr className="new-game-logs-table-header">
                          <th style={{width: 20, padding: 7}}>#</th>
                          <th style={{ minWidth: '120px', padding: 7 }}>{t('common:myInfo.dateTime')}</th>
                          <th>
                            <div className="new-game-logs-table-header-player-name">{gameLogs[key].roomData[0].player1}</div>
                            <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">

                              <div className='top-table-row-player-image-frame mx-0'>
                                <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key].roomData[0].player1]?.photo)} className="top-table-row-player-image-default" alt="x" />
                              </div>
                              {/* {gameLogs[key].roomData[0].player1 || ''} */}
                            </div>
                          </th>
                          <th>
                            <div className="new-game-logs-table-header-player-name">{gameLogs[key].roomData[0].player2}</div>
                            <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                              <div className='top-table-row-player-image-frame mx-0'>
                                <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key].roomData[0].player2]?.photo)} className="top-table-row-player-image-default" alt="x" />
                              </div>
                              {/* {gameLogs[key].roomData[0].player2 || ''} */}
                            </div>
                          </th>
                          <th>
                            <div className="new-game-logs-table-header-player-name">{gameLogs[key].roomData[0].player3}</div>
                            <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                              <div className='top-table-row-player-image-frame mx-0'>
                                <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key].roomData[0].player3]?.photo)} className="top-table-row-player-image-default" alt="x" />
                              </div>
                              {/* {gameLogs[key].roomData[0].player3 || ''} */}
                            </div>
                          </th>
                          {gameLogs[key].roomData[0]?.player4 && (
                            <th>
                              <div className="new-game-logs-table-header-player-name">{gameLogs[key].roomData[0].player4}</div>
                              <div className="new-game-logs-table-header-player d-flex flex-row align-items-center">
                                <div className='top-table-row-player-image-frame mx-0'>
                                  <Media src={renderPlayerImg(gameLogs[key].playerImg[gameLogs[key].roomData[0].player4]?.photo)} className="top-table-row-player-image-default" alt="x" />
                                </div>
                                {/* {gameLogs[key].roomData[0].player4 || ''} */}
                              </div>
                            </th>
                          )}
                          <th />
                          <th>
                            {t('common.pules')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          gameLogs[key].roomsPoints && gameLogs[key].roomsPoints && Object.keys(gameLogs[key].roomsPoints.rounds).map((pointKey, index) => (
                            <>
                              {gameLogs[key].roomData[index + 1] ? (
                                <tr className="new-game-logs-table-row" key={pointKey}>
                                  <td style={{width: 20, padding: 7}}>{(index + 1)}</td>
                                  <td>
                                    {/* {
                                      Object.keys(gameLogs[key].roomData[index + 1]).map((roundKey, roundIdx) => (
                                        roundIdx === 0 ? (
                                          <Moment format={constants.FORMAT_DATE_TYPE.fullDateMinute} locale={constants.sendLanguage.lv}>
                                            {gameLogs[key].roomData[index + 1][roundKey].time}
                                          </Moment>
                                        ) : (
                                          null
                                        )
                                      ))
                                    } */}
                                    {
                                      gameLogs[key].roomData[index + 1] ? (Object.keys(gameLogs[key].roomData[index + 1]).map((roundKey, roundIdx) => (
                                        roundIdx === 0 ? (
                                          <Moment format={constants.FORMAT_DATE_TYPE.fullDateMinute} locale={constants.sendLanguage.lv}>
                                            {gameLogs[key].roomData[index + 1][roundKey].time}
                                          </Moment>
                                        ) : (
                                          null
                                        )
                                      ))) : (null)
                                    }
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center width-100">
                                      <div className={classNames({
                                        'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player1 > 0,
                                        'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player1 < 0,
                                        'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player1 === 0,
                                      })}
                                      >
                                        {gameLogs[key].roomsPoints.rounds[pointKey].player1}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center width-100">
                                      <div className={classNames({
                                        'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player2 > 0,
                                        'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player2 < 0,
                                        'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player2 === 0,
                                      })}
                                      >
                                        {gameLogs[key].roomsPoints.rounds[pointKey].player2}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center width-100">
                                      <div className={classNames({
                                        'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player3 > 0,
                                        'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player3 < 0,
                                        'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player3 === 0,
                                      })}
                                      >
                                        {gameLogs[key].roomsPoints.rounds[pointKey].player3}
                                      </div>
                                    </div>
                                  </td>
                                  {(gameLogs[key].roomData[0]?.player4) && (
                                    <td>
                                      <div className="d-flex align-items-center width-100">
                                        <div className={classNames({
                                          'new-game-logs-table-row-large-trend': gameLogs[key].roomsPoints.rounds[pointKey].player4 > 0,
                                          'new-game-logs-table-row-low-trend': gameLogs[key].roomsPoints.rounds[pointKey].player4 < 0,
                                          'new-game-logs-table-row-neutral-trend': gameLogs[key].roomsPoints.rounds[pointKey].player4 === 0,
                                        })}
                                        >
                                          {gameLogs[key].roomsPoints.rounds[pointKey].player4}
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  <td />
                                  <td>
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'P' && (
                                      <div> P </div>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'P-' && (
                                      <span className={`strikeout-new-${screenMode}`}> P </span>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player1' && (
                                      <div>
                                        {gameLogs[key].roomData[0].player1}
                                      </div>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player1-' && (
                                      <span className={`strikeout-new-${screenMode}`}>
                                        {gameLogs[key].roomData[0].player1}
                                      </span>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player2' && (
                                      <div>
                                        {gameLogs[key].roomData[0].player2}
                                      </div>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player2-' && (
                                      <span className={`strikeout-new-${screenMode}`}>
                                        {gameLogs[key].roomData[0].player2}
                                      </span>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player3' && (
                                      <div>
                                        {gameLogs[key].roomData[0].player3}
                                      </div>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player3-' && (
                                      <span className={`strikeout-new-${screenMode}`}>
                                        {gameLogs[key].roomData[0].player3}
                                      </span>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player4' && (
                                      <div>
                                        {gameLogs[key].roomData[0].player4}
                                      </div>
                                    )}
                                    {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player4-' && (
                                      <span className={`strikeout-new-${screenMode}`}>
                                        {gameLogs[key].roomData[0].player4}
                                      </span>
                                    )}
                                  </td>
                                  <td className="new-game-logs-table-row-info">
                                    <Media src={screenMode === constants.SCREEN_MODE.light ? lightInfoImg : infoImg} alt="x" onClick={() => togglePartyLog(key, index)} />
                                  </td>
                                </tr>
                              ) : (null)}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <div className="d-flex align-items-center new-game-logs-empty-entries h-100">
                  {t('home.noGameLog')}
                </div>
              )
            )
          }
        </div>
        <PaginationNew
          show={size(gameLogs) > pageSize || true}
          currentPage={currentPage}
          pagesCount={pagesCount}
          handlePagination={handlePagination}
        />
      </div>
      <PartyLog screenMode={screenMode} party={party} partyRoomData={partyRoomData} roomPlayerData={roomPlayerData} roomPlayerImgData={roomPlayerImgData} closePartyLog={closePartyLog} togglePartyLog={togglePartyLog} openPartyModal={openPartyModal} />
    </div>
  );
};

GameLogs.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  showUserSettingsModal: PropTypes.func.isRequired,
  handleClickStart: PropTypes.func.isRequired,
  OpenMobileDropdownModal: PropTypes.func.isRequired,
};

GameLogs.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
  activeTab: constants.MENU_NAVIGATION.gameLogs,
};

export default GameLogs;
