import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Firebase } from '../../lib/firebase';

import { getPoints, cancelPointsListeners, getPules } from '../../actions/points';

import ScoreTableComponent from '../../web/components/Game/ScoreTable/ScoreTable';
import ScoreTable4Component from '../../web/components/Game/ScoreTable/ScoreTable4p';

import { size } from 'lodash';

class ScoreTable extends Component {
  static propTypes = {
    player1ShortName: PropTypes.string,
    player2ShortName: PropTypes.string,
    player3ShortName: PropTypes.string,
    player4ShortName: PropTypes.string,
    player1Uid: PropTypes.string,
    player2Uid: PropTypes.string,
    player3Uid: PropTypes.string,
    player4Uid: PropTypes.string,
    totalPoints: PropTypes.shape(),
    points: PropTypes.shape(),
    closeReason: PropTypes.shape(),
    myPos: PropTypes.string,
    bet: PropTypes.string,
    party: PropTypes.number,
    //  member: PropTypes.shape(),
    globalParams: PropTypes.shape({
      gameState: PropTypes.string,
    }),
    fetchPoints: PropTypes.func.isRequired,
    fetchPules: PropTypes.func.isRequired,
    cancelListeners: PropTypes.func.isRequired,
    roomId: PropTypes.string,
    scoreTableOpen: PropTypes.bool,
    isEndResultTable: PropTypes.bool,
    gameType: PropTypes.string,
    fetchPartyLog: PropTypes.func,
    showInfoModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
  //  match: null,
  //  history: {},
    player1ShortName: null,
    player2ShortName: null,
    player3ShortName: null,
    player4ShortName: null,
    player1Uid: null,
    player2Uid: null,
    player3Uid: null,
    player4Uid: null,
    totalPoints: null,
    points: null,
    closeReason: null,
    myPos: null,
    bet: null,
    party: null,
    globalParams: {},
    roomId: null,
    scoreTableOpen: false,
    isEndResultTable: false,
    gameType: null,
    fetchPartyLog: null,
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    const {
      fetchPoints,
      fetchPules,
      roomId,
      //  isEndResultTable,
      cancelListeners,
    } = this.props;

    Firebase.auth().onAuthStateChanged((user) => {
      if (user && roomId) {
      //  fetchPoints(roomId);
        cancelListeners().then(() => {
        //  if (isEndResultTable) {
          fetchPules(roomId);
          //  } else {
          fetchPoints(roomId);
        //  }
        });
      }
    });
  }

  componentWillUnmount() {
    const { cancelListeners, roomId } = this.props;

    if (roomId) {
      cancelListeners(roomId);
    }
  }

  render = () => {
    const {
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      player1Uid,
      player2Uid,
      player3Uid,
      player4Uid,
      totalPoints,
      points,
      //  pules,
      myPos,
      scoreTableOpen,
      bet,
      party,
      isEndResultTable,
      //  globalParams,
      gameType,
      closeReason,
      fetchPartyLog,
      showInfoModal,
    } = this.props;

    //  console.log('ScoreTable container points', { points });

    if (player4ShortName) {
    
      return (
        <ScoreTable4Component
          player1ShortName={player1ShortName}
          player2ShortName={player2ShortName}
          player3ShortName={player3ShortName}
          player4ShortName={player4ShortName}
          player1Uid={player1Uid}
          player2Uid={player2Uid}
          player3Uid={player3Uid}
          player4Uid={player4Uid}
          totalPnts={totalPoints}
          points={points}
        //  pules={pules}
          myPos={myPos}
          scoreTableOpen={scoreTableOpen}
          isEndResultTable={isEndResultTable}
          bet={bet}
          party={party}
        //  globalParams={globalParams}
          gameType={gameType}
          closeReason={closeReason}
          fetchPartyLog={fetchPartyLog}
          showInfoModal={showInfoModal}
        />
      );
    }

    return (
      <ScoreTableComponent
        player1ShortName={player1ShortName}
        player2ShortName={player2ShortName}
        player3ShortName={player3ShortName}
        player1Uid={player1Uid}
        player2Uid={player2Uid}
        player3Uid={player3Uid}
        totalPnts={totalPoints}
        points={points}
      //  pules={pules}
        myPos={myPos}
        scoreTableOpen={scoreTableOpen}
        isEndResultTable={isEndResultTable}
        bet={bet}
        party={party}
      //  globalParams={globalParams}
        gameType={gameType}
        closeReason={closeReason}
        fetchPartyLog={fetchPartyLog}
        showInfoModal={showInfoModal}
      />
    );
  }
}

const mapStateToProps = (state) => {
  if (state.game) {
    return ({
      totalPoints: state.points.totalPoints || {},
      points: state.points.points || {},
      //  pules: state.points.pules || {},
      myPos: state.game.myPos || 'player1',
      bet: state.game.globalParams.bet || null,
      party: state.game.globalParams.party || null,
      gameType: state.game.globalParams.gameType || null,
      closeReason: state.game.globalParams.closeReason || null,
      //  globalParams: state.game.globalParams || {},
      player1ShortName: state.game.players.player1 ? state.game.players.player1.shortName : '',
      player2ShortName: state.game.players.player2 ? state.game.players.player2.shortName : '',
      player3ShortName: state.game.players.player3 ? state.game.players.player3.shortName : '',
      player4ShortName: state.game.players.player4 ? state.game.players.player4.shortName : '',

      player1Uid: state.game.players.player1 ? state.game.players.player1.uid : '',
      player2Uid: state.game.players.player2 ? state.game.players.player2.uid : '',
      player3Uid: state.game.players.player3 ? state.game.players.player3.uid : '',
      player4Uid: state.game.players.player4 ? state.game.players.player4.uid : '',
    });
  }
  return null;
};

const mapDispatchToProps = {
  fetchPoints: getPoints,
  fetchPules: getPules,
  cancelListeners: cancelPointsListeners,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreTable);
