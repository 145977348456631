import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';

const ScoreTableRow = React.memo(({
  player1,
  player2,
  player3,
  player4,
  pule,
  player1ShortName,
  player2ShortName,
  player3ShortName,
  player4ShortName,
  myPos,
  index,
  roundIndex,
  fetchPartyLog,
  isEndResultTable,
}) => (
  <Fragment>
    <td className="score-table-id-col">{index}</td>
    <td className="score-table-col  width-20">
      <Fragment>
        {myPos === 'player1' && (
          <Fragment>
            {player4}
          </Fragment>
        )}
        {myPos === 'player2' && (
          <Fragment>
            {player1}
          </Fragment>
        )}
        {myPos === 'player3' && (
          <Fragment>
            {player2}
          </Fragment>
        )}
        {myPos === 'player4' && (
          <Fragment>
            {player3}
          </Fragment>
        )}
      </Fragment>
    </td>
    <td className="score-table-col  width-20">
      <Fragment>
        {myPos === 'player1' && (
          <Fragment>
            {player1}
          </Fragment>
        )}
        {myPos === 'player2' && (
          <Fragment>
            {player2}
          </Fragment>
        )}
        {myPos === 'player3' && (
          <Fragment>
            {player3}
          </Fragment>
        )}
        {myPos === 'player4' && (
          <Fragment>
            {player4}
          </Fragment>
        )}
      </Fragment>
    </td>
    <td className="score-table-col  width-20">
      <Fragment>
        {myPos === 'player1' && (
          <Fragment>
            {player2}
          </Fragment>
        )}
        {myPos === 'player2' && (
          <Fragment>
            {player3}
          </Fragment>
        )}
        {myPos === 'player3' && (
          <Fragment>
            {player4}
          </Fragment>
        )}
        {myPos === 'player4' && (
          <Fragment>
            {player1}
          </Fragment>
        )}
      </Fragment>
    </td>
    <td className="score-table-col  width-20">
      <Fragment>
        {myPos === 'player1' && (
          <Fragment>
            {player3}
          </Fragment>
        )}
        {myPos === 'player2' && (
          <Fragment>
            {player4}
          </Fragment>
        )}
        {myPos === 'player3' && (
          <Fragment>
            {player1}
          </Fragment>
        )}
        {myPos === 'player4' && (
          <Fragment>
            {player2}
          </Fragment>
        )}
      </Fragment>
    </td>
    <td className="score-table-pules-col width-10">
      {pule === 'P' && (
        <div>P</div>
      )}
      {pule === 'P-' && (
        <span className="strikeout-new-normal strikeout-extension">P</span>
      )}
      {pule === 'player1' && (
        <div className="score-table-pules-col-div">
          { player1ShortName }
        </div>
      )}
      {pule === 'player1-' && (
        <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
          { player1ShortName }
        </span>
      )}
      {pule === 'player2' && (
        <div className="score-table-pules-col-div">
          { player2ShortName }
        </div>
      )}
      {pule === 'player2-' && (
        <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
          { player2ShortName }
        </span>
      )}
      {pule === 'player3' && (
        <div className="score-table-pules-col-div">
          { player3ShortName }
        </div>
      )}
      {pule === 'player3-' && (
        <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
          { player3ShortName }
        </span>
      )}
      {pule === 'player4' && (
        <div className="score-table-pules-col-div">
          { player4ShortName }
        </div>
      )}
      {pule === 'player4-' && (
        <span className="score-table-pules-col-div strikeout-new-normal strikeout-extension">
          { player4ShortName }
        </span>
      )}
    </td>
    {isEndResultTable ? (
      <td className="score-table-col">
        <div>
          <Button color="link" className="end-result-modal-info-button" onClick={() => fetchPartyLog(roundIndex + 1)}>
            Info
          </Button>
        </div>
      </td>
    ) : (null)}
  </Fragment>
));

ScoreTableRow.propTypes = {
  player1: PropTypes.number,
  player2: PropTypes.number,
  player3: PropTypes.number,
  player4: PropTypes.number,
  pule: PropTypes.string,
  player1ShortName: PropTypes.string,
  player2ShortName: PropTypes.string,
  player3ShortName: PropTypes.string,
  player4ShortName: PropTypes.string,
  myPos: PropTypes.string,
  index: PropTypes.number,
  roundIndex: PropTypes.number,
  fetchPartyLog: PropTypes.func,
  isEndResultTable: PropTypes.bool,
};

ScoreTableRow.defaultProps = {
  player1: null,
  player2: null,
  player3: null,
  player4: null,
  pule: null,
  player1ShortName: null,
  player2ShortName: null,
  player3ShortName: null,
  player4ShortName: null,
  myPos: null,
  index: null,
  roundIndex: null,
  fetchPartyLog: null,
  isEndResultTable: false,
};

export default ScoreTableRow;
