import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
// import isEqual from 'react-fast-compare';

import { connect } from 'react-redux';

// import Pagination from 'reactstrap/lib/Pagination';
// import PaginationItem from 'reactstrap/lib/PaginationItem';
// import PaginationLink from 'reactstrap/lib/PaginationLink';

import { Col, Row } from 'reactstrap';

import CustomDate from '../UI/CustomDate';

import {
  getBannedUsers,
  // getBannedUsersCount,
} from '../../../actions/users';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import PaginationOld from './PaginationOld';

class BannedUsers extends React.Component {
  static propTypes = {
  //  t: PropTypes.func.isRequired,
    bannedUsers: PropTypes.shape({
      name: PropTypes.shape({}),
      endDate: PropTypes.shape({}),
      reason: PropTypes.shape({}),
    }),
    // bannedUsersCount: PropTypes.number,
    // fetchBannedUsersCount: PropTypes.func.isRequired,
    fetchBannedUsers: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    bannedUsers: {},
    // bannedUsersCount: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      sortedKeysBannedUsers: this.getSortedKeys(props.bannedUsers),
      currentPage: 0,
      pageSize: 20,
    };
  }

  componentDidMount() {
    const { fetchBannedUsersCount, fetchBannedUsers } = this.props;

    // fetchBannedUsersCount();
    fetchBannedUsers();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.bannedUsers !== this.props.bannedUsers) {
      this.setState({
        sortedKeysBannedUsers: this.getSortedKeys(this.props.bannedUsers),
      });
    }
  }

  getSortedKeys(bannedUsers) {
    return Object.entries(bannedUsers)
      .sort(([, a], [, b]) => a.endDate - b.endDate)
      .map(([key]) => key);
  }

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  table() {
    const {
      bannedUsers,
      t,
    } = this.props;
    const { sortedKeysBannedUsers, currentPage, pageSize } = this.state;

    return (
      <Fragment>
        {(bannedUsers && sortedKeysBannedUsers.length > 0) ? sortedKeysBannedUsers
          .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
          .map((key, index) => (
            <Fragment key={key}>
              {bannedUsers[key] ? (
              <Row key={key} style={{margin: 0}} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <Col xs={3} className="allUsers-table-col money-history-table-col text-left" style={{padding: '5px 8px', lineBreak: 'auto', lineHeight: '18px'}}>
                  {bannedUsers[key].name}
                </Col>
                <Col xs={4} className="allUsers-table-col money-history-table-col text-left" style={{padding: '5px 8px', lineBreak: 'auto', lineHeight: '18px'}}>
                  {/*  <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                      {bannedUsers[key].endDate}
                    </Moment> */}
                  <CustomDate format="DD-MM-YYYY, hh:mm" date={bannedUsers[key].endDate} />
                </Col>
                <Col xs={5} className="allUsers-table-col money-history-table-col text-left" style={{padding: '5px 8px', lineBreak: 'auto', lineHeight: '18px'}}>
                  {bannedUsers[key].reason}
                </Col>
                </Row>
              ):(null)}
            </Fragment>
          )) : <tr><td colSpan={3} className="text-center banned-user-empty-entries">{t('ignoredUsers.ignoredUsersEmptyEntries')}</td></tr>}
      </Fragment>
    );
  }

  render() {
    const { t } = this.props;
    const { sortedKeysBannedUsers, currentPage, pageSize } = this.state;

    let pagesCount = Math.ceil(sortedKeysBannedUsers.length / pageSize);

    return (
      <Fragment>
        <div className="px-3">
        <Row className="" style={{ margin: 0 }}>
          <Col xs={3} style={{padding: '5px 8px'}} className="money-history-table-header-col justify-content-start">{t('common.name')}</Col>
          <Col xs={4} style={{padding: '5px 8px'}} className="money-history-table-header-col justify-content-start">{t('bannedUsers.end')}</Col>
          <Col xs={5} style={{padding: '5px 8px'}} className="money-history-table-header-col justify-content-start">{t('bannedUsers.reason')}</Col>
        </Row>
        </div>
        <ScrollAreaWrapper
          className="top-scrollarea"
          contentClassName="top-scrollarea-body px-3"
          show
          rightOffset={12}
          topOffset={0}
          bottomOffset={0}
        >
          {this.table()}
        </ScrollAreaWrapper>
        <PaginationOld
          show={sortedKeysBannedUsers.length > pageSize}
          currentPage={currentPage}
          pagesCount={pagesCount}
          handlePagination={this.handlePagination}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bannedUsers: (state.users && state.users.bannedUsers) ? state.users.bannedUsers : {},
  // bannedUsersCount: (state.users && state.users.bannedUsersCount) ? state.users.bannedUsersCount : null,
});

const mapDispatchToProps = {
  fetchBannedUsers: getBannedUsers,
  // fetchBannedUsersCount: getBannedUsersCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(BannedUsers));
