import React from 'react'
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  isMobileSafari,
} from 'react-device-detect';

import config from '../../../constants/config';

import {
  SETTINGSTOOLTIPTARGET,
  LOGOUTTOOLTIPTARGET,
  FULLSCREENTOOLTIPTARGET,
  FONTRESIZETOOLTIPTARGET,
} from '../../../constants/constants';

const PopoverComponent = ({gamePopOver, popOverOpen, onHover, onHoverLeave, fontMode, targetName, popOverTitle, popOverDescription}) => {

  const { t } = useTranslation('common');

  const onHoverFunc = () => {
    onHover();
  }
  const onHoverLeaveFunc = () => {
    onHoverLeave();
  }
console.log(gamePopOver, popOverOpen, popOverTitle, popOverDescription, "popCheck1")
  return (
    <>
      {!gamePopOver && !config.isInAppFrame() && (<Popover
        container={'div > div'}
        placement="left"
        isOpen={targetName === LOGOUTTOOLTIPTARGET && popOverOpen}
        target='menu_logout_button'
        popperClassName={`popover popover-font-${fontMode}`}
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
        className="menu-header-popover-option menu-popover"
      >
        <PopoverBody
          onMouseLeave={onHoverLeave}
          className="menu-player-balance-popover-body"
        >
          <div className="menu-player-balance-popover-body-header">
            {t(`common:home.${popOverTitle}`)}
          </div>
          <div className="menu-player-balance-popover-body-main">
            <div>
              {t(`common:home.${popOverDescription}`)}
            </div>
          </div>
        </PopoverBody>
      </Popover>)}
      {!isMobileSafari ? (
        <Popover
          container={'div > div'}
          placement="left"
          isOpen={targetName === FULLSCREENTOOLTIPTARGET && popOverOpen}
          target={gamePopOver ? 'game_room_full_screen_button' : 'menu_full_screen_button'}
          popperClassName={`popover popover-font-${fontMode}`}
          onMouseEnter={onHover}
          onMouseLeave={onHoverLeave}
          className="menu-header-popover-option menu-popover"
        >
          <PopoverBody
            onMouseLeave={onHoverLeave}
            className="menu-player-balance-popover-body"
          >
            <div className="menu-player-balance-popover-body-header">
              {t(`common:home.${popOverTitle}`)}
            </div>
            <div className="menu-player-balance-popover-body-main">
              <div>
                {t(`common:home.${popOverDescription}`)}
              </div>
            </div>
          </PopoverBody>
        </Popover>
      ):(null)}
      <Popover
        container={'div > div'}
        placement="left"
        isOpen={targetName === FONTRESIZETOOLTIPTARGET && popOverOpen}
        target={gamePopOver ? 'game_room_font_resize_button' : 'menu_font_resize_button'}
        popperClassName={`popover popover-font-${fontMode}`}
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
        className="menu-header-popover-option menu-popover"
      >
        <PopoverBody
          onMouseLeave={onHoverLeave}
          className="menu-player-balance-popover-body"
        >
          <div className="menu-player-balance-popover-body-header">
            {t(`common:home.${popOverTitle}`)}
          </div>
          <div className="menu-player-balance-popover-body-main">
            <div>
              {t(`common:home.${popOverDescription}`)}
            </div>
          </div>
        </PopoverBody>
      </Popover>
      <Popover
        container={'div > div'}
        placement="left"
        isOpen={targetName === SETTINGSTOOLTIPTARGET && popOverOpen}
        target={gamePopOver ? 'game_room_menu_settings_button' : 'menu_settings_button'}
        popperClassName={`popover popover-font-${fontMode}`}
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
        className="menu-header-popover-option menu-popover"
      >
        <PopoverBody
          onMouseLeave={onHoverLeave}
          className="menu-player-balance-popover-body"
        >
          <div className="menu-player-balance-popover-body-header">
            {t(`common:home.${popOverTitle}`)}
          </div>
          <div className="menu-player-balance-popover-body-main">
            <div>
              {t(`common:home.${popOverDescription}`)}
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
}

PopoverComponent.propTypes = {
  popOverOpen: PropTypes.bool,
  targetName: PropTypes.string,
  popOverTitle: PropTypes.string,
  popOverDescription: PropTypes.string,
  fontMode: PropTypes.string,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
}

PopoverComponent.defaultProps = {
  popOverOpen: false,
  popOverTitle: '',
  popOverDescription: '',
}

export default PopoverComponent;
