import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { size, map } from 'lodash';

import { connect } from 'react-redux';

import Moment from 'react-moment';
import Spinner from 'reactstrap/lib/Spinner';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  Media,
} from 'reactstrap';

import { Firebase } from '../../../lib/firebase';
import * as EmailValidator from 'email-validator';

import myInfoImg from '../../../images/icons/my_profile.png';
import closeImg from '../../../images/icons/close.png';

import {
  sendMoney,
  inviteFriendEmail,
  getFriends,
  removeFriend,
} from '../../../actions/member';
import * as constants from '../../../constants/constants';
import { errorMessages } from '../../../constants/messages';
import coinImg from '../../../images/coin.svg';
import config from '../../../constants/config';
import PaginationOld from './PaginationOld';

class Friends extends React.Component {
  static propTypes = {
    socProvider: PropTypes.string,
    //  role: PropTypes.string,
    friends: PropTypes.shape(),
    i18n: PropTypes.shape(),
    achievementsNotification: PropTypes.bool,
    t: PropTypes.func.isRequired,
    SendMoneyToFriend: PropTypes.func.isRequired,
    fetchFriends: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    isLoading: PropTypes.bool,
    inviteFriendEmailFunc: PropTypes.func.isRequired,
    removeAFriend: PropTypes.func.isRequired,
  }

  static defaultProps = {
    socProvider: '',
    //  role: '',
    friends: {},
    i18n: null,
    achievementsNotification: false,
    fontMode: constants.FONT_MODE.normal,
    isLoading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      amount: 0,
      inviteFriendModalOpen: false,
      inviteEmail: '',
      alertOpen: false,
      alertType: '',
      message: '',
      devMode: false,
      friendId: '',
      currentPage: 0,
      pageSize: 20,
    };

    //  this.toggle = this.toggle.bind(this);
    //  this.sendMoney = this.sendMoney.bind(this);
    //  this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const {
      fetchFriends, socProvider,
    } = this.props;

    const { hostname } = window.location;

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }

    this.setState({ devMode });

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  if (resp && resp.data) {
                    fetchFriends(resp).then((res) => {
                      console.log(res);
                    });
                  }
                },
              );
            }
          });
        } else {
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (event.target.name === 'amount') {
      if (value.toString().length > 3) return;
      
      this.setState({
        [event.target.name]: value
          .replace(/[^0-9]/g, '')  // Remove non-numeric characters
          .replace(/^0+/, '')  // Remove leading zeros
      });
    } else {
      this.setState({
        [event.target.name]: value,
      });
    }
  }

  inviteFriend = () => {
    const { socProvider } = this.props;

    if (socProvider === 'facebook') {
      window.FB.ui({
        method: 'apprequests',
        message: 'Come Play Zole',
      }, () => {
      });
    } else {
      // Draugiem invite
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      alertOpen: false,
      alertType: '',
      message: '',
      friendId: '',
      amount: 0
    }));
  }

  openModal = (id) => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      friendId: id,
    }));
  }

  toggleInviteFriend = () => {

    this.setState(previous => ({
      inviteFriendModalOpen: !previous.inviteFriendModalOpen,
      alertOpen: false,
      alertType: '',
      message: '',
      inviteEmail: '',
    }));
  }

  handleInviteFriendEmail = () => {
    const { inviteFriendEmailFunc } = this.props;
    const { inviteEmail } = this.state;
    if (!inviteEmail || !EmailValidator.validate(inviteEmail)) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: errorMessages.wrongEmail,
      });
    }

    inviteFriendEmailFunc(inviteEmail).then((res) => {
      if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
        this.setState({
          alertOpen: true,
          alertType: constants.FROUNT_SIDE_STATUS.success,
          message: 'verifiedEmailSent',
        });
      } else {
        this.setState({
          alertOpen: true,
          alertType: res.status,
          message: res.message,
        });
      }
    });
  }

  sendMoney = () => {
    const { SendMoneyToFriend, t } = this.props;

    const { friendId, amount } = this.state;
    if (!friendId) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'noSelectedFriend',
      });
    }
    if (amount === 0) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'noAmount',
      });
    }
    if (amount > 100) {
      return this.setState({
        alertOpen: true,
        alertType: constants.FROUNT_SIDE_STATUS.danger,
        message: 'sentPerDayMoney',
      });
    }
    if (friendId && amount) {
      SendMoneyToFriend(friendId, amount).then(res => {
        if (res && res.status === constants.FROUNT_SIDE_STATUS.success) {
          this.setState({
            alertOpen: true,
            alertType: constants.FROUNT_SIDE_STATUS.success,
            message: 'sentMoney',
          });
        } else {
          if (res.message === errorMessages.sentPerDayMoneyLimit) {
            this.setState({
              alertOpen: true,
              alertType: constants.FROUNT_SIDE_STATUS.danger,
              message: errorMessages.sentPerDayMoneyLimit,
              restMoney: res.restMoney || 0,
            });
          } else {
            this.setState({
              alertOpen: true,
              alertType: constants.FROUNT_SIDE_STATUS.danger,
              message: 'failedSentMoney',
            });
          }
        }
      });
      // this.setState(prevState => ({
      //   modal: !prevState.modal,
      // }));
    }
  }

  handleRemoveFriend = (selectId) => {
    const { removeAFriend } = this.props;
    removeAFriend(selectId);
  }

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  render() {
    const {
      t, isLoading, changeTab, socProvider, achievementsNotification, i18n, friends, fontMode
    } = this.props;
    const {
      modal,
      amount,
      inviteEmail,
      inviteFriendModalOpen,
      alertOpen,
      alertType,
      message,
      devMode,
      restMoney,
      currentPage,
      pageSize,
    } = this.state;

    let pagesCount = Math.ceil(size(friends) / pageSize);

    return (
      <div className="my-info">
        <Helmet>
          <title>
            Zole - {t('route.friends')}
          </title>
        </Helmet>
        <Row className="my-info-header">
          {/*  <Col xs="4" sm="4">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text">
              {t('myInfo.friends')}
            </div>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className="my-info-header-button active" onClick={() => changeTab('9')}>
              {t('myInfo.friends')}
            </Button>
          </Col>
          <Col className="menu-topTab">
            <Button color="link" className={`my-info-header-button ${i18n.language === 'ru' ? 'my-info-header-button-ru' : null}`} onClick={() => changeTab('10')}>
              {t('myInfo.ignoredPlayers')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab('11')}>
              {t('myInfo.achievements')}
            </Button>
          </Col>
          <Col className="menu-topTab" style={{ marginRight: 15 }}>
            <Button color="link" className={`my-info-header-button`} onClick={() => changeTab('12')}>
              {t('myInfo.results')}
            </Button>
          </Col> */}

          <Col xs="3">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text">
              {t('myInfo.friends')}
            </div>
          </Col>
          <Col xs="9" style={{ paddingRight: 30 }}>

            <Row>
              {/* Stop for production mode(change xs=3 to xs2 for first 3) */}
              <Col xs="2" className="menu-topTab friends-menu-1">
                <Button color="link" className="my-info-header-button active" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('myInfo.friends')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('myInfo.ignoredPlayers')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab achievements-menu">
                {/* <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}> */}
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('myInfo.achievements')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('myInfo.results')}
                </Button>
              </Col>
              {/* Stop for production mode*/}
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('myInfo.weeklyStatus')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('myInfo.gameLogs')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-info-content-joyride" style={{height: '100%', paddingTop: 24}}>
          <Col sm="12">
            <Row className="friends">
              <Col sm="12" className={`friends-table ${config.isInAppFrame() && 'friends-table-iframe'}`}>
                <Row className="friends-table-header">
                  <div className="friends-table-header-col text-left" style={{width: '12%', padding: '0px 15px'}}>
                    {t('common.name')}
                  </div>
                  <div className="friends-table-header-col" style={{width: '13%', padding: '0px 15px'}}>
                    {t('common.level')}
                  </div>
                  <div className="friends-table-header-col text-left" style={{width: '14%', padding: '0px 15px'}}>
                    {t('common.balance')}
                  </div>
                  <div className="friends-table-header-col" style={{width: '11%', padding: '0px 15px'}}>
                    {t('common.points')}
                  </div>
                  <div className="friends-table-header-col" style={{width: '16%', padding: '0px 15px'}}>
                    {t('common.playingSince')}
                  </div>
                  <div className="friends-table-header-col" style={{width: '16%', padding: '0px 15px'}}>
                    {t('friends.sendMoney')}
                  </div>
                  <div className="friends-table-header-col" style={{width: '18%', padding: '0px 15px'}}>
                    {t('friends.unFriend')}
                  </div>
                </Row>
                {
                  isLoading ? (
                    <div className="top-table-spinner-loading-section d-flex align-items-center">
                      <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                    </div>
                  ) : (
                    friends && size(friends) > 0 ? (
                      map(friends.slice(currentPage * pageSize, (currentPage + 1) * pageSize), (item, index) => (
                        <Row key={index} className="friends-table-row mt-2 mb-1">
                          <div className="friends-table-row-col text-left" style={{width: '12%', padding: '0px 15px'}}>
                            {item.name}
                          </div>
                          <div className="friends-table-row-col" style={{width: '13%', padding: '0px 15px'}}>
                            {item.lvl}
                          </div>
                          <div className="friends-table-row-col text-left" style={{width: '14%', padding: '0px 15px'}}>
                            {item.bal}
                          </div>
                          <div className="friends-table-row-col" style={{width: '11%', padding: '0px 15px'}}>
                            {item.totalPnts}
                          </div>
                          <div className="friends-table-row-col" style={{width: '16%', padding: '0px 15px'}}>
                            <Moment format="DD-MM-YYYY" locale="lv">
                              {item.dateCreated}
                            </Moment>
                          </div>
                          {
                            devMode && (
                              <div className="friends-table-row-col" style={{width: '16%', padding: '0px 15px'}}>
                                <Button color="link" className="my-info-header-button" onClick={() => this.openModal(item.uid)} >
                                  {t('friends.send')}
                                </Button>
                              </div>
                            )
                          }
                          <div className="friends-table-row-col" style={{width: '18%', padding: '0px 15px'}}>
                            <Button color="link" className="my-info-header-button" onClick={() => this.handleRemoveFriend(item.uid)} >
                              {t('common:top.removeFriend')}
                            </Button>
                          </div>
                        </Row>
                      ))
                    ) : (
                      <div className="top-table-spinner-loading-section d-flex align-items-center flex-column">
                        {t('home.noFriend')}

                        {(friends && size(friends) === 0 && ['facebook', 'password', 'google', 'inbox', 'draugiem'].includes(socProvider)) && (
                          <Button
                            color="link"
                            className="send-money-block-invite-button mb-0 mt-1"
                            onClick={socProvider === 'facebook' ? this.inviteFriend : this.toggleInviteFriend}
                          >
                            {t('sendMoney.inviteFriend')}
                          </Button>
                        )}
                      </div>
                    )
                  )}
              </Col>
              <Col xs="12">
                <PaginationOld
                  show={size(friends) > pageSize || true}
                  currentPage={currentPage}
                  pagesCount={pagesCount}
                  handlePagination={this.handlePagination}
                />
              </Col>

              {/*  <Col sm="3" style={{ paddingLeft: 0 }}>  */}

              <Modal container={'div > div'} className={`friends-modal old-modal root-font-${fontMode}`} isOpen={modal} toggle={this.toggle}>
                <ModalHeader
                  className="friends-modal-header"
                  toggle={this.toggle}
                  close={
                    <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggle} />
                  }
                >
                  {t('sendMoney.sendMoney')}
                </ModalHeader>
                <ModalBody className="friends-modal-body">
                  <Fragment>
                    <Alert color={alertType} isOpen={alertOpen}>
                      <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                      <p className="alert-heading alert-heading-body">
                        {
                          message === errorMessages.sentPerDayMoney ? (
                            <div className='d-flex flex-row'>
                              <span>
                                {t('sendMoney.sentPerDayMoney')}
                              </span>
                              <Media
                                src={coinImg}
                                width={18}
                                className='ml-1 mr-1'
                              />
                              <span>
                                {t('sendMoney.perFriend')}
                              </span>
                            </div>
                          ) : message === errorMessages.sentPerDayMoneyLimit ? (
                            <div className='d-flex flex-column'>
                              <div className='d-flex flex-row'>
                                <span>
                                  {t('sendMoney.sentPerDayMoney')}
                                </span>
                                <Media
                                  src={coinImg}
                                  width={18}
                                  className='ml-1 mr-1'
                                />
                                <span>
                                  {t('sendMoney.perDayFriend')}
                                </span>
                              </div>
                              <div>
                                {t(`sendMoney.sentPerDayMoneyLimit`, {restMoney})}
                              </div>
                            </div>
                          ) : (
                            t(`sendMoney.${message}`)
                          )}
                      </p>
                    </Alert>
                  </Fragment>
                  <Form className="friends-modal-body-form">
                    <FormGroup>
                      <Label for="amount">{t('common:sendMoney.enterText')}</Label>
                      <Input type="text" name="amount" max={100} min={0} id="amount" value={amount} placeholder="0" onChange={this.handleChange} />
                    </FormGroup>
                  </Form>

                </ModalBody>
                <ModalFooter className="friends-modal-footer">
                  <Button className="modal-footer-button" color="secondary" onClick={this.sendMoney}>{t('common.send')}</Button>
                  <Button className="modal-footer-button" color="secondary" onClick={this.toggle}>{t('common.cancel')}</Button>
                </ModalFooter>
              </Modal>
              {/*  </Col> */}
              <Modal container={'div > div'} className={`friends-modal old-modal root-font-${fontMode}`} isOpen={inviteFriendModalOpen} toggle={this.toggleInviteFriend}>
                <ModalHeader
                  className="friends-modal-header"
                  toggle={this.toggleInviteFriend}
                  close={
                    <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.toggleInviteFriend} />
                  }
                >
                  {t('sendMoney.inviteFriend')}
                </ModalHeader>
                <ModalBody className="friends-modal-body">
                  <Fragment>
                    <Alert color={alertType} isOpen={alertOpen}>
                      <h5 className="alert-heading alert-heading-message">{alertType === constants.FROUNT_SIDE_STATUS.success ? t('support.success') : t('support.appearError')}</h5>
                      <p className="alert-heading alert-heading-body">{t(`member.${message}`)}</p>
                    </Alert>
                  </Fragment>
                  <Form className="friends-modal-body-form">
                    <FormGroup>
                      <Label for="amount">{t('home.email')}</Label>
                      <Input type="text" name="inviteEmail" id="inviteEmail" value={inviteEmail} placeholder={t('home.email')} onChange={this.handleChange} />
                    </FormGroup>
                  </Form>

                </ModalBody>
                <ModalFooter className="friends-modal-footer">
                  <Button className="modal-footer-button" color="secondary" onClick={this.handleInviteFriendEmail}>{t('common.send')}</Button>
                  <Button className="modal-footer-button" color="secondary" onClick={this.toggleInviteFriend}>{t('common.cancel')}</Button>
                </ModalFooter>
              </Modal>
            </Row>
            {(friends && size(friends) > 0 &&['facebook', 'password', 'google', 'inbox', 'draugiem'].includes(socProvider)) && (
              <Row>
                <Col xs="12">
                  <Button
                    color="link"
                    className="send-money-block-invite-button"
                    onClick={socProvider === 'facebook' ? this.inviteFriend : this.toggleInviteFriend}
                  >
                    {t('sendMoney.inviteFriend')}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  friends: state.member.friends || {},
  isLoading: state.member.isLoading || false,
  socProvider: state.member.socProvider || null,
  role: state.member.role || null,
});

const mapDispatchToProps = {
  SendMoneyToFriend: sendMoney,
  inviteFriendEmailFunc: inviteFriendEmail,
  fetchFriends: getFriends,
  removeAFriend: removeFriend
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Friends));
