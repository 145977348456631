import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Row,
  Col,
  Form,
  Label,
  Alert,
  Input,
  Button,
  FormGroup,
  // Media,
} from 'reactstrap';

import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Firebase } from '../../../lib/firebase';
import {
  // isMobileSafari,
  isMobile,
} from 'react-device-detect';
import RecoverEmail from './RecoverEmail';
import logoImg from '../../../images/Menu/zole_logo.webp';

import { fbLoginPopup as checkFacebookLoginState, draugiemAuth, afterGoogleLogin } from '../../../actions/member';
import ResetPassword from './ResetPassword';
import * as constants from '../../../constants/constants';
import draLoginImg from '../../../images/landing-view/dra-login.webp';
import fbLoginImg from '../../../images/landing-view/fb-login.webp';
import googleLoginImg from '../../../images/landing-view/google-login.webp';

import closeImg from '../../../images/landing-view/close.svg';

import { errorMessages } from '../../../constants/messages';
import {
  FONT_MODE,
} from '../../../constants/constants';

import { saveRefferalToken } from '../../../actions/refferal';

// import defaultFont from '../../../images/redesign/top-row/defaultFont.webp';
// import largeFont from '../../../images/redesign/top-row/largeFont.webp';
// import xLargeFont from '../../../images/redesign/top-row/xLargeFont.webp';
// import maxFont from '../../../images/redesign/top-row/maxFont.png';

//import history from '../../history';

class Login extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    error: PropTypes.string,
    success: PropTypes.string,
    getDraugiemAuthUrl: PropTypes.func.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    checkFacebookLogin: PropTypes.func.isRequired,
    afterGoogleLoginFunction: PropTypes.func.isRequired,
    loadingSubmit: PropTypes.bool,
    messageType: PropTypes.string,
    resetPasswordFlag: PropTypes.bool,
    onSubmitResetPassword: PropTypes.func.isRequired,
    recoverUser: PropTypes.shape({}),
    toggleRecoverUser: PropTypes.func.isRequired,
    onRecoverUserSubmit: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    toggleFont: PropTypes.func.isRequired,
    saveRefToken: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
    success: null,
    member: {},
    loadingSubmit: false,
    messageType: constants.stages.login,
    resetPasswordFlag: false,
    recoverUser: null,
    fontMode: constants.FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {
      email: (props.member && props.member.email) ? props.member.email : '',
      password: '',
      FB: null,
      error: null,
      success: null,
      loading: false,
      draugiemAuthUrl: null,
      actionCode: null,
      lang: null,
      confirmPass: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.FBLogin = this.FBLogin.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.draLogin = this.draLogin.bind(this);
    this.draAuthHandler = this.draAuthHandler.bind(this);
  }


  /* componentDidMount() {
    if (window.FB) {
      this.setState({ FB: window.FB });
    } else {
      document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
    }

    console.log('componentDidMount');

    if (window.location) {
      const { getDraugiemAuthUrl } = this.props;

      const loc = window.location;
      const redirectUrl = `${loc.protocol}//${loc.host}/dra-redirect`;

      getDraugiemAuthUrl(redirectUrl).then((result) => {
        console.log('result');
        console.log(result.data);
        //  const popup = window.open(result.data.url, 'Draugiem.lv', 'width=200,height=200');

        this.setState({ draugiemAuthUrl: result.data.url });
      });
    }
  } */

  componentDidMount() {
    const { toggleFont, fontMode, saveRefToken } = this.props;

    if (window.FB) {
      this.setState({ FB: window.FB });
    } else {
      document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
    }
    const { resetPasswordFlag } = this.props;
    const { search } = window.location;
    let actionCode;
    let lang;
    let email;
  //  let refferalToken;

    if (resetPasswordFlag) {
      try {
        const urlParams = new URLSearchParams(search);
        actionCode = urlParams.get('actionCode');
        email = urlParams.get('email');
        lang = urlParams.get('lang') || constants.sendLanguage.lv;
      //  refferalToken = urlParams.get('refferalToken');
      } catch (err) {
        actionCode = this.getParameterByName('actionCode');
        lang = this.getParameterByName('lang') || constants.sendLanguage.lv;
        email = this.getParameterByName('email');
      //  refferalToken = this.getParameterByName('refferalToken');
      }

     // console.log('refferalToken', refferalToken);

      this.setState({ email, actionCode, lang });

     // saveRefToken(refferalToken);
    }
  }

  componentDidUpdate() {
    console.log('componentDidUpdate login');
  }

  componentWillUnmount() {
    document.removeEventListener('FBObjectReady', this.initializeFacebookLogin);
  }

  // FB stuff

  initializeFacebookLogin = () => {
    this.setState({ FB: window.FB });
    // this.checkFacebookLoginStatus();
  }

  /* checkFacebookLoginStatus = () => {
    const { FB } = this.state;
    FB.getLoginStatus(this.facebookLoginHandler);
  } */

  facebookLoginHandler = (response, fromLoginForm) => {
    const { i18n, checkFacebookLogin, history } = this.props;

    console.log('facebookLoginHandler', { checkFacebookLogin, response, fromLoginForm });

    checkFacebookLogin(response, i18n.language).then((res) => {
      if (res.data) {
        if (res.data.uid) {
          console.log('history.push');

          try {
            localStorage.setItem('justLoggedIn', 'true'); //x
          } catch (err) {
            console.log(err);
          }

          history.push('/');
          this.setState({ date: Date.now() });
        //  window.location.reload();
        } else {
          if (fromLoginForm) this.setState({ success: null }); // error: 'notLoggedIn',
          this.setState({ loading: false });
        }
      } else {
        if (fromLoginForm) this.setState({ success: null }); // error: 'notLoggedIn',
        this.setState({ loading: false });
      }
    }).catch((err) => {
      console.log(err);

      this.setState({ success: null, loading: false });
    });
  }


  FBLogin = (e) => {
    e.preventDefault();
    const { FB, loading } = this.state;

    console.log('FBLogin', { FB, loading });

    this.setState({ loading: true });

    if (!FB || loading) return;

    FB.getLoginStatus((response) => {
      console.log('FB res', { response });
      if (response.status === 'connected') {
        this.facebookLoginHandler(response, true);
      } else {
        FB.login((response2) => { console.log('FB res', { response }); this.facebookLoginHandler(response2, true); });
      }
    });
  }

  googleLogin = (e) => {
    e.preventDefault();
    const { i18n, afterGoogleLoginFunction, history } = this.props;
    const { loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    const provider = new Firebase.auth.GoogleAuthProvider();
    provider.addScope('email');

    Firebase.auth().languageCode = i18n.language;

    Firebase.auth().signInWithPopup(provider).then((_result) => {
      console.log('_result');
      console.log(_result);

      if (_result.additionalUserInfo) {
        afterGoogleLoginFunction(_result.additionalUserInfo, i18n.language).catch((err) => {
          console.log(err);
        });
      }

      try {
        localStorage.setItem('justLoggedIn', 'true'); //x
      } catch (err) {
        console.log(err);
      }

      console.log('history.push');
      history.push('/');
      this.setState({ date: Date.now() });
    //  window.location.reload();
    }).catch((error) => {
      if (error.code != 'auth/popup-closed-by-user') {
        this.setState({ error: error.code, success: null });
      }
      this.setState({ loading: false });
    });
  }

  // Draugiem stuff

  draAuthHandler = async (e) => {
    window.removeEventListener('dr_auth_code', this.draAuthHandler);

    const { i18n, history, draugiemAuthFunc } = this.props;
    console.log('draAuthHandler 1');

    const code = (e.detail && e.detail.dr_auth_code) ? e.detail.dr_auth_code : null;

    if (!code) {
      this.setState({ error: 'loginFailure', success: null, loading: false });
      return;
    }

    this.setState({ loading: true });

    console.log('draAuthHandler 2', draugiemAuth, draugiemAuthFunc);

    await draugiemAuthFunc(code, false, i18n.language).then(() => {
      console.log('draAuthHandler 3');
      if (Firebase.auth().currentUser) {
        try {
          localStorage.setItem('justLoggedIn', 'true'); //x
        } catch (err) {
          console.log(err);
        }

        history.push('/');
        this.setState({ date: Date.now() });

      //  window.location.reload();
      } else {
        this.setState({ loading: false, error: 'loginFailure', success: null });
      }
    }).catch((error) => {
      console.log('draAuthHandler err', error);
      this.setState({ loading: false, error: 'loginFailure', success: null });
    });

    console.log('draAuthHandler 4');
  };

  draLogin = (e) => {
    e.preventDefault();
    const { getDraugiemAuthUrl } = this.props;
    const { loading, draugiemAuthUrl } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    //  const popup = window.open();

    if (draugiemAuthUrl) {
      console.log('has draugiemAuthUrl', { draugiemAuthUrl });
      // const popup = window.open(draugiemAuthUrl, 'Draugiem.lv', 'width=200,height=200');
      // pop-up solution for iOS Safari
      let popup;
      setTimeout(() => {
        popup = window.open(draugiemAuthUrl, 'Draugiem.lv', 'width=200,height=200');
      });
      //  console.log('popup', { popup });

      //  popup.location(result.data.url);

      window.addEventListener('dr_auth_code', this.draAuthHandler, false);

      let leftDomain = false;
      const interval = setInterval(() => {
        console.log('run interval', { leftDomain });
        try {
        //  console.log('popup.document', { document: popup.document, leftDomain });

          if (popup.document && popup.document.domain === document.domain) {
            console.log('popup.document', { readyState: popup.document.readyState, leftDomain });

            if (leftDomain && popup.document.readyState === 'complete') {
              clearInterval(interval);

              let authCodeText;
              try {
                const urlParams = new URLSearchParams(popup.document.location.search);
                authCodeText = urlParams.get('dr_auth_code');
              } catch (err) {
                console.log(err);
                authCodeText = this.getParameterByName('dr_auth_code');
              }

              //  const urlParams = new URLSearchParams(popup.document.location.search);

              //  const authCodeText = urlParams.get('dr_auth_code')

              console.log(authCodeText);

              const evt = document.createEvent('CustomEvent');
              evt.initCustomEvent('dr_auth_code', false, false, { dr_auth_code: authCodeText });

              window.dispatchEvent(evt);

              popup.close();
            } else {
              console.log('test 1');
              leftDomain = true;
            }
          } else {
            console.log('test 2');
            leftDomain = true;

            clearInterval(interval);

            this.setState({ loading: false });
          }
        } catch (err) {
          console.log('popup err', { err });
          if (popup.closed) {
            console.log('popup', { closed: popup.closed });
            clearInterval(interval);

            this.setState({ loading: false });

            return;
          }

          leftDomain = true;
        }
      }, 1000);
    } else {
      const loc = window.location;
      const redirectUrl = `${loc.protocol}//${loc.host}/dra-redirect`;

      console.log('redirectUrl');
      console.log(redirectUrl);

      getDraugiemAuthUrl(redirectUrl).then((result) => {
        console.log('result');
        console.log(result.data);
        // const popup = window.open(result.data.url, 'Draugiem.lv', 'width=200,height=200');
        // pop-up solution for iOS Safari
        let popup;
        setTimeout(() => {
          popup = window.open(result.data.url, 'Draugiem.lv', 'width=200,height=200');
        })
        //  console.log('popup', { popup });

        //  popup.location(result.data.url);

        window.addEventListener('dr_auth_code', this.draAuthHandler, false);

        let leftDomain = false;
        const interval = setInterval(() => {
          console.log('run interval', { leftDomain });
          try {
          //  console.log('popup.document', { document: popup.document, leftDomain });

            if (popup.document && popup.document.domain === document.domain) {
              console.log('popup.document', { readyState: popup.document.readyState, leftDomain });

              if (leftDomain && popup.document.readyState === 'complete') {
                clearInterval(interval);

                let authCodeText;
                try {
                  const urlParams = new URLSearchParams(popup.document.location.search);
                  authCodeText = urlParams.get('dr_auth_code');
                } catch (err) {
                  console.log(err);
                  authCodeText = this.getParameterByName('dr_auth_code');
                }

                //  const urlParams = new URLSearchParams(popup.document.location.search);

                //  const authCodeText = urlParams.get('dr_auth_code')

                console.log(authCodeText);

                const evt = document.createEvent('CustomEvent');
                evt.initCustomEvent('dr_auth_code', false, false, { dr_auth_code: authCodeText });

                window.dispatchEvent(evt);

                popup.close();
              } else {
                console.log('test 1');
                leftDomain = true;
              }
            } else {
              console.log('test 2');
              leftDomain = true;

              clearInterval(interval);

              this.setState({ loading: false });
            }
          } catch (e) {
            console.log('popup e', { e });
            if (popup.closed) {
              console.log('popup', { closed: popup.closed });
              clearInterval(interval);

              this.setState({ loading: false });

              return;
            }

            leftDomain = true;
          }
        }, 1000);

        /*  const timer = setInterval(() => {
          if(popup && popup.closed) {
            clearInterval(timer);
            this.setState({loading: false});
          }
        }, 1000);  */
      }).catch((_error) => {
        console.log(_error);
        this.setState({ loading: false });
      });
    }
  }

  // Form stuff

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();
    const { onFormSubmit, i18n, history } = this.props;
    const { email, password, loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });
    try {
      localStorage.setItem('emailStauts', JSON.stringify(email)); //x
    } catch (err) {
      console.log(err);
    }

    onFormSubmit({ email, password }, i18n.language)
      .then(() => {
        console.log('history.push');
        history.push('/');
        this.setState({ date: Date.now() });
      //  window.location.reload();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  goToLanding = (e) => {
    e.preventDefault();
    const { history } = this.props;

    console.log('history', { history });

    history.push('/landing');

    this.setState({ date: Date.now() });

  //  window.location.reload();
  }

  goToForgotPassword = (e) => {
    e.preventDefault();
    const { history } = this.props;

    console.log('history', { history });

    ReactGA.event({
      category: 'Landing',
      action: 'Landing - Reset Password',
    });

    history.push('/forgot-password');

    this.setState({ date: Date.now() });
  }

  goToSignUp = (e) => {
    e.preventDefault();
    const { history } = this.props;

    console.log('history', { history });

    history.push('/sign-up');

    this.setState({ date: Date.now() });
  }

  submitResetPassword = () => {
    const { onSubmitResetPassword } = this.props;
    const { password, confirmPass, actionCode, email, lang } = this.state;
    this.setState({ date: Date.now() });
    onSubmitResetPassword(email, lang, actionCode, password, confirmPass);
  }

  handleRecoverUserSubmit = (recover) => {
    const { loading } = this.state;
    const { onRecoverUserSubmit, history, i18n } = this.props;

    if (loading) return;

    console.log('handleRecoverUserSubmit', { recover, language: i18n.language });
    onRecoverUserSubmit(recover, i18n.language);
  }

  toggleFontFunc = () => {
    const { toggleFont, fontMode } = this.props;
    if (fontMode === FONT_MODE.normal) {
      toggleFont(FONT_MODE.large);
    } else if (fontMode === FONT_MODE.large) {
      toggleFont(FONT_MODE.xLarge);
    } else {
      toggleFont(FONT_MODE.normal);
    }
  }

  render() {
    const { success, error, t, loadingSubmit, messageType, resetPasswordFlag, recoverUser, toggleRecoverUser, fontMode } = this.props;
    const {
      email, password, FB, success: stateSuccess, error: stateError, loading, actionCode, confirmPass, lang
    } = this.state;

    console.log('render login');

    return (

      <div className="landing-container-body">
        <Row className="landing-header">
          <Col sm="10">
            <img className="landing-header-logo" src={logoImg} />
          </Col>

          <Col sm="2" className="landing-header-links">
            {/* <Media src={fontMode === constants.FONT_MODE.xLarge ? xLargeFont : fontMode === constants.FONT_MODE.large ? largeFont : defaultFont} className="user-settings-wrapper-font-mode cursor-pointer mr-2" alt="x" onClick={() => this.toggleFontFunc()} /> */}
            {/* <Link to="/landing">
              {t('home.close')}
              <img src={closeImg} />
            </Link> */}
            <a href="#" onClick={this.goToLanding}>
              <span>
                {t('home.close')}
                <img src={closeImg} />
              </span>
            </a>
          </Col>
        </Row>
        {
          resetPasswordFlag && actionCode ? (
            <ResetPassword
              submitResetPassword={this.submitResetPassword}
              email={email}
              password={password}
              confirmPass={confirmPass}
              lang={lang}
              messageType={messageType}
              error={error}
              success={success}
              loading={loadingSubmit}
              handleChange={this.handleChange}
            />
          ) : (
            <div className={classNames('landing-content', {
              'landing-content-mobile': isMobile,
            })}>
              <div className="landing-form-title">
                <h1>{t('home.loginWith')}</h1>
              </div>
              <div className="landing-login-with">
                <a href="#" className={!FB || loading ? 'disabled' : ''} onClick={this.FBLogin}><img src={fbLoginImg} /></a>
                <a href="#" className={loading ? 'disabled' : ''} onClick={this.googleLogin}><img src={googleLoginImg} /></a>
                <a href="#" className={loading ? 'disabled' : ''} onClick={this.draLogin}><img src={draLoginImg} /></a>
              </div>

              <div className="landing-login-or">
                <hr />
                <span>{t('home.or')}</span>
              </div>

              <Row style={{ margin: 0 }}>
                <Col lg={{ size: 4, offset: 4 }} style={{ textAlign: 'center' }}>
                  {(!!success || !!stateSuccess) && <Alert color="success">{t(`member.${success || stateSuccess}`)}</Alert>}
                  {(!!error || !!stateError) && <Alert color="danger">{t(`member.${error || stateError}`)}</Alert>}
                  <Form onSubmit={this.handleSubmit} className="common-form">
                    <FormGroup>
                      <Label for="email">
                        {t('home.email')}
                        *
                      </Label>
                      <Input
                        name="email"
                        id="email"
                        placeholder="john@doe.corp"
                        disabled={loading}
                        value={email}
                        onChange={this.handleChange}
                        maxLength={100}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">
                        {t('home.pass')}
                        *
                      </Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        disabled={loading}
                        value={password}
                        onChange={this.handleChange}
                        maxLength={100}
                      />
                    </FormGroup>

                    <Row style={{ justifyContent: 'space-between' }}>
                      <div className="text-left">
                        {/* <Link className="common-link" to="/sign-up">{t('home.register')}</Link> */}
                        <a href="#" className="common-link" onClick={this.goToSignUp}>
                          <span>
                            {t('home.register')}
                          </span>
                        </a>
                      </div>
                      <div className="text-right">
                        {/*  <Link className="common-link" to="/forgot-password">{t('home.resetPassword')}</Link>  */}
                        <a href="#" className="common-link" onClick={this.goToForgotPassword}>
                          <span>
                            {t('home.forgotPass')}
                          </span>
                        </a>
                      </div>
                    </Row>

                    <Button className="common-button lite-shadow submit-button" disabled={loading}>
                      {loading ? t('home.loading') : t('home.login') }
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          )
        }
        {
          error === errorMessages.changedEmail && (
            <RecoverEmail fontMode={fontMode} recoverUser={recoverUser} toggleRecoverUser={toggleRecoverUser} error={error} loading={loadingSubmit} recoverUserSubmit={this.handleRecoverUserSubmit} />
          )
        }
      </div>

    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  checkFacebookLogin: checkFacebookLoginState,
  afterGoogleLoginFunction: afterGoogleLogin,
  draugiemAuthFunc: draugiemAuth,
  saveRefToken: saveRefferalToken,
};

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
// export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Login));
