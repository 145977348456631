import axios from 'axios';
import moment from 'moment';
// import { getBaseOs } from 'react-native-device-info';

import {
  isAndroid,
  isIOS,
  isIOS13,
  isTablet,
  isMobile,
  isDesktop,
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileModel
} from 'react-device-detect'; 

import * as EmailValidator from 'email-validator';
import {
  map, size, get, forEach, filter, includes, // orderBy,
} from 'lodash';

import { errorMessages } from '../constants/messages';
import {
  FirebaseStorage, Firebase, FirebaseRef, LeaderboardRef, StatusRef, UserStatsRef, TournamentsRef, AdminLogsRef, RoomsRef,
} from '../lib/firebase';

// import { Firebase } from '../lib/firebase';

// let UID = (Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;

import * as constants from '../constants/constants';

import config from '../constants/config';

import LogRocket from 'logrocket';

/*
let LogRocket;
if (typeof (document) !== 'undefined') {
  import('logrocket').then((L) => {
    LogRocket = L.default;
  });
} */


const sendVerificationEmail = (language) => {
  console.log('sendVerificationEmail', language);

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  return new Promise(async (resolve) => {
    const verifyEmailFunction = Firebase.app().functions().httpsCallable('verifyEmail');

    return verifyEmailFunction({ devMode, language })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch(() => resolve({ message: errorMessages.errorSendingEmailVerification }));
  });
};

const generateRandom = (min, max) => {
  const difference = max - min;
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor(rand * difference);

  // add with min value
  rand += min;

  return rand;
};

export function signUp(formData, language) {
  const {
    email, password, password2, firstName, lastName,
  } = formData;

  let devMode = false;

  if (window && window.location) {
    const { hostname } = window.location;

    if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
      devMode = true;
    }
  }

  return (dispatch, getState) => new Promise(async (resolve, reject) => {
    // Validation rules
    if (!firstName || (firstName.trim()).length === 0) return reject({ code: errorMessages.missingFirstName });
    if (!lastName || (lastName.trim()).length === 0) return reject({ code: errorMessages.missingLastName });
    if (!email) return reject({ code: errorMessages.missingEmail });
    if (!EmailValidator.validate(email)) return resolve({ code: errorMessages.wrongEmail });
    if (!password) return reject({ code: errorMessages.missingPassword });
    if (!password2) return reject({ code: errorMessages.missingConfirmPassword });
    if (password !== password2) return reject({ code: errorMessages.passwordsDontMatch });
    if (password.length < 6) return reject({ code: errorMessages.wrongPassword });

    const { refferal } = getState();
    const { refferalToken } = refferal;

    const checkEmailInUseErrorFunction = Firebase.app().functions('europe-west1').httpsCallable('checkEmailInUseError');

    return checkEmailInUseErrorFunction({
      email,
      language,
      devMode,
    }).then((result) => {
      console.log('checkEmailInUseErrorFunction result', result);

      if (result && result.data && result.data.status === 'error') {
        return reject({ code: result.data.error || 'auth/email-already-in-use' });
      }

    // Go to Firebase
    return Firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password)
      .then((res) => {
        // Send user details to Firebase database
        console.log('signUp call', res);

        if (res && res.user.uid) {
          //  res.user.sendEmailVerification()
          //    .catch(() => console.log('Verification email failed to send'));

          const afterEmailAuthFunction = Firebase.app().functions('europe-west1').httpsCallable('afterEmailRegistration');

          afterEmailAuthFunction({
            providerData: res.user.providerData[0],
            uid: res.user.uid,
            firstName,
            lastName,
            language,
            refferalToken,
          }).then((result) => {
            console.log('afterEmailAuthFunction', result, refferalToken);
            sendVerificationEmail(language);

            dispatch(logout());

            return resolve(dispatch({
              type: 'USER_DATA',
              data: result.data,
            }));
          });
        }
      }).catch(err => {
        console.log(err, "signup call error")

        if (err.code === 'auth/email-already-in-use') {
          console.log('test 1');

          const checkEmailInUseErrorFunction = Firebase.app().functions('europe-west1').httpsCallable('checkEmailInUseError');

          checkEmailInUseErrorFunction({
            email,
            language,
            devMode,
          }).then((result) => {
            console.log('checkEmailInUseErrorFunction', result);

            if (result && result.data && result.data.status === 'success') {
              return reject({ code: result.data.userErrorCode || 'auth/email-already-in-use' });
            }

            return reject({ code: 'auth/email-already-in-use' });
          }).catch(() => {
            return reject({ code: 'auth/email-already-in-use' });
          });

           /* FirebaseRef.child('users').orderByChild('email').equalTo(email).once('value', (snapshot) => {
             const data = snapshot.val() || {};
             console.log(data, 'check user');
             console.log('test 4');
             if (data) {
               console.log('test 5');
               map(data, item => {
                 if (item?.verifiedEmail) {
                   return reject({ code: err.code })
                 } else {
                   return reject({ code: 'auth/email-already-in-use-not-confirmed' })
                 }
               });
             } else {
               console.log('test 3');
               return reject({ code: 'auth/email-already-in-use-not-confirmed' })
             }
           }).catch(err => {
             console.log('test 6');
             console.log(err);
           }); */
         } else {
          return reject({ code: err.code })
         }
      });
    }).catch(() => {
      return reject({ code: 'auth/email-already-in-use' });
    });
  }).catch(err => ({ code: err.code }));
}


export function setUsersActiveRoom(roomId) {
  return dispatch => new Promise((resolve, reject) => {
    if (!roomId) return reject();

    return resolve(dispatch({
      type: 'SET_USER_ACTIVE_ROOM',
      data: roomId,
    }));
  });
}

export function setUsersJoinedRoom(roomId) {
  return dispatch => new Promise((resolve, reject) => {
    if (!roomId) return reject();

    return resolve(dispatch({
      type: 'SET_USER_JOINED_ROOM',
      data: roomId,
    }));
  });
}


export function updateLastLoginAndLoginStreak(UID) {
  console.log('updateLastLoginAndLoginStreak', UID);
  // UPDATE DAILY LOGIN STREAK IN USERACHIEVEMENTS
  // Firebase.database().ref(`/users/${UID}`).once('value', (usersSnapshot) => {

  const promise1 = Firebase.database().ref(`/users/${UID}/lastLogin`).once('value');
  const promise2 = Firebase.database().ref(`/users/${UID}/lastLoginStreakUpdate`).once('value');

  Promise.all([promise1, promise2]).then(async (promisesRes) => {
    let lastLogin;
    let lastLoginStreakUpdate;

    promisesRes.map((promiseRes, index) => {
      if (index === 0) {
        lastLogin = promiseRes.val() || null;
      } else if (index === 1) {
        lastLoginStreakUpdate = promiseRes.val() || null;
      }
      return null;
    });

    const yesterdaysDate = moment().subtract(1, constants.FORMAT_DAY_TYPE.days).format(constants.FORMAT_DATE_TYPE.fullMonthDayYear);

    // const { lastLogin } = usersSnapshot.val();
    // const lastLoginDate = moment(lastLogin).format('MM/DD/YYYY');

      // const userData = usersSnapshot.val();
      const userData = { lastLogin, lastLoginStreakUpdate };

    console.log('userData', userData);

    try {
      localStorage.setItem('userId', UID); //x
    } catch (err) {
      console.log('err', err);
    }

    let lastLoginDate;
    if (userData && (userData.lastLogin || userData.lastLoginStreakUpdate)) {
      console.log('userData.lastLoginStreakUpdate', userData.lastLogin, userData.lastLoginStreakUpdate);

      if (userData.lastLoginStreakUpdate) {
        lastLoginDate = moment(userData.lastLoginStreakUpdate).format(constants.FORMAT_DATE_TYPE.fullMonthDayYear);
      } else {
        lastLoginDate = moment(userData.lastLogin).format(constants.FORMAT_DATE_TYPE.fullMonthDayYear);
      }
    }

    console.log('lastLoginDate', lastLoginDate, yesterdaysDate);

    console.log('moment(lastLoginDate).isBefore(yesterdaysDate)', moment(lastLoginDate).isBefore(yesterdaysDate));
    console.log('moment(lastLoginDate).isSame(yesterdaysDate)', moment(lastLoginDate).isSame(yesterdaysDate));

    // this variable is for testing/developing
    const dateString = moment(Firebase.database.ServerValue.TIMESTAMP).subtract(1, constants.FORMAT_DAY_TYPE.days).valueOf();

    // Firebase.database().ref(`userAchievements/${UID}`).once('value', (achievementSnapshot) => {

    const promiseA1 = Firebase.database().ref(`userAchievements/${UID}/dailyLogin`).once('value');
    const promiseA2 = Firebase.database().ref(`userAchievements/${UID}/maxDailyLogin`).once('value');

    Promise.all([promiseA1, promiseA2]).then(async (promisesRes) => {
      let dailyLoginAchievement;
      let maxDailyLoginAchievement;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          dailyLoginAchievement = promiseRes.val() || null;
        } else if (index === 1) {
          maxDailyLoginAchievement = promiseRes.val() || null;
        }
        return null;
      });

      let achievementData = { dailyLogin: dailyLoginAchievement, maxDailyLogin: maxDailyLoginAchievement };

      console.log('updateLastLoginAndLoginStreak achievementData', achievementData);

      if (achievementData && achievementData.dailyLogin && achievementData.maxDailyLogin && userData.lastLogin) {
        if (userData.lastLogin && moment(lastLoginDate).isBefore(yesterdaysDate)) {
          console.log('set userAchievements to 1');
          Firebase.database().ref(`userAchievements/${UID}`).update({ dailyLogin: 1 });
        }
        if (userData.lastLogin && moment(lastLoginDate).isSame(yesterdaysDate)) {
          const loginStreak = achievementData.dailyLogin ? achievementData.dailyLogin + 1 : 1;

          const highestLoginStreak = loginStreak > achievementData.maxDailyLogin || loginStreak === achievementData.maxDailyLogin ? loginStreak : achievementData.maxDailyLogin;

          console.log('set userAchievements to ', loginStreak, 'maxDailyLogin to ', highestLoginStreak);

          Firebase.database().ref(`userAchievements/${UID}`).update({
            dailyLogin: loginStreak,
            maxDailyLogin: highestLoginStreak,
          });
        }
      } else {
        console.log('set userAchievements dailyLogin and maxDailyLogin to 1');

        Firebase.database().ref(`userAchievements/${UID}`).update({ dailyLogin: 1, maxDailyLogin: 1 });
      }

      /*
      if (achievementSnapshot.val() && achievementSnapshot.val().dailyLogin && achievementSnapshot.val().maxDailyLogin && userData.lastLogin) {
        if (userData.lastLogin && moment(lastLoginDate).isBefore(yesterdaysDate)) {
          console.log('set userAchievements to 1');
          Firebase.database().ref(`userAchievements/${UID}`).update({ dailyLogin: 1 });
        }
        if (userData.lastLogin && moment(lastLoginDate).isSame(yesterdaysDate)) {
          const loginStreak = achievementSnapshot.val().dailyLogin ? achievementSnapshot.val().dailyLogin + 1 : 1;

          const highestLoginStreak = loginStreak > achievementSnapshot.val().maxDailyLogin || loginStreak === achievementSnapshot.val().maxDailyLogin ? loginStreak : achievementSnapshot.val().maxDailyLogin;

          console.log('set userAchievements to ', loginStreak, 'maxDailyLogin to ', highestLoginStreak);

          Firebase.database().ref(`userAchievements/${UID}`).update({
            dailyLogin: loginStreak,
            maxDailyLogin: highestLoginStreak,
          });
        }
      } else {
        console.log('set userAchievements dailyLogin and maxDailyLogin to 1');

        Firebase.database().ref(`userAchievements/${UID}`).update({ dailyLogin: 1, maxDailyLogin: 1 });
      } */
    });

    // Update last login
    StatusRef.child(`status/${UID}`).update({
      lastAction: Firebase.database.ServerValue.TIMESTAMP,
    });

    console.log('updateLastLoginAndLoginStreak edit', UID);

    FirebaseRef.child(`users/${UID}`).update({
      lastLogin: Firebase.database.ServerValue.TIMESTAMP,
      lastLoginStreakUpdate: Firebase.database.ServerValue.TIMESTAMP,
    });
  });
}

export function updateUserLastLogin() {
  console.log('updateUserLastLogin');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('updateUserLastLogin', UID);

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    (async function () {
      await updateLastLoginAndLoginStreak(UID);
    }());
    return resolve();
  });
}

/**
  * Get this User's Details
  */
export function getUserData(dispatch, uid) {
  let UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getUserData', { UID, uid });

  if (!UID && !uid) {
    return () => new Promise((resolve) => {
      resolve(dispatch({
        type: 'USER_LOGOUT',
        data: {},
      }));
    });
  }

  if (!UID && uid) {
    UID = uid;
  }

  // console.log('test UID', UID);

  // FirebaseRef.child(`users/${UID}/disableAutoPlay`).set(true);

  return new Promise(async (resolve) => {
    await FirebaseRef.child('settings').once('value', (snapshot) => {
      const settings = snapshot.val();
      try {
        dispatch({ type: 'SET_SETTINGS', data: { ...settings } });
      } catch (err) {
        console.log(err);
      }
      console.log('settings', { settings });
    });

    await FirebaseRef.child(`users/${UID}`).on('value', (snapshot) => {
      const userData = snapshot.val() || null;
      const providerId = get(userData, 'provierId', '');
      const email = get(userData, 'email', '');
      const contactEmail = get(userData, 'contactEmail', '');
      const verifiedEmail = get(userData, 'verifiedEmail', false);
      const fontMode = get(userData, 'fontMode', 'normal');
      const screenMode = get(userData, 'screenMode', 'normal');

      console.log('getUserData screenMode', fontMode, screenMode);

      if (providerId !== '' && providerId !== constants.EMAIL_PROVIDERS && email !== '' && email !== constants.NO_EMAIL_USER) {
        if (contactEmail !== '' || !verifiedEmail) {
          FirebaseRef.child(`users/${UID}`).update({
            contactEmail: userData.email,
            verifiedEmail: true,
          });
        }
      }
      if (providerId !== '' && providerId === constants.EMAIL_PROVIDERS && email !== '') {
        if (contactEmail !== '' || !verifiedEmail) {
          FirebaseRef.child(`users/${UID}`).update({
            contactEmail: userData.email,
            verifiedEmail: true,
          });
        }
      }

      try {
        console.log('getUserData screenMode 2', fontMode, screenMode);

        localStorage.setItem('fontMode', fontMode); //x
        localStorage.setItem('screenMode', screenMode); //x
      } catch (err) {
        console.log(err);
      }

      console.log('dispatch', dispatch);

      if (dispatch) {
        console.log('dispatch2', dispatch);
      }
      try {
        console.log('call USER_DATA', userData.verifiedEmail);
        dispatch({ type: 'USER_DATA', data: userData });
      } catch (err) {
        console.log(err);
      }
    });

    await LeaderboardRef.child(`leaderboard/allTime/${UID}/pointsPos`).on('value', (snapshot) => {
      const leaderboardPos = snapshot.val();
      try {
        dispatch({ type: 'USER_LEADERBOARD_POSITION_CHANGE', data: leaderboardPos });
      } catch (err) {
        console.log(err);
      }
    });

    await TournamentsRef().child(`playerTournamentHistory/${UID}`).on('value', (snapshot) => {
      const playerTournamentHistory = snapshot.val();
      try {
        dispatch({ type: 'USER_DATA_TOURNAMENT_HISTORY', data: playerTournamentHistory });
      } catch (err) {
        console.log(err);
      }
    });

    await FirebaseRef.child('todayHolidays').on('value', (snapshot) => {
      const todayHolidays = snapshot.val();
      try {
        dispatch({ type: 'TODAY_HOLIDAYS', data: todayHolidays });
      } catch (err) {
        console.log(err);
      }
    });


    await FirebaseRef.child(`claimedRewards/${UID}`).on('value', (snapshot) => {
      const claimedRewards = snapshot.val();
      try {
        dispatch({ type: 'CLAIMED_REWARDS', data: claimedRewards });
      } catch (err) {
        console.log(err);
      }
    });

    await Firebase.database().ref(`activeGifts/${UID}`).on('value', (snapshot) => {
      const activeGifts = snapshot.val();
      try {
        dispatch({ type: 'ACTIVE_GIFTS_REPLACE', data: activeGifts });
      } catch (err) {
        console.log(err);
      }
    });

    return resolve();
  });
}

export function getMobileVersion(dispatch) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return new Promise((resolve) => {
    FirebaseRef.child('settings/mobileVersion').once('value', (snapshot) => {
      const mobileVersion = snapshot.val() || null;

      return resolve(dispatch({ type: 'MOBILE_VERSION', data: mobileVersion }));
    });
  });
}

export function readSupportStatus(dispatch) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  // await FirebaseRef.child(`supportChat/activeChats/${UID}`).off();

  console.log('readSupportStatus', UID);

  return new Promise(resolve => FirebaseRef.child(`supportChat/activeChats/${UID}`)
    .on('value', (snapshot) => {
      const status = snapshot.val() || null;

      resolve(dispatch({
        type: 'SUPPORT_CHAT_STATUS',
        data: status,
      }));
    }));
}

export function readSupportChat(dispatch) {
  console.log('readSupportChat');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('readSupportChat 2', UID);

  if (!UID) return () => new Promise(resolve => resolve());

  FirebaseRef.child(`supportChat/messages/${UID}`).off();

  dispatch({
    type: 'SUPPORT_CHAT',
    data: {},
  });

  FirebaseRef.child(`supportChat/messagesCount/${UID}`)
    .on('value', (snapshot) => {
      const messagesCount = snapshot.val() || {};

      console.log('readSupportChat messagesCount', messagesCount);

      dispatch({
        type: 'SUPPORT_CHAT_MESSAGES_COUNT',
        data: messagesCount,
      });
    });

  return new Promise(resolve => FirebaseRef.child(`supportChat/messages/${UID}`)
    .limitToLast(100)
    .on('child_added', (snapshot) => {
      const chat = snapshot.val() || {};
      const { key } = snapshot;

      console.log('readSupportChat chat', chat, key);

      resolve(dispatch({
        type: 'SUPPORT_CHAT_CHILD',
        data: chat,
        key,
      }));
    }));
}

/*
export function stopReadSupportChat() {
  console.log('stopReadSupportChat');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch) => new Promise(async (resolve) => {
    await FirebaseRef.child(`supportChat/messages/${UID}`).off();

    return resolve();
  });
} */


export function getUserData2(dispatch, uid) {
  let UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getUserData2', { UID, uid });

  if (!UID && !uid) {
    return () => new Promise((resolve) => {
      resolve(dispatch({
        type: 'USER_LOGOUT',
        data: {},
      }));
    });
  }

  if (!UID && uid) {
    UID = uid;
  }

  return new Promise(async (resolve) => {
    await FirebaseRef.child('settings').once('value', (snapshot) => {
      const settings = snapshot.val();
      try {
        dispatch({ type: 'SET_SETTINGS', data: { ...settings } });
      } catch (err) {
        console.log(err);
      }
      console.log('settings', { settings });
    });

    await LeaderboardRef.child(`leaderboard/allTime/${UID}/pointsPos`).on('value', (snapshot) => {
      const leaderboardPos = snapshot.val();
      try {
        dispatch({ type: 'USER_LEADERBOARD_POSITION_CHANGE', data: leaderboardPos });
      } catch (err) {
        console.log(err);
      }
    });

    await TournamentsRef().child(`playerTournamentHistory/${UID}`).on('value', (snapshot) => {
      const playerTournamentHistory = snapshot.val();
      try {
        dispatch({ type: 'USER_DATA_TOURNAMENT_HISTORY', data: playerTournamentHistory });
      } catch (err) {
        console.log(err);
      }
    });

    await FirebaseRef.child('todayHolidays').on('value', (snapshot) => {
      const todayHolidays = snapshot.val();

      const todayHolidaysTest = { holiday: 'easter' };

      try {
        dispatch({ type: 'TODAY_HOLIDAYS', data: todayHolidays });
      } catch (err) {
        console.log(err);
      }
    });


    await FirebaseRef.child(`claimedRewards/${UID}`).on('value', (snapshot) => {
      const claimedRewards = snapshot.val();
      try {
        dispatch({ type: 'CLAIMED_REWARDS', data: claimedRewards });
      } catch (err) {
        console.log(err);
      }
    });

    await Firebase.database().ref(`activeGifts/${UID}`).on('value', (snapshot) => {
      const activeGifts = snapshot.val();
      try {
        dispatch({ type: 'ACTIVE_GIFTS_REPLACE', data: activeGifts });
      } catch (err) {
        console.log(err);
      }
    });

    return resolve();
  });
}

export function getUserData3(dispatch, uid) {
  let UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getUserData3', { UID, uid });

  if (!UID && !uid) {
    return () => new Promise((resolve) => {
      resolve(dispatch({
        type: 'USER_LOGOUT',
        data: {},
      }));
    });
  }

  if (!UID && uid) {
    UID = uid;
  }

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  // FirebaseRef.child(`warningPlayers/${UID}`).set(Firebase.database.ServerValue.increment(1));

  return new Promise(async (resolve) => {
    await FirebaseRef.child(`users/${UID}`).off();
    await FirebaseRef.child(`users`).off();

    await FirebaseRef.child(`users/${UID}`).once('value', (snapshotOnce) => {
      const userDataOnce = snapshotOnce.val() || null;
      console.log('getUserData3 userDataOnce', userDataOnce);

      console.log('call USER_DATA', userDataOnce.verifiedEmail);
      dispatch({ type: 'USER_DATA', data: userDataOnce });

      if (devMode && userDataOnce && !userDataOnce.autoMergeRooms && userDataOnce.autoMergeRooms !== false) {
        // add autoMergeRooms on login if was null in db
        console.log('add autoMergeRooms on login if was null in db');
        FirebaseRef.child(`users/${UID}/autoMergeRooms`).set(true);
      }

      if (LogRocket && userDataOnce && userDataOnce.enableLogRocket) {
        console.log('getUserData3 LogRocket.init');
        try {
          LogRocket.init('zole/zole');

          console.log('getUserData3 LogRocket.identify');

          LogRocket.identify(userDataOnce.uid, {
            name: userDataOnce.name,
            email: userDataOnce.email,
          });
        } catch (err) {
          console.log('getUserData3 LogRocket error');
        }
      }

    //  const { member } = getState();
    //  const { fontMode } = member;
    //  console.log('fontMode', fontMode);

      if (userDataOnce && userDataOnce.uid) {
      //  Firebase.database().ref(`users/${userDataOnce.uid}`).update({
       //   fontMode: fontMode || constants.FONT_MODE.normal,
       // });

        StatusRef.child(`status/${userDataOnce.uid}`).update({
          status: true,
          lastAction: Firebase.database.ServerValue.TIMESTAMP,
        });
      }

      // Potential db optimization by spliting user data fetching with child_changed/child_added/child_removed
      // TO DO revert back to .on if causes potential issues
      console.log('init child_changed');

      FirebaseRef.child(`users/${UID}`).on(
        'child_changed',
        (snapshot2) => {
          const userData = snapshot2.val() !== undefined ? snapshot2.val() : null;
          const { key } = snapshot2;

          console.log('child_changed', key);

          dispatch({
            type: 'USER_DATA_CHANGE',
            data: { userData, key },
          });
        },
      );

      FirebaseRef.child(`users/${UID}`).on(
        'child_added',
        (snapshot2) => {
          const userData = snapshot2.val() !== undefined ? snapshot2.val() : null;
          const { key } = snapshot2;

          console.log('child_changed 2', key);

          dispatch({
            type: 'USER_DATA_ADDED',
            data: { userData, key },
          });
        },
      );

      FirebaseRef.child(`users/${UID}`).on(
        'child_removed',
        (snapshot2) => {
          const { key } = snapshot2;

          console.log('child_changed 3', key);

          dispatch({ type: 'USER_DATA_REMOVED', data: { key } });
        },
      );

      FirebaseRef.child(`users/${UID}`).once('value', (snapshot) => {
        const userData = snapshot.val() || null;
        const providerId = get(userData, 'provierId', '');
        const email = get(userData, 'email', '');
        const contactEmail = get(userData, 'contactEmail', '');
        const verifiedEmail = get(userData, 'verifiedEmail', false);
        const fontMode = get(userData, 'fontMode', 'normal');
        const screenMode = get(userData, 'screenMode', 'normal');

        console.log('getUserData3 screenMode', fontMode, screenMode);

        if (providerId !== '' && providerId !== constants.EMAIL_PROVIDERS && email !== '' && email !== constants.NO_EMAIL_USER) {
          if (contactEmail !== '' || !verifiedEmail) {
            FirebaseRef.child(`users/${UID}`).update({
              contactEmail: userData.email,
              verifiedEmail: true,
            });
          }
        }
        if (providerId !== '' && providerId === constants.EMAIL_PROVIDERS && email !== '') {
          if (contactEmail !== '' || !verifiedEmail) {
            FirebaseRef.child(`users/${UID}`).update({
              contactEmail: userData.email,
              verifiedEmail: true,
            });
          }
        }

        try {
          console.log('getUserData3 screenMode 2', fontMode, screenMode);

          localStorage.setItem('fontMode', fontMode); //x
          localStorage.setItem('screenMode', screenMode); //x
        } catch (err) {
          console.log(err);
        }
        
        try {
          console.log('call USER_DATA', userData.verifiedEmail);
          return resolve(dispatch({ type: 'USER_DATA', data: userData }));
        } catch (err) {
          console.log(err);
          return resolve();
        }
      });

      // return resolve();
    }).catch(err => {
      console.log('err', err);
      return resolve();
    });

    // return resolve();
  });
}

export function getMemberData() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(async (loggedIn) => {
      console.log('getMemberData loggedin', loggedIn);
      // unsubscribe();
      if (loggedIn) {
        const { member } = getState();

        console.log('getMemberData loggedin uid', member.uid, loggedIn.uid);

        if (member && member.uid && member.uid !== loggedIn.uid) {
          console.log('user uid changed, cancel active listeners from old user', member.uid, loggedIn.uid);

          await FirebaseRef.child(`users/${member.uid}`).off();
          await FirebaseRef.child(`supportChat/activeChats/${member.uid}`).off();
          await FirebaseRef.child(`supportChat/messages/${member.uid}`).off();
          await FirebaseRef.child(`supportChat/messagesCount/${member.uid}`).off();

          await FirebaseRef.child(`claimedRewards/${member.uid}`).off();
          await FirebaseRef.child(`actiGifts/${member.uid}`).off();
          await LeaderboardRef.child(`leaderboard/allTime/${member.uid}/pointsPos`).off();
          await TournamentsRef().child(`playerTournamentHistory/${member.uid}`).off();
        }

        await getUserData2(dispatch, loggedIn.uid);

        console.log('getMemberData test 1');
        await getUserData3(dispatch, loggedIn.uid).then((res) => {
          console.log('getUserData3 res', res);
        });

        updateLastLoginAndLoginStreak(loggedIn.uid);

        console.log('getMemberData test 2');
        readSupportStatus(dispatch);
        readSupportChat(dispatch);
        console.log('getMemberData test 3');
        getMobileVersion(dispatch);

        console.log('getMemberData test 4');

        return resolve();
      }

      return resolve();
    });
  });
}

export function getUserRole() {
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) {
    return () => new Promise(resolve => resolve());
  }

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/role`)
      .once('value', (snapshot) => {
        const role = snapshot.val();

        dispatch({ type: 'USER_ROLE', data: role });
        return resolve(role);
      });
  });
}

/**
  * Login to Firebase with Email/Password
  */
export function login(formData, language) {
  const { email, password } = formData;

  return (dispatch, getState) => new Promise(async (resolve, reject) => {
    // Validation rules
    if (!email) return reject({ code: errorMessages.missingEmail });
    if (!password) return reject({ code: errorMessages.missingPassword });

    const promise5 = Firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password);

    const { member } = getState();
    const { fontMode } = member;

    return Promise.all([promise5]).then((promisesRes) => {
      const res = promisesRes[0];

      const userDetails = res && res.user ? res.user : null;

      if (userDetails.uid) {
        // Update last logged in data
     //   FirebaseRef.child(`users/${userDetails.uid}`).update({
        //  lastLogin: Firebase.database.ServerValue.TIMESTAMP,
     //     fontMode: fontMode || constants.FONT_MODE.normal,
      //  });

        if (userDetails.emailVerified === false) {
          Firebase.auth().languageCode = language;
          //  Firebase.auth().currentUser.sendEmailVerification()
          //    .catch(() => console.log('Verification email failed to send'));

          sendVerificationEmail(language);

          //  dispatch(logout());
          return reject({ code: errorMessages.unverifiedEmail });
        }
        const afterEmailAuthFunction = Firebase.app().functions('europe-west1').httpsCallable('afterEmailLogin');

        // console.log('call afterEmailAuthFunction');

        afterEmailAuthFunction({ language });
      }

      return resolve(dispatch({ type: 'USER_LOGIN', data: userDetails }));
    }).catch((err) => {
      console.log(err, 'login error');
      if (err.code !== errorMessages.wrongPasswordServer) {
        return FirebaseRef.child('previousEmails').orderByChild('previousEmail')
          .startAt(email.toLowerCase())
          .endAt(`${email.toLowerCase()}\uf8ff`)
          .once('value', (snapshot) => {
            const recoverUser = snapshot.val();

            console.log('recoverUser', recoverUser);

            if (recoverUser) {
              return reject({ code: errorMessages.changedEmail, recoverUser });
            }
            console.log('errCode++++', err.code);
            return reject({ code: err.code });
          });
      }

      console.log('errCode----', err.code);
      return reject({ code: err.code });
    });
  });
}

export function restoreUsersEmail() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  //  console.log('Firebase.auth().currentUser', { user: Firebase.auth().currentUser });
  return (dispatch, getState) => new Promise(async (resolve) => {
    const { member } = getState();
    const { fontMode } = member;
    console.log('fontMode', fontMode);

    if (Firebase.auth().currentUser.emailVerified) {
      FirebaseRef.child(`users/${UID}`).update({
        verifiedEmail: true,
      //  fontMode: fontMode || constants.FONT_MODE.normal,
      });
    }
    resolve(dispatch({ type: 'EMIL_VERIFY_REPLACE', data: constants.SERVER_STATUS_CODE.success }));
  });
}

export function afterEmailLogin() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  //".read": "auth != null && (root.child('users/'+auth.uid+'/role').val() === 'admin' || root.child('users/'+auth.uid+'/role').val() === 'tester')",

  //  console.log('Firebase.auth().currentUser', { user: Firebase.auth().currentUser });
  return (dispatch, getState) => new Promise(async (resolve) => {
    const { member } = getState();
    const { fontMode } = member;
    console.log('fontMode', fontMode);

    if (Firebase.auth().currentUser.emailVerified) {
      FirebaseRef.child(`users/${UID}`).update({
        verifiedEmail: true,
      //  fontMode: fontMode || constants.FONT_MODE.normal,
      });

      const claimEmailVerifiedBonusFunction = Firebase.app().functions().httpsCallable('claimEmailVerifiedBonus');

      console.log('call claimEmailVerifiedBonusFunction');

      claimEmailVerifiedBonusFunction();
    }
    resolve(dispatch({ type: 'EMIL_VERIFY_REPLACE', data: constants.SERVER_STATUS_CODE.success }));
  });
}

/*
Delete Request for 7 days
*/
export function requestDeleteUser() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve) => {
    const requestDeleteUserFunction = Firebase.app()
      .functions()
      .httpsCallable('requestDeleteUser');

    requestDeleteUserFunction().then((res) => {
      if (res.data.status === constants.SERVER_STATUS_CODE.error) {
        return resolve({ status: constants.SERVER_STATUS_CODE.error });
      }
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    });
  });
}

/*
  Send verification email to user.
*/
export function sendVerify() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = Firebase.auth().currentUser.uid;

  if (!UID) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve) => {
    //  Firebase.auth().currentUser.sendEmailVerification((res) => {})
    //    .catch((err) => resolve({ status: constants.SERVER_STATUS_CODE.error, message: err.code }));
    //  console.log("send verification link social");
    // const sendVerificationEmailFunction = Firebase.app().functions().httpsCallable('verifyEmail');

    // sendVerificationEmailFunction({
    //   uid: UID,
    //   lang: 'en',
    // }).then(() => {

    //   resolve({ status: constants.SERVER_STATUS_CODE.success });
    // });
    sendVerificationEmail();
  });
}

export function afterGoogleLogin(data, language) {
  //  console.log('afterGoogleLogin', { data, language });
  //  && !data.isNewUser
  if (Firebase === null || !(data)) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise((resolve) => {
    const { refferal } = getState();
    const { refferalToken } = refferal;

    console.log('refferalToken', refferalToken);

    const afterGoogleLoginFunction = Firebase.app().functions('europe-west1').httpsCallable('afterGoogleLogin');

    afterGoogleLoginFunction({
      data,
      language,
      refferalToken,
    }).then((result) => {
      if (result.data && result.data.providerId && result.data.profile) {
        /* if (LogRocket && result.data.enableLogRocket) {
          LogRocket.init('zole/zole');

          LogRocket.identify(UID, {
            name: result.data.profile.name,
            email: result.data.profile.email,
          });
        } */

        //  const {
        //    uid,
        //  } = result.data;
      }

      const { member } = getState();
      const { fontMode } = member;
      console.log('fontMode', fontMode);

      if (UID) {
      //  Firebase.database().ref(`users/${UID}`).update({
        //  lastLogin: Firebase.database.ServerValue.TIMESTAMP,
      //    fontMode: fontMode || constants.FONT_MODE.normal,
      //  });

        StatusRef.child(`status/${UID}`).update({
          status: true,
          lastAction: Firebase.database.ServerValue.TIMESTAMP,
        });

        /*  console.log('sendEmailVerification user', { user: Firebase.auth().currentUser });
  
          if (Firebase.auth().currentUser.emailVerified === false && Firebase.auth().currentUser.email) {
          //  Firebase.auth().languageCode = language;
            console.log('sendEmailVerification');
            Firebase.auth().currentUser.sendEmailVerification()
              .catch(() => console.log('Verification email failed to send'));
          } */
      }

      resolve(result);
    });
  });
}


export function verifyEmail() {
  return () => new Promise(async resolve => resolve(constants.SERVER_STATUS_CODE.success));
}

/**
  * Get draugiem auth url
  */
export function getDraugiemAuthUrl(redirectUrl) {
  return () => new Promise(async (resolve, reject) => {
    const fn = Firebase.app().functions('europe-west1').httpsCallable('draugiemAuthUrl');
    return fn({
      redirectUrl,
    }).then((result) => {
      resolve(result);
    }).catch(reject);
  });
}


/**
  * Login to Firebase with Facebook
  */

export function signInWithFacebook(fbToken) {
  return dispatch => new Promise((resolve, reject) => {
    //  const credential = Firebase.auth().FacebookAuthProvider.credential(fbToken);
    const credential = Firebase.auth.FacebookAuthProvider.credential(
      fbToken,
    );

    const promise5 = Firebase.auth().signInWithCredential(credential);

    Promise.all([promise5]).then((promisesRes) => {
      const user = promisesRes[0];

      // Get the user object from the realtime database
      Firebase.database.ref('users').child(user.uid).once('value')
        .then((snapshot) => {
          const exists = (snapshot.val() !== null);
          const userData = snapshot.val();

          dispatch({ type: 'USER_FB_LOGIN', userData });
          resolve({ exists, user });
        })
        .catch(error => reject(error));
    })
      .catch(error => reject(error));
  });
}


/*
const sendVerificationEmail = () => {
  console.log('sendVerificationEmail');

  return new Promise(async (resolve) => {
    const verifyEmailFunction = Firebase.app().functions().httpsCallable('verifyEmail');

    return verifyEmailFunction()
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch(() => resolve({ message: errorMessages.errorSendingEmailVerification }));
  });
}; */

/**
  * Reset Password
  */
export function resetPassword(formData, language) {
  const { email } = formData;

  return () => new Promise(async (resolve, reject) => {
    // Validation rules

    console.log('resetPassword', email, language);

    if (!email) return reject({ code: errorMessages.missingEmail });

    const sendPasswordResetEmailFunction = Firebase.app().functions('europe-west1').httpsCallable('sendPasswordResetEmail');

    return sendPasswordResetEmailFunction({ email: email.toLowerCase(), userLanguage: language })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch(err => resolve({ message: err.code }));

    // Go to Firebase
    /* Firebase.auth().languageCode = language;
     return Firebase.auth().sendPasswordResetEmail(email)
       .then(() => resolve(dispatch({ type: 'USER_RESET' })))
       .catch(err => reject({ code: err.code })); */
  });
}

/**
 * Update Profile
 */
export function updateAvatarFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = Firebase.auth().currentUser.uid;

  if (fileUpload != '') {
    const baseData = fileUpload.toString();

    return () => new Promise((resolve) => {
      console.log('called here@@@@@@@@@@@@@@@@@', UID, baseData.replace(/^data:image\/[a-z]+;base64,/, ''));
      const uploadTask = FirebaseStorage.ref(`/userAvatars/${UID}`).child(`${UID}-${(Date.now().toString())}`)
        .putString(baseData.replace(/^data:image\/[a-z]+;base64,/, ''), 'base64', { contentType: 'image/jpg' });

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: constants.SERVER_STATUS_CODE.success, url: downloadURL });
          });
        });
    });
  }
}

//
export function updateProfile(formData, addEmailFlag) {
  const {
    curPassword,
    password,
    password2,
    firstName,
    lastName,
    changePassword,
    email,
    changeEmail,
    photo,
    showCrop,
    contactEmail,
    verifiedEmail,
    addEmail,
    birthday,
  } = formData;

  return dispatch => new Promise(async (resolve, reject) => {
    // Are they a user?
    const UID = Firebase.auth().currentUser.uid;

    if (!UID) return resolve({ message: errorMessages.missingData });

    const validationFileds = [];
    // Validation rules
    if (!firstName || (firstName.trim()).length === 0) {
      // validationFileds.push({field: constants.PROFILE_FIELDS.surname, msg: errorMessages.missingFirstName});
      return resolve({ message: errorMessages.missingFirstName, msgType: constants.PROFILE_FIELDS.surname });
    }
    if (!lastName || (lastName.trim()).length === 0) {
      // validationFileds.push({field: constants.PROFILE_FIELDS.name, msg: errorMessages.missingLastName});
      return resolve({ message: errorMessages.missingLastName, msgType: constants.PROFILE_FIELDS.name });
    }

    if (showCrop) return resolve({ message: errorMessages.changePhoto });
    if (changeEmail) {
      if (!email) {
        // validationFileds.push({field: constants.PROFILE_FIELDS.email, msg: errorMessages.missingEmail});
        return resolve({ message: errorMessages.missingEmail, msgType: constants.PROFILE_FIELDS.email });
      }
      if (!EmailValidator.validate(email)) {
        // validationFileds.push({field: constants.PROFILE_FIELDS.email, msg: errorMessages.wrongEmail});
        return resolve({ message: errorMessages.wrongEmail, msgType: constants.PROFILE_FIELDS.email });
      }
    }

    if (birthday) {
      // console.log(Date.parse(birthday), Date.parse(constants.LIMIT_DATE_RANGE.minDate), Date.parse(constants.LIMIT_DATE_RANGE.maxDate));
      if (Date.parse(birthday) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(birthday) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
        // validationFileds.push({field: constants.PROFILE_FIELDS.birthday, msg: errorMessages.wrongDate});
        return resolve({ message: errorMessages.wrongDate, msgType: constants.PROFILE_FIELDS.birthday });
      }
    }

    if (changePassword) {
      if (!curPassword) {
        // validationFileds.push({field: constants.PROFILE_FIELDS.password, msg: errorMessages.missingPassword});
        return resolve({ message: errorMessages.missingPassword, msgType: constants.PROFILE_FIELDS.password });
      }
      if (!password) {
        // validationFileds.push({field: constants.PROFILE_FIELDS.password2, msg: errorMessages.missingPassword});
        return resolve({ message: errorMessages.missingPassword, msgType: constants.PROFILE_FIELDS.password2 });
      }
      if (!password2) {
        // validationFileds.push({field: constants.PROFILE_FIELDS.password3, msg: errorMessages.missingPassword});
        return resolve({ message: errorMessages.missingPassword, msgType: constants.PROFILE_FIELDS.password3 });
      }
      if (password !== password2) return resolve({ message: errorMessages.passwordsDontMatch, msgType: constants.PROFILE_FIELDS.password3 });
      if (password.length < 6) return resolve({ message: errorMessages.wrongPassword, msgType: constants.PROFILE_FIELDS.password2 });
    }
    if (validationFileds.length > 0) {
      return resolve({ message: constants.SERVER_STATUS_CODE.error, msgType: constants.SERVER_STATUS_CODE.error, msgTypes: validationFileds })
    }

    const dateBirth = birthday ? Date.parse(birthday) : null;

    const updateProfileFunction = Firebase.app().functions().httpsCallable('updateProfile');

    let emailChanged = false;
    if (changeEmail) {
      const curEmail = Firebase.auth().currentUser.email;
      if (!addEmailFlag) {
        if (curEmail !== email.toLowerCase()) {
          /*    console.log('change email', email);
              await Firebase.auth()
                .currentUser.verifyBeforeUpdateEmail(email)
                .then(() => {
                  emailChanged = true;
  
                //  Firebase.auth()
                //    .currentUser.sendEmailVerification()
                //    .catch((err) => resolve({message: err.code}));
                })
                .catch((err) => {
                  emailChanged = false;
                  resolve({ message: err.code });
                });  */
          console.log('emailChanged true', curEmail, email);

          emailChanged = true;
        }
      }
    }
    if (changePassword) {
      await Firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), curPassword).then(async (res) => {
        if (!res || !res.user || !res.user.uid) {
          return resolve({ message: errorMessages.passwordsDontMatch, msgType: constants.PROFILE_FIELDS.password });
        } else {
          await Firebase.auth().currentUser.updatePassword(password).catch(err => resolve({ message: err.code, msgType: constants.PROFILE_FIELDS.password }));
        }
      }).catch(err => resolve({ message: err.code, msgType: constants.PROFILE_FIELDS.password }));
    }

    return updateProfileFunction({
      firstName,
      lastName,
      photo,
      contactEmail: email.toLowerCase(),
      birthday: dateBirth,
    }).then((res) => {
      getUserData(dispatch);

      console.log('updateProfileFunction res', res);

      if (res.data && res.data.customToken) {
        console.log('updateProfileFunction res customToken', res.data.customToken);
      }

      if (res.data.status === constants.SERVER_STATUS_CODE.error) {
        if (res.data.message === errorMessages.maxNameChangeLimit) {
          return resolve({ status: constants.SERVER_STATUS_CODE.error, emailChanged, message: errorMessages.maxNameChangeLimit });
        }
        console.log("changeEmail===", res);
        if (emailChanged) {
          return resolve({ status: constants.SERVER_STATUS_CODE.error, emailChanged, message: res.data.message });
        }
        return resolve({ status: constants.SERVER_STATUS_CODE.error, emailChanged, message: errorMessages.actionFailed });
      }
      if (res.data && res.data.customToken) {
        return resolve({ status: constants.SERVER_STATUS_CODE.success, emailChanged, customToken: res.data.customToken });
      }

      return resolve({ status: constants.SERVER_STATUS_CODE.success, emailChanged });
    }).catch(() => resolve({ message: errorMessages.errorUpdating }));
  }).catch((err) => {
    console.log('err', err);
    // throw err;
  });
}

export function updateProfileOld(formData, addEmailFlag) {
  const {
    curPassword,
    password,
    password2,
    firstName,
    lastName,
    changePassword,
    email,
    changeEmail,
    photo,
    showCrop,
    // contactEmail,
    // verifiedEmail,
    // addEmail,
    birthday,
  } = formData;

  return dispatch => new Promise(async (resolve) => {
    const UID = Firebase.auth().currentUser.uid;

    if (!UID) return resolve({ message: errorMessages.missingData });

    const validationFileds = [];
    // Validation rules
    if (!firstName || (firstName.trim()).length === 0) {
      return resolve({ message: errorMessages.missingFirstName, msgType: constants.PROFILE_FIELDS.surname });
    }
    if (!lastName || (lastName.trim()).length === 0) {
      return resolve({ message: errorMessages.missingLastName, msgType: constants.PROFILE_FIELDS.name });
    }

    if (showCrop) return resolve({ message: errorMessages.changePhoto });
    if (changeEmail) {
      if (!email) {
        return resolve({ message: errorMessages.missingEmail, msgType: constants.PROFILE_FIELDS.email });
      }
      if (!EmailValidator.validate(email)) {
        return resolve({ message: errorMessages.wrongEmail, msgType: constants.PROFILE_FIELDS.email });
      }
    }

    if (birthday) {
      if (Date.parse(birthday) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(birthday) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
        return resolve({ message: errorMessages.wrongDate, msgType: constants.PROFILE_FIELDS.birthday });
      }
    }

    if (changePassword) {
      if (!curPassword) {
        return resolve({ message: errorMessages.missingPassword, msgType: constants.PROFILE_FIELDS.password });
      }
      if (!password) {
        return resolve({ message: errorMessages.missingPassword, msgType: constants.PROFILE_FIELDS.password2 });
      }
      if (!password2) {
        return resolve({ message: errorMessages.missingPassword, msgType: constants.PROFILE_FIELDS.password3 });
      }
      if (password !== password2) return resolve({ message: errorMessages.passwordsDontMatch, msgType: constants.PROFILE_FIELDS.password3 });
      if (password.length < 6) return resolve({ message: errorMessages.wrongPassword, msgType: constants.PROFILE_FIELDS.password2 });
    }
    if (validationFileds.length > 0) {
      return resolve({ message: constants.SERVER_STATUS_CODE.error, msgType: constants.SERVER_STATUS_CODE.error, msgTypes: validationFileds })
    }

    const dateBirth = birthday ? Date.parse(birthday) : null;

    const updateProfileFunction = Firebase.app().functions().httpsCallable('updateProfile');

    let emailChanged = false;
    if (changeEmail) {
      const curEmail = Firebase.auth().currentUser.email;
      if (!addEmailFlag) {
        if (curEmail !== email.toLowerCase()) {
          console.log('emailChanged true', curEmail, email);

          emailChanged = true;
        }
      }
    }
    if (changePassword) {
      await Firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), curPassword).then(async (res) => {
        if (!res || !res.user || !res.user.uid) {
          return resolve({ message: errorMessages.passwordsDontMatch, msgType: constants.PROFILE_FIELDS.password });
        }

        await Firebase.auth().currentUser.updatePassword(password).catch(err => resolve({ message: err.code, msgType: constants.PROFILE_FIELDS.password }));
      }).catch(err => resolve({ message: err.code, msgType: constants.PROFILE_FIELDS.password }));
    }

    return updateProfileFunction({
      firstName,
      lastName,
      photo,
      contactEmail: email.toLowerCase(),
      birthday: dateBirth,
    }).then((res) => {
      getUserData(dispatch);

      console.log('updateProfileFunction res', res);

      if (res.data && res.data.customToken) {
        console.log('updateProfileFunction res customToken', res.data.customToken);
      }

      if (res.data.status === constants.SERVER_STATUS_CODE.error) {
        if (res.data.message === errorMessages.maxNameChangeLimit) {
          return resolve({ status: constants.SERVER_STATUS_CODE.error, emailChanged, message: errorMessages.maxNameChangeLimit });
        }
        console.log('changeEmail===', res);
        if (emailChanged) {
          return resolve({ status: constants.SERVER_STATUS_CODE.error, emailChanged, message: res.data.message });
        }
        return resolve({ status: constants.SERVER_STATUS_CODE.error, emailChanged, message: errorMessages.actionFailed });
      }
      if (res.data && res.data.customToken) {
        return resolve({ status: constants.SERVER_STATUS_CODE.success, emailChanged, customToken: res.data.customToken });
      }

      return resolve({ status: constants.SERVER_STATUS_CODE.success, emailChanged });
    }).catch(() => resolve({ message: errorMessages.errorUpdating }));
  }).catch((err) => {
    console.log('err', err);
    // throw err;
  });
}

export function updateProfilePhoto(formData) {
  const {
    photo,
    showCrop,
  } = formData;

  return dispatch => new Promise(async (resolve, reject) => {
    // Are they a user?
    const UID = Firebase.auth().currentUser.uid;

    if (!UID) return resolve({ message: errorMessages.missingData });

    const validationFileds = [];

    const updateProfileFunction = Firebase.app().functions().httpsCallable('updateProfile');

    /*
    Firebase.database().ref(`users/${UID}`).update({
      photo,
    })
      .then((res) => {
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
      .catch(() => resolve({ message: errorMessages.errorUpdating })); */

    return updateProfileFunction({
      photo,
      updateOnlyPhoto: true,
    }).then((res) => {
      getUserData(dispatch);

      console.log('updateProfileFunction res', res);

      if (res.data && res.data.customToken) {
        console.log('updateProfileFunction res customToken', res.data.customToken);
      }

      if (res.data.status === constants.SERVER_STATUS_CODE.error) {
        if (res.data.message === errorMessages.maxNameChangeLimit) {
          return resolve({ status: constants.SERVER_STATUS_CODE.error });
        }
        console.log("changeEmail===", res);

        return resolve({ status: constants.SERVER_STATUS_CODE.error });
      }
      if (res.data && res.data.customToken) {
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      }

      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => resolve({ message: errorMessages.errorUpdating }));
  }).catch((err) => {
    console.log('err', err);
  });
}


export function logout() {
  console.log('logout action');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  //  console.log('UID', { UID });

  if (!UID) return () => new Promise(resolve => resolve());

  StatusRef.child(`status/${UID}`).remove();
  FirebaseRef.child(`supportChat/activeChats/${UID}`).off();
  FirebaseRef.child(`supportChat/messages/${UID}`).off();
  FirebaseRef.child(`supportChat/messagesCount/${UID}`).off();

  return dispatch => new Promise((resolve, reject) => Firebase.auth()
    .signOut()
    .then(() => {
      //  console.log('USER_LOGOUT');
      try {
        localStorage.setItem('latestUid', null); //x
      } catch (err) {
        console.log(err);
      }
      resolve(
        dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }),
      );
    })
    .catch(reject)).catch((err) => {
      console.log('err', err);
      // throw err.message;
    });
}


function isUserEqual(facebookAuthResponse, firebaseUser) {
  if (firebaseUser) {
    const { providerData } = firebaseUser;

    for (let i = 0; i < providerData.length; i += 1) {
      if (
        providerData[i].providerId
        === Firebase.auth.FacebookAuthProvider.PROVIDER_ID
        && providerData[i].uid === facebookAuthResponse.userID
      ) {
        // We don't need to re-auth the Firebase connection.
        return true;
      }
    }
  }
  return false;
}

export function updateUserLastAction() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    StatusRef.child(`status/${UID}`).update({
      lastAction: Firebase.database.ServerValue.TIMESTAMP,
    });

    return resolve();
  });
}

export function setUserOnlineStatus(updateDesign, newDesign, language, fontMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('setUserOnlineStatus newDesign', updateDesign, newDesign, language, fontMode);

  return () => new Promise((resolve) => {
    StatusRef.child(`isOnline/${UID}`).onDisconnect().remove().catch(() => { });
    StatusRef.child(`isOnline/${UID}`).set(Firebase.database.ServerValue.TIMESTAMP).catch(() => { });

    return resolve();
  });
}

export function setUserLoginStatistics(updateDesign, newDesign, language, fontMode, screenMode, changedFullscreen) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  // osName, osVersion, browserName, browserVersion, isMobile,
  // window.innerWidth and window.innerHeight

  const osNameVal = osName;
  const osVersionVal = osVersion;
  const browserNameVal = browserName;
  const browserVersionVal = browserVersion;
  const isMobileVal = isMobile;
  let innerWidthVal = 0;
  let innerHeightVal = 0;

  if (window) {
    innerWidthVal = window.innerWidth || 0;
    innerHeightVal = window.innerHeight || 0;
  }

  console.log('setUserLoginStatistics osNameVal', osNameVal, osVersionVal, browserNameVal, browserVersionVal, isMobileVal, innerWidthVal, innerHeightVal);

  const isInAppFrame = config.isInAppFrame();

  console.log('setUserLoginStatistics isInAppFrame', isInAppFrame);

  let isFullscreen = false;

  if (changedFullscreen || changedFullscreen === false) {
    isFullscreen = changedFullscreen;
  } else if (document && ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen))) {
    isFullscreen = false;
  } else {
    isFullscreen = true;
  }

  console.log('setUserLoginStatistics isFullscreen', isFullscreen, changedFullscreen);

  console.log('setUserLoginStatistics newDesign', updateDesign, newDesign, language, fontMode, screenMode, devMode);

  return (dispatch, getState) => new Promise((resolve) => {
    const { userSettings } = getState();
    const soundOn = (userSettings && userSettings[UID]) ? userSettings[UID]?.soundOn : true;

    console.log('setUserLoginStatistics soundOn', soundOn);

    const setUserLoginStatisticsFunction = Firebase.app().functions().httpsCallable('setUserLoginStatistics');

    setUserLoginStatisticsFunction({
      updateDesign,
      newDesign,
      language,
      fontMode,
      screenMode,
      soundOn,
      devMode,
      isInAppFrame,
      isFullscreen,
      osName: osNameVal,
      osVersion: osVersionVal,
      browserName: browserNameVal,
      browserVersion: browserVersionVal,
      isMobile: isMobileVal,
      innerWidth: innerWidthVal,
      innerHeight: innerHeightVal,
    }).then(() => resolve());

    return resolve();
  });
}

export function setUserScreenView(screenId) {
  console.log('setUserScreenView screenId', screenId);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  let categoryId;

  const isInAppFrame = config.isInAppFrame();

  let newDesign = false;

  return (dispatch, getState) => new Promise((resolve) => {

    const { member } = getState();

    if (member && member.newDesign) {
      newDesign = true;
    }
    /* try {
      const newDesignVar = localStorage.getItem('newDesign'); //x

      if (newDesignVar) {
        newDesign = newDesignVar;
      }
    } catch (err) {
    } */

    if (isInAppFrame) {
      categoryId = 'iFrame';
    } else if (newDesign && window && window.innerWidth <= 1280) {
      categoryId = 'mWeb';
    } else {
      categoryId = 'dWeb';
    }

    console.log('setUserScreenView categoryId', isInAppFrame, categoryId, newDesign);

    const setUserScreenViewFunction = Firebase.app().functions().httpsCallable('setUserScreenView');

    setUserScreenViewFunction({
      categoryId,
      screenId,
    }).then(() => resolve());

    return resolve();
  });
}


export function setUserScreenViewDetailed(sectionId, screenId) {
  console.log('setUserScreenViewDetailed screenId', sectionId, screenId);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  let categoryId;

  const isInAppFrame = config.isInAppFrame();

  let newDesign = false;

  return (dispatch, getState) => new Promise((resolve) => {
    const { member } = getState();

    if (member && member.newDesign) {
      newDesign = true;
    }

    if (isInAppFrame) {
      categoryId = 'iFrame';
    } else if (newDesign && window && window.innerWidth <= 1280) {
      categoryId = 'mWeb';
    } else {
      categoryId = 'dWeb';
    }

    console.log('setUserScreenViewDetailed categoryId', isInAppFrame, categoryId, newDesign);
  
    const setUserScreenViewFunction = Firebase.app().functions().httpsCallable('setUserScreenView');

    setUserScreenViewFunction({
      detailedStatistics: true,
      categoryId,
      sectionId,
      screenId,
    }).then(() => resolve());

    return resolve();
  });
}


export function removeUserStatus() {
  console.log('removeUserStatus');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    StatusRef.child(`status/${UID}`).remove();

    return resolve();
  });
}

export function removeUserOnDisconnect() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    StatusRef.child(`status/${UID}`)
      .onDisconnect()
      .remove(() => { });

    StatusRef.child(`status/${UID}`).update({
      status: true,
      lastAction: Firebase.database.ServerValue.TIMESTAMP,
    });

    return resolve();
  });
}

export function fbLoginPopup(event, language) {
  console.log('facebooklogin fbLoginPopup', event);
  return (dispatch, getState) => new Promise((resolve, reject) => {
    if (event.authResponse) {
      const unsubscribe = Firebase.auth().onAuthStateChanged(
        (firebaseUser) => {
          unsubscribe();
          // Check if we are already signed-in Firebase with the correct user.

          if (!isUserEqual(event.authResponse, firebaseUser)) {
            const provider = new Firebase.auth.FacebookAuthProvider();
            provider.addScope('email');

            const promise5 = Firebase.auth().signInWithPopup(provider);

            Promise.all([promise5])
              .then(async (promisesRes) => {
                const res = promisesRes[0];

                const fbAccessToken = res.credential.accessToken;

                let additionalUserInfo = null;

                const response = await axios({
                  method: 'get',
                  url: `https://graph.facebook.com/v19.0/me?  access_token=${fbAccessToken}&fields=id,name,first_name,last_name,email`,
                  headers: {
                    'Content-Type': 'text/plain',
                    Accept: 'application/json',
                  },
                  timeout: 3000,
                });

                console.log('facebooklogin fbLoginPopup response', response);

                if (response && response.data) {
                  additionalUserInfo = response.data;
                }

                const { refferal } = getState();
                const { refferalToken } = refferal;

                console.log('refferalToken', refferalToken);

                const afterFBAuthFunction = Firebase.app()
                  .functions('europe-west1')
                  .httpsCallable('afterFBAuth');

                afterFBAuthFunction({
                  uid: res.user.uid,
                  providerData: res.user.providerData,
                  additionalUserInfo,
                  language,
                  refferalToken,
                })
                  .then((result) => {
                    console.log('afterFBAuth result', result);

                    if (result.data && result.data.uid) {
                      /* if (LogRocket && result.data.enableLogRocket) {
                        LogRocket.init('zole/zole');

                        LogRocket.identify(result.data.uid, {
                          name: result.data.name,
                          email: result.data.email,
                        });
                      } */

                      const { uid } = result.data;

                      const { member } = getState();
                      const { fontMode } = member;
                      console.log('fontMode', fontMode);

                      if (uid) {
                      //  Firebase.database().ref(`users/${uid}`).update({
                        //  lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                      //    fontMode: fontMode || constants.FONT_MODE.normal,
                      //  });

                        StatusRef.child(`status/${uid}`).update({
                          status: true,
                          lastAction: Firebase.database.ServerValue.TIMESTAMP,
                        });
                      }
                    }

                    console.log('call USER_DATA', result.data.verifiedEmail);
                    resolve({ data: result.data });
                    //  dispatch({
                    //   type: 'USER_DATA',
                    //    data: result.data,
                    //  }),
                  //  );
                  })
                  .catch((err) => {
                    console.log('FB Auth problem', { err });
                    reject({ code: err.code });
                  });
              })
              .catch((err) => {
                console.log('FB signIn problem', { err });
                reject({ code: err.code });
              });
          } else {
            FirebaseRef.child(`users/${firebaseUser.uid}`).once(
              'value',
              (playerSnapshot) => {
                const playerData = playerSnapshot.val() || {};

                /* if (LogRocket && playerData.enableLogRocket) {
                  LogRocket.init('zole/zole');

                  LogRocket.identify(playerData.uid, {
                    name: playerData.name,
                    email: playerData.email,
                  });
                } */

                if (Firebase.auth().currentUser) {
                  const { uid } = Firebase.auth().currentUser;

                  const { member } = getState();
                  const { fontMode } = member;
                  console.log('fontMode', fontMode);

                 // Firebase.database().ref(`users/${uid}`).update({
                   // lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                //    fontMode: fontMode || constants.FONT_MODE.normal,
                //  });
                  StatusRef.child(`status/${uid}`)
                    .update({
                      status: true,
                      lastAction: Firebase.database.ServerValue.TIMESTAMP,
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }

                if (firebaseUser && (!playerData || !playerData.uid)) {
                  const { refferal } = getState();
                  const { refferalToken } = refferal;

                  console.log('refferalToken', refferalToken);

                  const afterFBAuthFunction = Firebase.app()
                    .functions('europe-west1')
                    .httpsCallable('afterFBAuth');

                  afterFBAuthFunction({
                    uid: firebaseUser.uid,
                    providerData: firebaseUser.providerData,
                    language,
                    refferalToken,
                  })
                    .then((result) => {
                      console.log('call USER_DATA', result.data.verifiedEmail);
                      resolve({ data: result.data });
                      //  dispatch({
                      //    type: 'USER_DATA',
                      //    data: result.data,
                      //  }),
                    //  );
                    })
                    .catch((err) => {
                      console.log('FB Auth problem', { err });
                      reject({ code: err.code });
                    });
                } else {
                  console.log('call USER_DATA', playerData.verifiedEmail);

                  resolve({
                    data: {
                      uid: playerData.uid,
                      name: playerData.name,
                      email: playerData.email,
                      photo: playerData.photo,
                      level: playerData.lvl,
                      balance: playerData.bal,
                      levelupGameLimit: playerData.lvlupLimit,
                      lvlUpNotification: playerData.lvlUpNotification,
                      gamesPlayed: playerData.gPlayed,
                      gamesWon: playerData.gWon,
                      totalPoints: playerData.totalPnts,
                      nextBonusSpin: playerData.nxtSpin,
                      lastBonusSpin: playerData.lastSpin,
                      status: 'logged In',
                      role: playerData.role,
                      lastLogin: playerData.lastLogin,
                      socProvider: playerData.socProvider,
                      tutorialShown: playerData.tutorialShown,
                      firstTimeModal: playerData.firstTimeModal,
                      blocked: playerData.blocked,
                      banEndDate: playerData.banEndDate,
                      banReason: playerData.banReason,
                      joinedRooms: playerData.joinedRooms,
                      verifiedEmail: playerData.verifiedEmail,
                    }
                  });
                   /* dispatch({
                      type: 'USER_DATA',
                      data: {
                        uid: playerData.uid,
                        name: playerData.name,
                        email: playerData.email,
                        photo: playerData.photo,
                        level: playerData.lvl,
                        balance: playerData.bal,
                        //  position: playerData.pos,
                        levelupGameLimit: playerData.lvlupLimit,
                        lvlUpNotification: playerData.lvlUpNotification,
                        gamesPlayed: playerData.gPlayed,
                        gamesWon: playerData.gWon,
                        totalPoints: playerData.totalPnts,
                        nextBonusSpin: playerData.nxtSpin,
                        lastBonusSpin: playerData.lastSpin,
                        status: 'logged In',
                        role: playerData.role,
                        lastLogin: playerData.lastLogin,
                        socProvider: playerData.socProvider,
                        tutorialShown: playerData.tutorialShown,
                        firstTimeModal: playerData.firstTimeModal,
                        blocked: playerData.blocked,
                        banEndDate: playerData.banEndDate,
                        banReason: playerData.banReason,
                        joinedRooms: playerData.joinedRooms,
                      },
                    }), */
                //  );
                }

                //  resolve(dispatch({ type: 'USER_DATA', data: firebaseUser }));
              },
            );
          }
        },
      );
    } else {
      // User is signed-out of Facebook.
      Firebase.auth().signOut();
      resolve(
        dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }),
      );
    }
    //  });
  });
}

export function checkLoginState(event, email, language, history) {
  console.log('facebooklogin checkLoginState', { event, email, language });
  return (dispatch, getState) => new Promise((resolve) => {
    //  Firebase.auth().signOut().then(() => {

    if (event.authResponse) {
      const unsubscribe = Firebase.auth().onAuthStateChanged(
        (firebaseUser) => {
          unsubscribe();
          // Check if we are already signed-in Firebase with the correct user.

          if (!isUserEqual(event.authResponse, firebaseUser)) {
            // Build Firebase credential with the Facebook auth token.
            const credential = Firebase.auth.FacebookAuthProvider.credential(
              event.authResponse.accessToken,
            );
            // Sign in with the credential from the Facebook user.

            const promise5 = Firebase.auth().signInWithCredential(credential);

            Promise.all([promise5])
              .then(async (promisesRes) => {
                const res = promisesRes[0];

                const fbAccessToken = res.credential.accessToken;

                let additionalUserInfo = null;

                const response = await axios({
                  method: 'get',
                  url: `https://graph.facebook.com/v19.0/me?  access_token=${fbAccessToken}&fields=id,name,first_name,last_name,email`,
                  headers: {
                    'Content-Type': 'text/plain',
                    Accept: 'application/json',
                  },
                  timeout: 3000,
                });

                console.log('facebooklogin checkLoginState response', response);

                if (response && response.data) {
                  additionalUserInfo = response.data;
                }

                const { refferal } = getState();
                const { refferalToken } = refferal;

                console.log('refferalToken', refferalToken);

                //  Firebase.auth().signInAndRetrieveDataWithCredential(credential).then((res) => {
                const afterFBAuthFunction = Firebase.app()
                  .functions('europe-west1')
                  .httpsCallable('afterFBAuth');

                afterFBAuthFunction({
                  uid: res.user.uid,
                  providerData: res.user.providerData,
                  additionalUserInfo,
                  language,
                  refferalToken,
                })
                  .then((result) => {
                    if (result.data && result.data.uid) {
                      /* if (LogRocket && result.data.enableLogRocket) {
                        LogRocket.init('zole/zole');

                        LogRocket.identify(result.data.uid, {
                          name: result.data.name,
                          email: result.data.email,
                        });
                      } */

                      const { uid } = result.data;

                      if (uid) {
                      //  Firebase.database().ref(`users/${uid}`).update({
                      //    lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                      //  });

                        StatusRef.child(`status/${uid}`).update({
                          status: true,
                          lastAction: Firebase.database.ServerValue.TIMESTAMP,
                        });
                      }
                    }

                    console.log('call USER_DATA', result.data.verifiedEmail);

                    try {
                      dispatch({
                        type: 'USER_DATA',
                        data: result.data,
                      });

                      return resolve();
                    } catch (err) {
                      console.log('err');
                    }
                    /* resolve(
                      dispatch({
                        type: 'USER_DATA',
                        data: result.data,
                      }),
                    ); */
                  })
                  .catch((err) => {
                    console.log('FB Auth problem', { err });
                  });
              })
              .catch((err) => {
                console.log('FB signIn problem', { err });
              });
          } else {
            FirebaseRef.child(`users/${firebaseUser.uid}`).once(
              'value',
              (playerSnapshot) => {
                const playerData = playerSnapshot.val() || {};

                /* if (LogRocket && playerData.enableLogRocket) {
                  LogRocket.init('zole/zole');

                  LogRocket.identify(playerData.uid, {
                    name: playerData.name,
                    email: playerData.email,
                  });
                } */

                if (Firebase.auth().currentUser) {
                  const { uid } = Firebase.auth().currentUser;

                //  Firebase.database().ref(`users/${uid}`).update({
                //    lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                //  });

                  StatusRef.child(`status/${uid}`)
                    .update({
                      status: true,
                      lastAction: Firebase.database.ServerValue.TIMESTAMP,
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }

                if (firebaseUser && (!playerData || !playerData.uid)) {
                  const { refferal } = getState();
                  const { refferalToken } = refferal;

                  console.log('refferalToken', refferalToken);

                  const afterFBAuthFunction = Firebase.app()
                    .functions('europe-west1')
                    .httpsCallable('afterFBAuth');

                  afterFBAuthFunction({
                    uid: firebaseUser.uid,
                    providerData: firebaseUser.providerData,
                    language,
                    refferalToken,
                  })
                    .then((result) => {
                      console.log('call USER_DATA', result.data.verifiedEmail);

                      resolve(
                        dispatch({
                          type: 'USER_DATA',
                          data: result.data,
                        }),
                      );
                    })
                    .catch((err) => {
                      console.log('FB Auth problem', { err });
                    });
                } else {
                  console.log('call USER_DATA', playerData.verifiedEmail);

                  resolve(
                    dispatch({
                      type: 'USER_DATA',
                      data: {
                        uid: playerData.uid,
                        name: playerData.name,
                        email: playerData.email,
                        photo: playerData.photo,
                        level: playerData.lvl,
                        balance: playerData.bal,
                        levelupGameLimit: playerData.lvlupLimit,
                        lvlUpNotification: playerData.lvlUpNotification,
                        gamesPlayed: playerData.gPlayed,
                        gamesWon: playerData.gWon,
                        totalPoints: playerData.totalPnts,
                        nextBonusSpin: playerData.nxtSpin,
                        lastBonusSpin: playerData.lastSpin,
                        status: 'logged In',
                        role: playerData.role,
                        lastLogin: playerData.lastLogin,
                        socProvider: playerData.socProvider,
                        tutorialShown: playerData.tutorialShown,
                        firstTimeModal: playerData.firstTimeModal,
                        blocked: playerData.blocked,
                        banEndDate: playerData.banEndDate,
                        banReason: playerData.banReason,
                        joinedRooms: playerData.joinedRooms,
                      },
                    }),
                  );
                }
              },
            );
          }
        },
      );
    } else {
      // User is signed-out of Facebook.
      console.log('User is signed-out of Facebook.');

      Firebase.auth().signOut();

      if (history) {
        history.push('/login');
      }
      resolve(
        dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }),
      );
    }
    //  });
  });
}

export function spinBonusWheel() {
  //  console.log('spinBonusWheel');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  //  console.log('spinBonusWheel uid', { UID });

  if (!UID) {
    return () => new Promise(resolve => resolve());
  }

  return () => new Promise((resolve) => {
    const spinWheelFunction = Firebase.app()
      .functions()
      .httpsCallable('spinBonusWheelNew');

    spinWheelFunction().then((result) => {
      //  console.log('spinBonusWheel res', { result });
      resolve(result);
    });
  });
}

export function claimSpinResults() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const claimSpinResultsFunction = Firebase.app().functions().httpsCallable('claimSpinResults');

    claimSpinResultsFunction().then((result) => {
      resolve(result);
    });
  });
}


export function sendMoney(friendUid, amount) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    let d = new Date(Date.now());

    d.setHours(24, 0, 0, 0);
    d = Date.parse(d);

    const startAt = d - (1000 * 60 * 60 * 24);
    const endAt = d;

    UserStatsRef().child(`userBalHistory/${UID}`)
      .orderByChild('time')
      .startAt(startAt)
      .endAt(endAt)
      // .limitToLast(125)
      .once('value', async (snapshot) => {
        const history = snapshot.val() || {};
        let sentMoney = 0;
        await map(history, async (item) => {
          if (item.type === 'friendSent' && item?.friendUid === friendUid) {
            sentMoney = sentMoney + Math.abs(item.change);
          }
        });
        console.log(sentMoney, amount, "checkMMMMM")

        if (sentMoney + parseInt(amount, 10) <= 100) {
          const sendMoneyFunction = await Firebase.app().functions().httpsCallable('sendMoney');

          await sendMoneyFunction({ friendUid, amount })
            .then(res => {
              if (res.data.status === constants.SERVER_STATUS_CODE.success) {
                return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success })
              }
              return resolve({ status: constants.SERVER_STATUS_CODE.error, message: res.data?.message || constants.SERVER_STATUS_CODE.error })
            }).catch((err) => {
              console.log(err);
            });
        }

        if (sentMoney < 100 && sentMoney + parseInt(amount, 10) > 100) {
          return resolve({ status: constants.SERVER_STATUS_CODE.error, message: errorMessages.sentPerDayMoneyLimit, restMoney: 100 - parseInt(sentMoney, 10) })
        }
        return resolve({ status: constants.SERVER_STATUS_CODE.error, message: errorMessages.sentPerDayMoney })
      });
  });
}

export function setBonusModal(type) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('setBonusModal', type);

  const UID = (FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve, reject) => {
    if (type === 'chest') {
      return FirebaseRef.child('settings/treasureChestEnabled').once('value', (settingsSnapshot) => {
        const enabled = !!settingsSnapshot.val();

        if (enabled) {
          return FirebaseRef.child(`treasureChestShown/${UID}`).once('value', (snapshot) => {
            const chestShown = !!snapshot.val();

            if (!chestShown) {
              return resolve(dispatch({ type: 'SET_BONUS_MODAL', data: true, modalType: type }));
            }
            return resolve();
          }, (err) => {
            console.log(err);
            return reject(err);
          });
        }
        return reject('not available');
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    } if (type === 'advent') {
      return FirebaseRef.child('settings/adventEnabled').once('value', (settingsSnapshot) => {
        const enabled = !!settingsSnapshot.val();

        console.log('adventEnabled enabled', enabled);

        if (enabled) {
          return FirebaseRef.child(`adventeBonusShown/${UID}`).once('value', (snapshot) => {
            const adventShown = !!snapshot.val();

            console.log('adventShown enabled', adventShown);

            if (!adventShown) {
              return resolve(dispatch({ type: 'SET_BONUS_MODAL', data: true, modalType: type }));
            }
            return resolve();
          }, (err) => {
            console.log(err);
            return reject(err);
          });
        }
        return reject('not available');
      }, (err) => {
        console.log(err);
        return reject(err);
      });
    }
    return reject('wrong type');
  });
}

export function verifyGiftCode(code) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve({ status: constants.SERVER_STATUS_CODE.error, error: constants.SERVER_STATUS_CODE.error }));
  if (!code || code.length === 0) return () => new Promise(resolve => resolve({ status: constants.SERVER_STATUS_CODE.error, error: errorMessages.missingGiftCode }));

  return () => new Promise(resolve => FirebaseRef.child(`giftCodes/${code.toLowerCase()}`)
    .once('value', (giftCodeSnapshot) => {
      const giftCode = giftCodeSnapshot.val() || {};

      if (giftCode.code) {
        if (giftCode.expires < Date.now()) {
          resolve({ status: constants.SERVER_STATUS_CODE.error, error: 'Code expired' });
        }

        if (giftCode.claimedCount >= giftCode.playerLimit) {
          resolve({ status: constants.SERVER_STATUS_CODE.error, error: 'Claim limit reached' });
        }

        resolve({ status: constants.SERVER_STATUS_CODE.success, selCode: giftCode.code, selVal: giftCode.value });
      } else {
        resolve({ status: constants.SERVER_STATUS_CODE.error, error: 'wrong code' });
      }
    }).catch((err) => {
      resolve({ status: constants.SERVER_STATUS_CODE.error, error: err });
    }));
}

export function claimGiftCode(code) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve({ status: constants.SERVER_STATUS_CODE.error, error: constants.SERVER_STATUS_CODE.error }));
  if (!code || code.length === 0) return () => new Promise(resolve => resolve({ status: constants.SERVER_STATUS_CODE.error, error: errorMessages.missingGiftCode }));

  return () => new Promise((resolve) => {
    const claimGiftCodeFunction = Firebase.app().functions().httpsCallable('claimGiftCode');

    claimGiftCodeFunction({
      code: code.toLowerCase(),
    }).then((result) => {
      if (result.data.status === constants.SERVER_STATUS_CODE.success) {
        resolve({ status: constants.SERVER_STATUS_CODE.success });
      }
      if (result.data.status === constants.SERVER_STATUS_CODE.error) {
        resolve({ status: constants.SERVER_STATUS_CODE.error, error: result.data.error });
      }
    }).catch((err) => {
      resolve({ status: constants.SERVER_STATUS_CODE.error, error: err.message });
    });
  });
}

export function claimChestBonus() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const claimChestBonusFunction = Firebase.app().functions().httpsCallable('claimChestBonus');

    if (claimChestBonusFunction) {
      claimChestBonusFunction().then(() => resolve(dispatch({ type: 'SET_BONUS_MODAL', data: false, modalType: 'chest' }))).catch(() => resolve());
    }
  });
}


export function claimAdventBonus() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const claimAdventBonusFunction = Firebase.app().functions().httpsCallable('claimAdventBonus');

    if (claimAdventBonusFunction) {
      claimAdventBonusFunction().then(() => resolve(dispatch({ type: 'SET_BONUS_MODAL', data: false, modalType: 'advent' }))).catch(() => resolve());
    }
  });
}

export function draugiemAuthMobile(authCode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const draugiemAuthFunction = Firebase.app()
      .functions('europe-west1')
      .httpsCallable('draugiemAuth');
    draugiemAuthFunction({ authCode }).then((result) => {
      resolve(result);
    });
  });
}

export function draugiemAuth(authCode, devMode, language) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('draugiemAuth 1', authCode, devMode, language);

  return (dispatch, getState) => new Promise((resolve2) => {
    console.log('draugiemAuth 1.5');

    return new Promise(resolve => Firebase.auth().signOut()
      .then(() => {
        const { refferal } = getState();
        const { refferalToken } = refferal;

        console.log('refferalToken', refferalToken);

        const draugiemAuthFunction = Firebase.app().functions('europe-west1').httpsCallable('draugiemAuth');

        //  return new Promise((resolve, _reject) =>
        draugiemAuthFunction({
          authCode, devMode, language, refferalToken,
        }).then((result) => {
          const { data } = result;
          if (data.status === constants.SERVER_STATUS_CODE.success) {
            console.log('draugiemAuth 4', data.token);
            if (data.token) {
              console.log('draugiemAuth 5');

              const promise5 = Firebase.auth().signInWithCustomToken(data.token);

              Promise.all([promise5]).then(async (promisesRes) => {
                const res = promisesRes[0];

                console.log('draugiemAuth 6', res.data, res.operationType);

                if (res.data && res.data.uid) {
                  console.log('draugiemAuth 7');
                  return FirebaseRef.child(`users/${res.data.uid.toString()}`).once('value', (playerSnapshot) => {
                      const playerData = playerSnapshot.val() || {};

                      console.log('draugiemAuth 8');

                      /* if (LogRocket && playerData.enableLogRocket) {
                        LogRocket.init('zole/zole');

                        LogRocket.identify(playerData.uid, {
                          name: playerData.name,
                          email: playerData.email,
                        });
                      } */

                      const { uid } = playerData;

                      const { member } = getState();
                      const { fontMode } = member;
                      console.log('fontMode', fontMode);

                      console.log('draugiemAuth 9', uid);

                      if (uid) {
                      //  Firebase.database().ref(`users/${uid}`).update({
                        //  lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                      //    fontMode: fontMode || constants.FONT_MODE.normal,
                      //  });

                        StatusRef.child(`status/${uid}`).update({
                          status: true,
                          lastAction: Firebase.database.ServerValue.TIMESTAMP,
                        });
                      }

                      //  return resolve(playerData);
                      //  resolve2();
                      return resolve2(dispatch({ type: 'USER_LOGIN2', data: null }));
                  });
                } if (res.operationType === 'signIn' && res.user && res.user.uid) {
                  console.log('draugiemAuth 10', res.user.uid);

                  const UID = FirebaseRef
                    && Firebase
                    && Firebase.auth()
                    && Firebase.auth().currentUser
                    && Firebase.auth().currentUser.uid
                    ? Firebase.auth().currentUser.uid
                    : null;

                  console.log('draugiemAuth UID', UID);

                  const { uid } = res.user;

                //  await FirebaseRef.child(`users/${UID}`).once('value', (playerSnapshot) => {
              //      const playerData = playerSnapshot.val() || {};
               //     console.log('draugiemAuth 11', playerData);


                      /* if (LogRocket && playerData.enableLogRocket) {
                        console.log('draugiemAuth LogRocket.init');
                        LogRocket.init('zole/zole');

                        console.log('draugiemAuth LogRocket.identify');

                        LogRocket.identify(playerData.uid, {
                          name: playerData.name,
                          email: playerData.email,
                        });
                      } */

                    console.log('draugiemAuth 12');

                 //   const { uid } = playerData;

                    console.log('draugiemAuth 13');

                    const { member } = getState();
                    const { fontMode } = member;
                    console.log('fontMode', fontMode);

                    if (uid) {
                    //  await Firebase.database().ref(`users/${uid}`).update({
                      //  lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                    //    fontMode: fontMode || constants.FONT_MODE.normal,
                    //  });

                      await StatusRef.child(`status/${uid}`).update({
                        status: true,
                        lastAction: Firebase.database.ServerValue.TIMESTAMP,
                      });
                    }

                   console.log('draugiemAuth 14');

                    //  return resolve(playerData);
                    //  resolve2();
                    return resolve2(dispatch({ type: 'USER_LOGIN2', data: null }));
               //   }).catch(err => {
               //     console.log('draugiemAuth err');
               //     console.log(err);
                //  });

                  console.log('draugiemAuth 21');
                }
              }).catch((error) => {
                console.log('draugiemAuth :: error :: xx', { error });
              });
              console.log('draugiemAuth 22');
              // }).catch(err => {
              //   console.log('draugiemAuth :: error :: 33', { err });
              // });
            } else {
              // return dispatch => new Promise((resolve, reject) => Firebase.auth().signOut()
              Firebase.auth().signOut()
                .then(() => {
                  resolve2(dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }));
                })
                // .catch(resolve2())
                .catch((err) => {
                  console.log('draugiemAuth :: error :: kk', { err });
                  return resolve2();
                });
            }
            console.log('draugiemAuth 23');
          } else {
            // return dispatch => new Promise((resolve, reject) => Firebase.auth().signOut()
            Firebase.auth().signOut()
              .then(() => {
                resolve2(dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }));
              })
              // .catch(resolve2())
              .catch((err) => {
                console.log('draugiemAuth :: error :: dd', { err });
                return resolve2();
              });

            //  return new Promise((resolve) => { resolve(constants.SERVER_STATUS_CODE.success ); });
          }
          console.log('draugiemAuth 24');
        });
        //  });
      }).catch((err) => {
        console.log('draugiemAuth :: error :: 11', { err });
      }));
  }).catch((err) => {
    console.log('draugiemAuth :: error :: 22', { err });
  });
}

export function inboxAuth(uidText, devMode, language) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('inboxAuth test', uidText, devMode, language);

  return (dispatch, getState) => new Promise(resolve => Firebase.auth().signOut()
    .then(() => {
      const { refferal } = getState();
      const { refferalToken } = refferal;

      console.log('refferalToken', refferalToken);

      const inboxAuthFunction = Firebase.app().functions('europe-west1').httpsCallable('inboxAuth');

      inboxAuthFunction({
        uidText, devMode, language, refferalToken,
      }).then((result) => {
        const { data } = result;

        console.log('inboxAuth result data', data);

        if (data.status === constants.SERVER_STATUS_CODE.success) {
          if (data.token) {
            const promise = Firebase.auth().signInWithCustomToken(data.token);

            Promise.all([promise]).then((promisesRes) => {
              const res = promisesRes[0];

              console.log('inboxAuth signInWithCustomToken res', res);

              if (res.data && res.data.uid) {
                return FirebaseRef.child(`users/${res.data.uid.toString()}`)
                  .once('value', (playerSnapshot) => {
                    const playerData = playerSnapshot.val() || {};

                    const { uid } = playerData;

                    if (uid) {
                      StatusRef.child(`status/${uid}`).update({
                        status: true,
                        lastAction: Firebase.database.ServerValue.TIMESTAMP,
                      });
                    }

                    return resolve(playerData);
                  });
              } if (res.operationType === 'signIn' && res.user && res.user.uid) {
                return FirebaseRef.child(`users/${res.user.uid.toString()}`)
                  .once('value', (playerSnapshot) => {
                    const playerData = playerSnapshot.val() || {};

                    const { uid } = playerData;

                    if (uid) {
                      StatusRef.child(`status/${uid}`).update({
                        status: true,
                        lastAction: Firebase.database.ServerValue.TIMESTAMP,
                      });
                    }

                    return resolve(playerData);
                  });
              }
            }).catch((error) => {
              console.log(error);
            });
          } else {
            console.log('inboxAuth no token, signOut');
            // return dispatch => new Promise((resolve, reject) => Firebase.auth().signOut()
            Firebase.auth().signOut()
              .then(() => {
                resolve(dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }));
              })
              // .catch(reject))
              .catch((err) => {
                console.log('err', err);
                // throw err.message;
              });
          }
        } else {
          // return dispatch => new Promise((resolve, reject) => Firebase.auth().signOut()
          Firebase.auth().signOut()
            .then(() => {
              resolve(dispatch({ type: 'USER_LOGOUT', data: { status: 'signedOut' } }));
            })
            // .catch(reject))
            .catch((err) => {
              console.log('err', err);
              // throw err.message;
            });
        }
      });
    }));
}

export function getTimeOffset() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => Firebase.database().ref('.info/serverTimeOffset')
    .on('value', (snap) => {
      const offset = snap.val();

      resolve(dispatch({ type: 'OFFSET', data: offset }));
    }));
}

export function initFBPayment(product) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const initFBPaymentFunction = Firebase.app().functions('europe-west1').httpsCallable('initFBPayment');

  return dispatch => new Promise(async (resolve) => {
    initFBPaymentFunction({
      product,
    }).then((result) => {
      resolve(dispatch({ type: 'INIT_FB_PAYMENT', data: result }));
      return result;
    });
  });
}

export function initDraugiemPayment(product) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const initDraugiemPaymentFunction = Firebase.app().functions('europe-west1').httpsCallable('initDraugiemPayment');

  return dispatch => new Promise(async (resolve) => {
    initDraugiemPaymentFunction({
      product,
    }).then((result) => {
      resolve(dispatch({ type: 'INIT_DRAUGIEM_PAYMENT', data: result }));
      return result;
    });
  });
}


export function initInboxPayment(product) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const initInboxPaymentFunction = Firebase.app().functions('europe-west1').httpsCallable('initInboxPayment');

  return dispatch => new Promise(async (resolve) => {
    initInboxPaymentFunction({
      product,
    }).then((result) => {
      resolve(dispatch({ type: 'INIT_INBOX_PAYMENT', data: result }));
      return result;
    });
  });
}

export function initStripePayment(product) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const initStripePaymentFunction = Firebase.app().functions('europe-west1').httpsCallable('initStripePayment');

  return dispatch => new Promise(async (resolve) => {
    initStripePaymentFunction({
      product,
    }).then((result) => {
      resolve(dispatch({ type: 'INIT_STRIPE_PAYMENT', data: result }));
      return result;
    });
  });
}

export function initEveryPayPayment(selectedProduct, selectedPaymentOption, selectedProductId, lang) {
  console.log('initEveryPayPayment', selectedProduct, selectedPaymentOption, selectedProductId, lang);

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  console.log('initEveryPayPayment', devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const initEveryPayFunction = Firebase.app().functions('europe-west1').httpsCallable('initEveryPay');

  return dispatch => new Promise(async (resolve) => {
    initEveryPayFunction({
      selectedProduct,
      selectedPaymentOption,
      selectedProductId,
      devMode,
      lang,
    }).then((result) => {
      console.log('result', result);

      return resolve(dispatch({ type: 'INIT_EVERY_PAY_PAYMENT', data: result.data }));

      //  return result.data;
    });
  });
}

export function everyPayCallback(paymentReference) {
  console.log('everyPayCallback', paymentReference);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('everyPayCallback test 1');

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('everyPayCallback test 2', UID);

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  console.log('everyPayCallback', devMode);

  const everyPayCallbackFunction = Firebase.app().functions().httpsCallable('everyPayCallback');

  return dispatch => new Promise(async (resolve) => {
    everyPayCallbackFunction({ paymentReference, devMode }).then((result) => {
      console.log('everyPayCallbackFunction', result);
      resolve(dispatch({ type: 'FB_PAYMENT_CALLBACK', data: result }));
      return result;
    });
  });
}

export function inboxCallback(transaction_uuid, transaction_result,) {
  console.log('inboxCallback', transaction_uuid, transaction_result,);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('inboxCallback test 1');

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('inboxCallback test 2', UID);

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  console.log('inboxCallback', devMode);

  const inboxCallbackFunction = Firebase.app().functions().httpsCallable('inboxPaymentCallback');

  return dispatch => new Promise(async (resolve) => {
    inboxCallbackFunction({ transaction_uuid, transaction_result }).then((result) => {
      console.log('inboxCallbackFunction', result);
      dispatch({ type: 'INBOX_PAYMENT_CALLBACK', data: result });
      return resolve(result);
    });
  });
}

export function fbPaymentCallback(resp) {
  console.log('fbPaymentCallback', resp);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const fbPaymentCallbackFunction = Firebase.app().functions().httpsCallable('fbPaymentCallback');

  return dispatch => new Promise(async (resolve) => {
    fbPaymentCallbackFunction(resp).then((result) => {
      resolve(dispatch({ type: 'FB_PAYMENT_CALLBACK', data: result }));
      return result;
    });
  });
}

export function disableTutorial() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  console.log('disble tutorial');
  return () => new Promise(resolve => Firebase.database().ref(`users/${UID}`).update({
    tutorialShown: true,
  }).then(() => {
    resolve({ status: constants.SERVER_STATUS_CODE.success });
  }));
}


export function setCheckedVersion(checkedVersion) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('setCheckedVersion', checkedVersion);

  return () => new Promise(resolve => Firebase.database().ref(`users/${UID}/newVersion`).set(checkedVersion).then(() => {
    resolve({ status: constants.SERVER_STATUS_CODE.success });
  }));
}

export function updateAutoMergeRooms(value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('updateAutoMergeRooms', value, UID);

  return () => new Promise(resolve => Firebase.database().ref(`users/${UID}/autoMergeRooms`).set(value).then((res) => {
    console.log('updateAutoMergeRooms res', res);
    resolve({ status: constants.SERVER_STATUS_CODE.success });
  }).catch(err => {
    console.log('updateAutoMergeRooms err', err);
  }));
}


export function disableFirstTimeNotif() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid)
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => Firebase.database()
    .ref(`users/${UID}`)
    .update({
      firstTimeModal: true,
    })
    .then(() => {
      resolve({ status: constants.SERVER_STATUS_CODE.success });
    }));
}

export function supportMessageFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (fileUpload != '') {
    return () => new Promise((resolve) => {
      const uploadTask = FirebaseStorage.ref(`/attachFiles/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: constants.SERVER_STATUS_CODE.success, url: downloadURL });
          });
        });
    });
  }
}

export function sendSupportMessage({
  message, name, fileUploadUrl, fileName, email, title, design, screen
}) {
  const trimmedMessage = message.trim();

  // if (!message || !trimmedMessage) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  /*  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null; */

  return (dispatch) => new Promise(resolve => {
    let env = screen || constants.DWEB_PLATFORM;

    const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
    const isInFacebooxAppFrame = config.isInFacebookAppFrame();
    const isInInboxAppFrame = config.isInInboxAppFrame();
    console.log(config.isInDraugiemAppFrame(), "draugiem check=")
    let deviceInfo = screen || constants.DWEB_PLATFORM;
    if (isTablet) {
      env = constants.TABLET_PLATFORM;
      deviceInfo = constants.TABLET_PLATFORM;
    } else if (isIOS) {
      env = constants.IOS_PLATFORM.toLowerCase();
      deviceInfo = constants.IOS_PLATFORM.toLowerCase();
    } else if (isAndroid && !isDesktop) {
      env = constants.ANDROID_PLATFORM;
      deviceInfo = constants.ANDROID_PLATFORM;
    } else if (isInDraugiemAppFrame) {
      env = constants.DRAUGIEM_PLATFORM;
    } else if (isInFacebooxAppFrame) {
      env = constants.FACEBOOK_PLATFORM;
    } else if (isInInboxAppFrame) {
      env = constants.INBOX_PLATFORM;
    }
    let additionalData = {};
    
    if (deviceInfo === 'android' || deviceInfo === 'ios') {
      additionalData = {
        ...additionalData,
        appVersion: osVersion || '',
        osVersion: `${osName || ''} - ${mobileModel || ''}`,
      };
    }
    console.log({env}, {osName}, {deviceInfo}, {design}, "device check: --- 2")

    const sendChatFunction = Firebase.app().functions('europe-west1').httpsCallable('sendSupportChatMessage');

    sendChatFunction({
      message: trimmedMessage,
      fileUpload: fileUploadUrl || null,
      fileName: fileName || null,
      env,
      device: deviceInfo,
      email: email?.toLowerCase() || null,
      name: name || null,
      title: title || null,
      design,
      ...additionalData
    }).then(() => {
      //  Event("Chat", "Send chat message", null);
      console.log(env, "contacts us2");

      if (message === "Message limit reached") {
        return resolve(dispatch({ type: 'SUPPORT_MESSAGE_ERROR', data: message }));
      }
      return resolve(dispatch({ type: 'SUPPORT_MESSAGE_SUCCESS', data: message }));
    });
  });
}

export function setSupportAsRead() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return () => new Promise(resolve => FirebaseRef.child(`supportChat/activeChats/${UID}`).update({
    read: true,
  }).then(() => {
    resolve({ status: constants.SERVER_STATUS_CODE.success });
  }).catch((err) => {
    resolve({ status: constants.SERVER_STATUS_CODE.error, mesage: err });
  }));
}


export function getDailyBonusHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  let UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getDailyBonusHistory', UID);

  if (!UID) return () => new Promise(resolve => resolve());

  // UID = '300238';

  return () => new Promise(resolve => UserStatsRef().child(`userBalHistory/${UID}`)
    .orderByChild('type')
    .equalTo('dailyBonus')
    .limitToLast(30)
    .once('value', (snapshot) => {
      let history = snapshot.val() || {};

      history = Object.keys(history).sort((a, b) => a.time - b.time).reverse().reduce((obj, key) => {
        obj[key] = history[key];
        return obj;
      }, {});

      console.log('dailyBonus', history);

      resolve({ history });
    }));
}

export function getTransactionsHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const getTransactionsHistoryFunction = Firebase.app().functions().httpsCallable('getTransactionsHistory');

    getTransactionsHistoryFunction({}).then((result) => {
      if (result.data && result.data.data && result.data.status === constants.SERVER_STATUS_CODE.success) {
        return resolve(result.data.data);
      }
      return resolve();
    });
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function getBalanceHistory(time, offset = 0) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID || !time) return () => new Promise(resolve => resolve());

  let startAt;
  let endAt;

  const test = Date.now() + offset;

  let d = new Date(test);

  d.setHours(24, 0, 0, 0);
  d = Date.parse(d);

  if (time === 'today') {
    startAt = d - (1000 * 60 * 60 * 24);
    endAt = d;
  } else if (time === 'yesterday') {
    startAt = d - (1000 * 60 * 60 * 48);
    endAt = d - (1000 * 60 * 60 * 24);
  } else if (time === '2daysBefore') {
    startAt = d - (1000 * 60 * 60 * 72);
    endAt = d - (1000 * 60 * 60 * 48);
  } else {
    return () => new Promise(resolve => resolve());
  }

  return dispatch => new Promise(resolve => UserStatsRef().child(`userBalHistory/${UID}`)
    .orderByChild('time')
    .startAt(startAt)
    .endAt(endAt)
    // .limitToLast(125)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};

      resolve(dispatch({
        type: 'BAL_HISTORY',
        data: history,
      }));
    }));
}


export function getPointsHistory(time, offset) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID || !time) return () => new Promise(resolve => resolve());

  let startAt;
  let endAt;

  const test = Date.now() + offset;

  let d = new Date(test);

  d.setHours(24, 0, 0, 0);
  d = Date.parse(d);

  if (time === 'today') {
    startAt = d - (1000 * 60 * 60 * 24);
    endAt = d;
  } else if (time === 'yesterday') {
    startAt = d - (1000 * 60 * 60 * 48);
    endAt = d - (1000 * 60 * 60 * 24);
  } else if (time === '2daysBefore') {
    startAt = d - (1000 * 60 * 60 * 72);
    endAt = d - (1000 * 60 * 60 * 48);
  } else {
    return () => new Promise(resolve => resolve());
  }

  return dispatch => new Promise(resolve => UserStatsRef().child(`userPointsHistory/${UID}`)
    .orderByChild('time')
    .startAt(startAt)
    .endAt(endAt)
    //  .limitToLast(125)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};

      resolve(dispatch({
        type: 'POINTS_HISTORY',
        data: history,
      }));
    }));
}

export function getActiveGifts() {
  console.log('active gifts before');

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('active gifts', UID);
  return dispatch => new Promise(resolve => FirebaseRef.child(`activeGifts/${UID}`)
    .once('value', (snapshot) => {
      const gifts = snapshot.val() || {};
      console.log(gifts, 'gifts action');
      resolve(dispatch({
        type: 'ACTIVE_GIFTS_REPLACE',
        data: gifts,
      }));
    }));
}

export function getGiftsHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child(`giftsHistory/${UID}`)
    .orderByKey()
    .limitToLast(6)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};

      resolve(dispatch({
        type: 'GIFTS_HISTORY',
        data: history,
      }));
    }));
}


export function getGamesHistory() {
  console.log('getGamesHistory');

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const promises = [];
  const stats = {};

  return dispatch => new Promise((resolve) => {
    for (let i = 1; i < 30; i += 1) {
      const today = new Date();
      today.setDate(today.getDate() - i);

      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();

      //  console.log('date', { today, date, month });

      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }

      const dateString = `${year}-${month}-${date}`;

      //  console.log('dateString', { dateString });
      promises.push(UserStatsRef().child(`userRoomsResults/${dateString}/${UID}`)
        .once('value', (snapshot) => {
          const history = snapshot.val() || {};

          console.log('userRoomsResults userRoomsResults history', dateString, history);

          stats[dateString] = history;
        }));
    }

    const today2 = new Date();

    const year2 = today2.getFullYear();
    let month2 = today2.getMonth() + 1;
    let date2 = today2.getDate();

    if (month2 < 10) {
      month2 = `0${month2}`;
    }
    if (date2 < 10) {
      date2 = `0${date2}`;
    }

    const dateString2 = `${year2}-${month2}-${date2}`;

    console.log('dateString2', { dateString2 });

    Promise.all(promises).then(() => {
      const promises2 = [];
      console.log('getGamesHistory test 1');

      promises2.push(new Promise((resolve2) => {
        UserStatsRef().child(`todayUserResults/${UID}`).once('value', (todayUserSnapshot) => {
          const todayUserResults = todayUserSnapshot.val() || {};

          console.log('todayUserResults', todayUserResults);

          let totalMoney = 0;

          if (todayUserResults && todayUserResults.totalMoney) {
            totalMoney = todayUserResults.totalMoney || 0;
          }

          console.log('totalMoney', totalMoney);

          UserStatsRef().child(`todayRoomResults/playerRooms/${UID}`).once('value', (snapshot) => {
            const history = snapshot.val() || {};

            let todayStats = {};
            const uid = UID;

            console.log('getGamesHistory history', history);
            Object.keys(history).map((key) => {
              const { totalResults, totalBalChange, totalPointsChange } = history[key];

              if (totalResults && totalResults[uid]) {
                todayStats = {
                  ...todayStats,
                  wins: (todayStats.wins || 0) + ((totalResults[uid] && totalResults[uid].wins) ? totalResults[uid].wins : 0),
                  largeWins: (todayStats.largeWins || 0) + ((totalResults[uid] && totalResults[uid].largeWins) ? totalResults[uid].largeWins : 0),
                  smallWins: (todayStats.smallWins || 0) + ((totalResults[uid] && totalResults[uid].smallWins) ? totalResults[uid].smallWins : 0),
                  zoleWins: (todayStats.zoleWins || 0) + ((totalResults[uid] && totalResults[uid].zoleWins) ? totalResults[uid].zoleWins : 0),
                  loses: (todayStats.loses || 0) + ((totalResults[uid] && totalResults[uid].loses) ? totalResults[uid].loses : 0),
                  largeLoses: (todayStats.largeLoses || 0) + ((totalResults[uid] && totalResults[uid].largeLoses) ? totalResults[uid].largeLoses : 0),
                  smallLoses: (todayStats.smallLoses || 0) + ((totalResults[uid] && totalResults[uid].smallLoses) ? totalResults[uid].smallLoses : 0),
                  zoleLoses: (todayStats.zoleLoses || 0) + ((totalResults[uid] && totalResults[uid].zoleLoses) ? totalResults[uid].zoleLoses : 0),
                  galdinsLoses: (todayStats.galdinsLoses || 0) + ((totalResults[uid] && totalResults[uid].galdinsLoses) ? totalResults[uid].galdinsLoses : 0),
                  galdinsWins: (todayStats.galdinsWins || 0) + ((totalResults[uid] && totalResults[uid].galdinsWins) ? totalResults[uid].galdinsWins : 0),

                  roomsPlayed: (todayStats.roomsPlayed || 0) + 1,
                  roundsPlayed: (todayStats.roundsPlayed || 0) + ((totalResults[uid] && totalResults[uid].roundsPlayed) ? totalResults[uid].roundsPlayed : 0),

                  balChange: (todayStats.balChange || 0) + (totalBalChange[uid] || 0),
                  pointsChange: (todayStats.pointsChange || 0) + (totalPointsChange[uid] || 0),
                };
              } else {
                todayStats = {
                  ...todayStats,
                  balChange: ((todayStats && todayStats.balChange) ? todayStats.balChange : 0) + (totalBalChange[uid] || 0),
                  pointsChange: ((todayStats && todayStats.pointsChange) ? todayStats.pointsChange : 0) + (totalPointsChange[uid] || 0),
                };
              }

              return null;
            });

            todayStats = {
              ...todayStats,
              totalMoney: totalMoney,
            }

            stats[dateString2] = todayStats;

            return resolve2();
          });
        }).catch(err => {
          console.log('err', err);
        });
      }));

      Promise.all(promises2).then(() => {
        console.log('promises2 resolve');

        resolve(dispatch({
          type: 'GAMES_HISTORY',
          data: stats,
        }));
      });
    });
  });
}

export function getWeeklyStatistics() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const promises = [];
  const stats = {};
  const oneWeekAgo = [];
  const twoWeeksAgo = [];
  const weeklyStats = {};
  return dispatch => new Promise((resolve) => {
    for (let i = 1; i <= constants.WEEKLY_VALUE.twoWeek; i++) {
      const someday = new Date();
      // set to Monday of this week
      const firstDay = new Date(someday.setDate(someday.getDate() - (someday.getDay() + 6) % 7));
      const weeklyDay = new Date(firstDay.setDate(firstDay.getDate() - i));

      const year = weeklyDay.getFullYear();
      let month = weeklyDay.getMonth() + 1;
      let date = weeklyDay.getDate();

      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }

      const dateString = `${year}-${month}-${date}`;

      if (i <= constants.WEEKLY_VALUE.oneWeek) {
        oneWeekAgo.push(dateString);
      } else {
        twoWeeksAgo.push(dateString);
      }

      promises.push(UserStatsRef().child(`userRoomsResults/${dateString}/${UID}`)
        .once('value', (snapshot) => {
          const history = snapshot.val() || {};

          console.log('history', history);

          stats[dateString] = history;
        }));
    }
    Promise.all(promises).then(() => {
      map(stats, (item, key) => {
        if (oneWeekAgo.includes(key)) {
          weeklyStats.oneWeekAgo = {
            daysPlayed: get(weeklyStats, 'oneWeekAgo.daysPlayed', 0) + (item && item.roundsPlayed ? 1 : 0),
            gamesPlayed: get(weeklyStats, 'oneWeekAgo.gamesPlayed', 0) + (item && item.roundsPlayed ? item.roundsPlayed : 0),
            roomsPlayed: get(weeklyStats, 'oneWeekAgo.roomsPlayed', 0) + (item && item.roomsPlayed ? item.roomsPlayed : 0),
            wins: get(weeklyStats, 'oneWeekAgo.wins', 0) + (item && item.wins ? item.wins : 0),
            loses: get(weeklyStats, 'oneWeekAgo.loses', 0) + (item && item.loses ? item.loses : 0),
            purchases: get(weeklyStats, 'oneWeekAgo.purchases', 0) + (item && item.purchases ? item.purchases : 0),
            tournaments: get(weeklyStats, 'oneWeekAgo.tournaments', 0) + (item && item.tournaments ? item.tournaments : 0),
            achievements: get(weeklyStats, 'oneWeekAgo.achievements', 0) + (item && item.achievements ? item.achievements : 0),
            receivedGifts: get(weeklyStats, 'oneWeekAgo.receivedGifts', 0) + (item && item.giftsReceived ? item.giftsReceived : 0),
            sentGifts: get(weeklyStats, 'oneWeekAgo.sentGifts', 0) + (item && item.giftsSent ? item.giftsSent : 0),
          };
        } else if (twoWeeksAgo.includes(key)) {
          weeklyStats.twoWeeksAgo = {
            daysPlayed: get(weeklyStats, 'twoWeeksAgo.daysPlayed', 0) + (item && item.roundsPlayed ? 1 : 0),
            gamesPlayed: get(weeklyStats, 'twoWeeksAgo.gamesPlayed', 0) + (item && item.roundsPlayed ? item.roundsPlayed : 0),
            roomsPlayed: get(weeklyStats, 'twoWeeksAgo.roomsPlayed', 0) + (item && item.roomsPlayed ? item.roomsPlayed : 0),
            wins: get(weeklyStats, 'twoWeeksAgo.wins', 0) + (item && item.wins ? item.wins : 0),
            loses: get(weeklyStats, 'twoWeeksAgo.loses', 0) + (item && item.loses ? item.loses : 0),
            purchases: get(weeklyStats, 'twoWeeksAgo.purchases', 0) + (item && item.purchases ? item.purchases : 0),
            tournaments: get(weeklyStats, 'twoWeeksAgo.tournaments', 0) + (item && item.tournaments ? item.tournaments : 0),
            achievements: get(weeklyStats, 'twoWeeksAgo.achievements', 0) + (item && item.achievements ? item.achievements : 0),
            receivedGifts: get(weeklyStats, 'twoWeeksAgo.receivedGifts', 0) + (item && item.giftsReceived ? item.giftsReceived : 0),
            sentGifts: get(weeklyStats, 'twoWeeksAgo.sentGifts', 0) + (item && item.giftsSent ? item.giftsSent : 0),
          };
        }
      });

      console.log('weeklyStatsweeklyStats', weeklyStats);

      resolve(dispatch({
        type: 'WEEKLY_STATISTICS',
        data: weeklyStats,
      }));
    });
  });
}

export function blockUser(id, name) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID || !id || !name) return () => new Promise(resolve => resolve());

  return (dispatch, getState) => new Promise(resolve => FirebaseRef.child(`ignoredPlayers/${UID}/${id}`).update({
    //  uid: blockedUserId,
    name,
    date: Firebase.database.ServerValue.TIMESTAMP,
  }).then(() => {
    const { member } = getState();

    console.log('member', member);

    FirebaseRef.child(`ignoredBy/${id}/${UID}`).update({
      name: member.name || null,
      ignoredByUID: UID,
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    resolve(dispatch({
      type: 'USER_IGNORED',
      status: constants.SERVER_STATUS_CODE.success,
      data: id,
    }));
    //  resolve({ status: constants.SERVER_STATUS_CODE.success  });
  }).catch((err) => {
    resolve({ status: constants.SERVER_STATUS_CODE.error, message: err });
  }));
}


export function unBlockUser(id) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID || !id) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`ignoredPlayers/${UID}/${id}`).remove().then(() => {
    FirebaseRef.child(`ignoredBy/${id}/${UID}`).remove();

    resolve(dispatch({
      type: 'USER_UNIGNORED',
      status: constants.SERVER_STATUS_CODE.success,
      data: id,
    }));
    //  resolve({ status: constants.SERVER_STATUS_CODE.success  });
  }).catch((err) => {
    resolve({ status: constants.SERVER_STATUS_CODE.error, message: err });
  }));
}


export function getFBFriends(users) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const getFBFriendsFunction = Firebase.app().functions().httpsCallable('getUserFriends');

  return dispatch => new Promise(resolve => {
    dispatch({ type: 'LOADING_FRIEND_LOGS', data: {}, isLoading: true });
    getFBFriendsFunction({
      users,
    }).then((result) => {
      console.log('getUserFriends', result);
      if (result.data && result.data.data) {
        return resolve(dispatch({ type: 'FRIENDS', data: result.data.data, isLoading: false }));
      }
      return resolve(null);
    }).catch((err) => {
      console.log(err);
    })
  });
}


export function getDraugiemFriends(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('getDraugiemFriends', devMode);

  const getDraugiemFriendsFunction = Firebase.app().functions().httpsCallable('getUserFriends');

  return dispatch => new Promise(resolve => {
    dispatch({ type: 'LOADING_FRIEND_LOGS', data: {}, isLoading: true });
    getDraugiemFriendsFunction({
      devMode,
    }).then((result) => {
      console.log('getUserFriends', result);

      if (result.data.status !== constants.SERVER_STATUS_CODE.error && result.data && result.data.data) {
        return resolve(dispatch({ type: 'FRIENDS', data: result.data.data, isLoading: false }));
      }
      return resolve(dispatch({ type: 'FRIENDS', data: {}, isLoading: false }));
    });
  });
}


export function getIgnoredPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getIgnoredPlayers');

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`ignoredPlayers/${UID}`)
    .once('value', async (snapshot) => {
      const ignoredPlayers = snapshot.val() || {};

      console.log('test ignoredPlayers ignoredPlayers', ignoredPlayers);

      /* await Promise.all(map(ignoredPlayers, async (item, key) => {
        console.log('test photo fetch');

        const photo = await FirebaseRef.child(`users/${key}/photo`).once('value');
        const bal = await FirebaseRef.child(`users/${key}/bal`).once('value');
        const lvl = await FirebaseRef.child(`users/${key}/lvl`).once('value');
        ignoredPlayers[key].photo = photo.val() || null;
        ignoredPlayers[key].bal = bal.val() || null;
        ignoredPlayers[key].lvl = lvl.val() || null;

        console.log('getIgnoredPlayers key', key, ignoredPlayers[key].photo, ignoredPlayers[key].bal, ignoredPlayers[key].lvl);

        if (key && !ignoredPlayers[key].photo && !ignoredPlayers[key].bal && !ignoredPlayers[key].lvl) {
          //check if ingored user still exists and delete if not
          console.log('check if ingored user still exists and delete if not');

          const checkIfIgnoredUserStillExistsFunction = Firebase.app().functions().httpsCallable('checkIfIgnoredUserStillExists');

          await checkIfIgnoredUserStillExistsFunction({
            uidToCheck: key,
          }).then((result) => {
            console.log('checkIfIgnoredUserStillExists', result);

            if (result && result.data && result.data.deleted) {
              delete ignoredPlayers[key];
            }

            console.log('updated ignoredPlayers');
          }).catch((err) => {
            console.log('checkIfIgnoredUserStillExists err', err);
          });
        }
      })); */

      console.log('getIgnoredPlayers ignoredPlayers', ignoredPlayers);

      return resolve(dispatch({
        type: 'IGNORED_PLAYERS',
        data: ignoredPlayers,
      }));
    }));
}

export function getIgnoredByPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`ignoredBy/${UID}`)
    .once('value', async (snapshot) => {
      const ignoredBy = snapshot.val() || {};

      console.log('test ignoredPlayers ignoredBy', ignoredBy);

      /* await Promise.all(map(ignoredBy, async (item, key) => {
        console.log('test photo fetch');

        const photo = await FirebaseRef.child(`users/${key}/photo`).once('value');
        const bal = await FirebaseRef.child(`users/${key}/bal`).once('value');
        const lvl = await FirebaseRef.child(`users/${key}/lvl`).once('value');
        ignoredBy[key].photo = photo.val() || null;
        ignoredBy[key].bal = bal.val() || null;
        ignoredBy[key].lvl = lvl.val() || null;

        if (key && !ignoredBy[key].photo && !ignoredBy[key].bal && !ignoredBy[key].lvl) {
          //check if ingored user still exists and delete if not
          const checkIfIgnoredUserStillExistsFunction = Firebase.app().functions().httpsCallable('checkIfIgnoredUserStillExists');

          await checkIfIgnoredUserStillExistsFunction({
            uidToCheck: key,
          }).then((result) => {
            if (result && result.data && result.data.deleted) {
              delete ignoredBy[key];
            }
          }).catch((err) => {
            console.log('checkIfIgnoredUserStillExists err', err);
          });
        }
      })); */

      return resolve(dispatch({
        type: 'IGNORED_BY_PLAYERS',
        data: ignoredBy,
      }));
    }));
}




export function getIgnoredPlayersFull() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getIgnoredPlayers');

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`ignoredPlayers/${UID}`)
    .once('value', async (snapshot) => {
      const ignoredPlayers = snapshot.val() || {};

      console.log('test ignoredPlayers ignoredPlayers', ignoredPlayers);

      await Promise.all(map(ignoredPlayers, async (item, key) => {
        console.log('test photo fetch');

        const photo = await FirebaseRef.child(`users/${key}/photo`).once('value');
        const bal = await FirebaseRef.child(`users/${key}/bal`).once('value');
        const lvl = await FirebaseRef.child(`users/${key}/lvl`).once('value');
        ignoredPlayers[key].photo = photo.val() || null;
        ignoredPlayers[key].bal = bal.val() || null;
        ignoredPlayers[key].lvl = lvl.val() || null;

        console.log('getIgnoredPlayers key', key, ignoredPlayers[key].photo, ignoredPlayers[key].bal, ignoredPlayers[key].lvl);

        if (key && !ignoredPlayers[key].photo && !ignoredPlayers[key].bal && !ignoredPlayers[key].lvl) {
          //check if ingored user still exists and delete if not
          console.log('check if ingored user still exists and delete if not');

          const checkIfIgnoredUserStillExistsFunction = Firebase.app().functions().httpsCallable('checkIfIgnoredUserStillExists');

          await checkIfIgnoredUserStillExistsFunction({
            uidToCheck: key,
          }).then((result) => {
            console.log('checkIfIgnoredUserStillExists', result);

            if (result && result.data && result.data.deleted) {
              delete ignoredPlayers[key];
            }

            console.log('updated ignoredPlayers');
          }).catch((err) => {
            console.log('checkIfIgnoredUserStillExists err', err);
          });
        }
      }));

      console.log('getIgnoredPlayers ignoredPlayers', ignoredPlayers);

      return resolve(dispatch({
        type: 'IGNORED_PLAYERS',
        data: ignoredPlayers,
      }));
    }));
}

export function getIgnoredByPlayersFull() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`ignoredBy/${UID}`)
    .once('value', async (snapshot) => {
      const ignoredBy = snapshot.val() || {};

      console.log('test ignoredPlayers ignoredBy', ignoredBy);

      await Promise.all(map(ignoredBy, async (item, key) => {
        console.log('test photo fetch');

        const photo = await FirebaseRef.child(`users/${key}/photo`).once('value');
        const bal = await FirebaseRef.child(`users/${key}/bal`).once('value');
        const lvl = await FirebaseRef.child(`users/${key}/lvl`).once('value');
        ignoredBy[key].photo = photo.val() || null;
        ignoredBy[key].bal = bal.val() || null;
        ignoredBy[key].lvl = lvl.val() || null;

        if (key && !ignoredBy[key].photo && !ignoredBy[key].bal && !ignoredBy[key].lvl) {
          //check if ingored user still exists and delete if not
          const checkIfIgnoredUserStillExistsFunction = Firebase.app().functions().httpsCallable('checkIfIgnoredUserStillExists');

          await checkIfIgnoredUserStillExistsFunction({
            uidToCheck: key,
          }).then((result) => {
            if (result && result.data && result.data.deleted) {
              delete ignoredBy[key];
            }
          }).catch((err) => {
            console.log('checkIfIgnoredUserStillExists err', err);
          });
        }
      }));

      return resolve(dispatch({
        type: 'IGNORED_BY_PLAYERS',
        data: ignoredBy,
      }));
    }));
}


export function getAchievements() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`claimedAchievements/${UID}`)
      .once('value', (snapshot) => {
        const claimedAchievements = snapshot.val() || {};

        resolve(dispatch({
          type: 'CLAIMED_ACHIEVEMENTS',
          data: claimedAchievements,
        }));
      }, (err) => {
        console.log(err);
      });

    FirebaseRef.child(`userAchievements/${UID}`)
      .once('value', (snapshot) => {
        const userAchievements = snapshot.val() || {};

        return resolve(dispatch({
          type: 'USER_ACHIEVEMENTS',
          data: userAchievements,
        }));
      }, (err) => {
        console.log(err);
      });
  });
}

export function claimAchievement(achievementId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  return dispatch => new Promise((resolve) => {
    const claimAchievementFunction = Firebase.app()
      .functions()
      .httpsCallable('claimAchievement');

    claimAchievementFunction({
      achievementId,
      devMode,
      newRewardAmounts: true,
    }).then((res) => {
      if (res && res.data && res.data.type === constants.SERVER_STATUS_CODE.success) {
        return FirebaseRef.child(`claimedAchievements/${UID}`)
          .once('value', (snapshot) => {
            const claimedAchievements = snapshot.val() || {};

            return resolve(dispatch({
              type: 'CLAIMED_ACHIEVEMENTS',
              data: claimedAchievements,
            }));
          });
      }
      return resolve();
    }).catch(err => resolve());
  });
}

export function claimHoliday(holidayType, holidayTestOption) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('claimHoliday action', holidayType);

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    let claimHolidayFunction;
    if (holidayType === 'nameDay') {
      claimHolidayFunction = Firebase.app().functions().httpsCallable('claimNameDay');
    } else if (holidayType === 'birthday') {
      claimHolidayFunction = Firebase.app().functions().httpsCallable('claimBirthday');
    } else if (holidayType === 'holiday') {
      claimHolidayFunction = Firebase.app().functions().httpsCallable('claimHoliday');
    }
    const holidayTest = holidayTestOption || false;

    if (claimHolidayFunction) {
      console.log('claimBirthday call', holidayType);
      claimHolidayFunction({ holidayTest }).then(() => resolve()).catch(() => resolve());
    }
  });
}

export function closeLevelNotification() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/lvlUpNotification`).set(false).then(() => {
      resolve();
    }).catch((err) => {
      console.log(err);
      resolve();
    });
  });
}


export function getUserDbStatus() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    const connectedRef = Firebase.database().ref('.info/connected');
    connectedRef.on('value', (snap) => {
      if (snap.val() === true) {
        return resolve(dispatch({
          type: 'USER_CONNECTED_STATUS',
          data: true,
        }));
      }
      return resolve(dispatch({
        type: 'USER_CONNECTED_STATUS',
        data: false,
      }));
    });
  });
}


export function initSmartLook() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => resolve(dispatch({
    type: 'SMART_LOOK_STATUS',
    data: false,
  })));
}


export function failedLoginLog(authCodeText, hostname, search, member) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child('failedLoginLog').push({
      authCodeText: authCodeText || null,
      hostname: hostname || null,
      search: search || null,
      uid: member.uid || null,
      name: member.name || null,
      socId: member.socId || null,
    });

    return resolve();
  });
}

export function removeActiveRoom() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/activeRoom`).remove();

    return resolve(dispatch({
      type: 'REMOVE_ACTIVE_ROOM',
      data: false,
    }));
  });
}


export function cancelMenuListeners() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  /*  const UID = (
    FirebaseRef
      && Firebase
      && Firebase.auth()
      && Firebase.auth().currentUser
      && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null; */

  return () => new Promise((resolve) => {
    FirebaseRef.child('bans').off();
    FirebaseRef.child('roomsCount').off();
    FirebaseRef.child('onlineCount').off();

    TournamentsRef().child('playerTournamentHistory/xZtfLcw5H5ZzmroPhvt8B1nwXKy1').off();
    FirebaseRef.child('todayHolidays').off();
    FirebaseRef.child('claimedRewards').off();

    return resolve();
  });
}

export function mobilePaymentCallback(productId, uid, coins, tid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const mobilePaymentCallbackFunc = Firebase.app()
    .functions()
    .httpsCallable('mobilePaymentCallback');


  return dispatch => new Promise(async (resolve) => {
    mobilePaymentCallbackFunc({
      productId, uid, coins, tid,
    }).then(
      (result) => {
        resolve(dispatch({ type: 'MOBILE_PAYMENT_CALLBACK', data: result }));
        //  console.log(
        //    `mobilePaymentCallback = result ${JSON.stringify(result, null, 4)}`,
        //  );

        return result;
      },
    );
  });
}

export function completeAppleLogin(userData) {
  return dispatch => new Promise((resolve) => {
    const afterAppleAuth = Firebase.app()
      .functions('europe-west1')
      .httpsCallable('afterAppleAuth');

    afterAppleAuth({
      uid: userData.uid,
      providerData: userData.providerData,
      additionalUserInfo: userData.additionalUserInfo,
    })
      .then((result) => {
        if (result.data && result.data.uid) {
          const {
            uid,
          } = result.data;

          if (uid) {
          //  Firebase.database().ref(`users/${uid}`).update({
          //    lastLogin: Firebase.database.ServerValue.TIMESTAMP,
          //  });

            StatusRef.child(`status/${uid}`)
              .update({
                status: true,
                lastAction: Firebase.database.ServerValue.TIMESTAMP,
              })
              .catch((err) => {
                console.log('APPLE Auth problem1', { err });
              });
          }
        }

        resolve(
          dispatch({
            type: 'USER_DATA',
            data: result.data,
          }),
        );
      })
      .catch((err) => {
        console.log('APPLE Auth problem', { err });
      });
  });
}


export const claimOSBonus = (os) => {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
    ? Firebase.auth().currentUser.uid
    : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const claimOSBonusFunction = Firebase.app()
      .functions()
      .httpsCallable('claimOSBonus');

    if (claimOSBonusFunction) {
      claimOSBonusFunction({ os })
        .then(() => resolve())
        .catch(() => resolve());
    }
  });
};


export function removeGift(giftId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const removeGiftFunction = Firebase.app().functions().httpsCallable('removeGift');

    removeGiftFunction({
      giftId,
    }).then(result => resolve(result));
  });
}

export function feedbackSubmit(rating, comment, name, feedbackId, design, platform) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('feedbackSubmit', rating, comment, name, feedbackId, design, platform);

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return () => new Promise(resolve => resolve());

  let devPlatform = platform;
  const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
  const isInFacebooxAppFrame = config.isInFacebookAppFrame();
  const isInInboxAppFrame = config.isInInboxAppFrame();

  if (isInDraugiemAppFrame) {
    devPlatform = constants.DRAUGIEM_PLATFORM;
  } else if (isInFacebooxAppFrame) {
    devPlatform = constants.FACEBOOK_PLATFORM;
  } else if (isInInboxAppFrame) {
    devPlatform = constants.INBOX_PLATFORM;
  }

  console.log({devPlatform}, "device check --- 2")
  return dispatch => new Promise((resolve) => {
    Firebase.database().ref(`feedbackHistory/${feedbackId}/${UID}`).once('value', (snapshot) => {
      const feedbackHistory = snapshot.val() || null;

      console.log('feedbackHistory', feedbackHistory);

      if (!feedbackHistory) {
        Firebase.database().ref(`feedbackHistory/${feedbackId}/${UID}`).update({
          feedbackId,
          design,
          platform: devPlatform,
          rating,
          comment,
          name,
          lastFeedback: Firebase.database.ServerValue.TIMESTAMP,
          feedbackClose: true,
        });

        try {
          Firebase.database().ref(`feedbackResults/${feedbackId}`).update({
            count: Firebase.database.ServerValue.increment(1),
            total: Firebase.database.ServerValue.increment(parseInt(rating, 10)),
          });
        } catch (err) {
          console.log(err);
        }

        return resolve(dispatch({
          type: 'ADD_FEEDBACK_REPLACE',
          data: constants.SERVER_STATUS_CODE.success,
        }));
      }

      return resolve(dispatch({
        type: 'ADD_FEEDBACK_REPLACE',
        data: constants.SERVER_STATUS_CODE.error,
      }));
    }).catch((err) => {
      return resolve(dispatch({
        type: 'ADD_FEEDBACK_REPLACE',
        data: constants.SERVER_STATUS_CODE.error,
      }));
    });
  });
}

export function checkFeedbackModal() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => Firebase.database().ref(`users/${UID}`)
    .once('value', (snap) => {
      const userData = snap.val() || {};

      FirebaseRef.child('settings/feedback').on('value', (snapshot) => {
        const feedbackSetting = snapshot.val();
        const nowDate = Date.parse(new Date());

        console.log('feedbackSetting', userData, feedbackSetting);

        if (feedbackSetting && feedbackSetting.feedbackStart) {
          if (nowDate > constants.CALC_ACTIVE_FEEDBACK_TIME(userData.dateCreated, constants.WEEKLY_VALUE.oneWeek)) {
            if (userData.feedback && userData.feedback.feedbackStartTime && userData.feedback.feedbackShowTime) {
              if (userData.feedback.feedbackStartTime < feedbackSetting.feedbackStartTime) {
                FirebaseRef.child(`users/${UID}/feedback`).update({
                  feedbackShowTime: generateRandom(constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.start), constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.end)),
                  feedbackStartTime: feedbackSetting.feedbackStartTime,
                  feedbackOpen: true,
                });
              } else if (userData.feedback.feedbackStartTime === feedbackSetting.feedbackStartTime) {
                if (nowDate > constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.end)) {
                  FirebaseRef.child(`users/${UID}/feedback`).update({
                    feedbackOpen: false,
                  });
                }
              }
            } else {
              FirebaseRef.child(`users/${UID}/feedback`).update({
                feedbackShowTime: generateRandom(constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.start), constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.end)),
                feedbackStartTime: feedbackSetting.feedbackStartTime,
                feedbackOpen: true,
              });
            }
          } else {
            FirebaseRef.child(`users/${UID}/feedback`).update({
              feedbackOpen: false,
            });
          }
        } else {
          FirebaseRef.child(`users/${UID}/feedback`).update({
            feedbackOpen: false,
          });
        }
        // dispatch({ type: 'CHECK_FEEDBACK_REPLACE', data: userData.feedbackOpen, feedbackShowTime: userData.feedbackShowTime });
      });
      resolve(dispatch({ type: 'CHECK_FEEDBACK_REPLACE', data: userData.feedback || {} }));
    }));
}

export function feedbackClose(id) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
console.log(id, "========================")
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    Firebase.database().ref(`feedbackHistory/${id}/${UID}`).update({
      feedbackClose: true,
    });

    return resolve();
  });
}

export function getLatestVersion() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('versionHistory')
    .orderByChild('versionDate')
    .limitToLast(1)
    .once('value', (snapshot) => {
      const data = snapshot.val() || null;
      console.log(data, 'latest version');
      return resolve(dispatch({ type: 'GET_LATEST_VERSION_REPLACE', data }));
    })
    .catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function getAllVersions() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('versionHistory')
    .orderByChild('versionDate')
    .limitToLast(constants.POWER_RANKINGS_SHOW_LIMIT)
    .once('value', (snapshot) => {
      const data = snapshot.val() || null;

      const array = [];

      Object.keys(data).forEach((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (b.versionDate - a.versionDate));

      return resolve(dispatch({ type: 'GET_ALL_VERSION_REPLACE', data: array }));
    })
    .catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function getAllBlogs() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('blogs')
    .orderByChild('blogDate')
    .limitToLast(constants.POWER_RANKINGS_SHOW_LIMIT)
    .once('value', (snapshot) => {
      const data = snapshot.val() || null;

      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (b.blogDate - a.blogDate));
      console.log('blog action', array);
      return resolve(dispatch({ type: 'GET_ALL_BLOGS_REPLACE', data: array }));
    })
    .catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function claimTutorialBonus() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    const claimTutorialBonusFunc = Firebase.app().functions('europe-west1').httpsCallable('claimTutorialBonus');

    claimTutorialBonusFunc().then((result) => {
      // resolve(dispatch({
      //   type: 'USER_DATA',
      //   data: result.data,
      // }));
      resolve({ status: constants.SERVER_STATUS_CODE.success });
    });
  });
}

export function getPercentPosInfo() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    const promise1 = FirebaseRef.child('statistics/userCount').once('value');
    const promise2 = LeaderboardRef.child(`leaderboard/allTime/${UID}/pos`).once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let totalPlayers;
      let betterPos;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          totalPlayers = promiseRes.val() || 0;
        } else if (index === 1) {
          betterPos = promiseRes.val() || 0;
        }
        return null;
      });

      betterPos = (100 - (betterPos / totalPlayers) * 100).toFixed(2);

      console.log('getPercentPosInfo', betterPos, totalPlayers);

      return resolve(dispatch({ type: 'MY_INFO_POS_PERCENTAGE', data: betterPos }));
    });
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function getPercentGPlayedInfo() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    const promise1 = FirebaseRef.child('statistics/userCount').once('value');
    const promise2 = LeaderboardRef.child(`leaderboard/allTime/${UID}/gPlayedPos`).once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let totalPlayers;
      let betterGPlayed;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          totalPlayers = promiseRes.val() || 0;
        } else if (index === 1) {
          betterGPlayed = promiseRes.val() || 0; // how many players have played more rounds than current player
        }
        return null;
      });
      betterGPlayed = (100 - (betterGPlayed / totalPlayers) * 100).toFixed(2);

      console.log('getPercentPosInfo', betterGPlayed, totalPlayers);

      return resolve(dispatch({ type: 'MY_INFO_GPLAYED_PERCENTAGE', data: betterGPlayed }));
    });
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function getPercentGWonInfo() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getPercentGWonInfo', UID);

  return dispatch => new Promise((resolve) => {
    const promise1 = FirebaseRef.child('statistics/userCount').once('value');
    const promise2 = LeaderboardRef.child(`leaderboard/allTime/${UID}/gWonPos`).once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let totalPlayers;
      let betterGWon;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          totalPlayers = promiseRes.val() || 0;
        } else if (index === 1) {
          betterGWon = promiseRes.val() || 0;
        }
        return null;
      });

      console.log('getPercentGWonInfo betterGWon', betterGWon);

      betterGWon = (100 - (betterGWon / totalPlayers) * 100).toFixed(2);
      return resolve(dispatch({ type: 'MY_INFO_GWON_PERCENTAGE', data: betterGWon }));
    });
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function getPercentTotalPntsInfo() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    const promise1 = FirebaseRef.child('statistics/userCount').once('value');
    const promise2 = LeaderboardRef.child(`leaderboard/allTime/${UID}/pointsPos`).once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let totalPlayers;
      let betterTotalPnts;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          totalPlayers = promiseRes.val() || 0;
        } else if (index === 1) {
          betterTotalPnts = promiseRes.val() || 0;
        }
        return null;
      });
      betterTotalPnts = (100 - (betterTotalPnts / totalPlayers) * 100).toFixed(2);
      return resolve(dispatch({ type: 'MY_INFO_TOTALPNTS_PERCENTAGE', data: betterTotalPnts }));
    });
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function getPercentBalInfo() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return dispatch => new Promise((resolve) => {
    const promise1 = FirebaseRef.child('statistics/userCount').once('value');
    const promise2 = LeaderboardRef.child(`leaderboard/allTime/${UID}/balPos`).once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let totalPlayers;
      let betterBal;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          totalPlayers = promiseRes.val() || 0;
        } else if (index === 1) {
          betterBal = promiseRes.val() || 0;
        }
        return null;
      });
      betterBal = (100 - (betterBal / totalPlayers) * 100).toFixed(2);
      return resolve(dispatch({ type: 'MY_INFO_BAL_PERCENTAGE', data: betterBal }));
    });
  }).catch((err) => {
    console.log('err', err);
    // throw err.message;
  });
}

export function recoverUserEmail(recoverData, devMode, language) {
  let email = '';
  let uid = '';
  forEach(recoverData, (item, key) => {
    uid = item.uid;
    email = item.previousEmail;
  });
  console.log('recoverUserEmail', recoverData, uid, email, "recover data");

  // Go to Firebase
  return () => new Promise((resolve, reject) => {

    if (!uid) return reject({ code: errorMessages.missingData });
    if (!email) return reject({ code: errorMessages.missingEmail });

    const sendResetEmailFunc = Firebase.app().functions('europe-west1').httpsCallable('sendResetEmail');

    const data = {
      uid,
      email: email.toLowerCase(),
      devMode,
      language,
    };

    sendResetEmailFunc(data).then((res) => {
      console.log('sendResetEmailFunc res', res);

      if (res.data === 'error') {
        if (res.error) {
          if (res.error.code === 'auth/internal-error') {
            return resolve({ status: constants.SERVER_STATUS_CODE.error, message: 'internal-error' });
          } else {
            return resolve({ status: constants.SERVER_STATUS_CODE.error, message: 'error' });
          }
        } else {
          return resolve({ status: constants.SERVER_STATUS_CODE.error, message: 'error' });
        }
      } else {
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      }

    }).catch(e => {
      console.log(e, "recover cash error")
      return reject({ code: e.code });
    });
  });
}


export function updateProfileResetEmail(uid, code) {
  return () => new Promise((resolve, reject) => {

    if (!uid) return reject({ code: errorMessages.missingData });
    if (!code) return reject({ code: 'Missing code' });

    const updateProfileResetEmailFunc = Firebase.app().functions().httpsCallable('updateProfileResetEmail');

    const data = {
      uid,
      code,
    };
    updateProfileResetEmailFunc(data).then((res) => {
      console.log('updateProfileResetEmailFunc res', res);
      if (res.data.status === 'error') {
        return resolve({ status: constants.SERVER_STATUS_CODE.error, message: 'error' });
      } else {
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      }

    }).catch(e => {
      console.log(e, "recover cash error")
      return reject({ code: e.code });
    });
  });
}

export function getGameLogs() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  const endDate = new Date(Date.now()).toLocaleString('en-US', { timeZone: 'Europe/Riga' });
  const startDate = new Date(constants.CALC_ACTIVATE_DATE_BY_TIME(constants.TIME_RANGE_TYPE.day)).toLocaleString('en-US', { timeZone: 'Europe/Riga' });
  let rooms = {};
  console.log({ endDate });
  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'TODAY_ROOM_LOGS', data: [], isLoading: true });
    AdminLogsRef().child(`adminLogs/playerRooms/${UID}`)
      .orderByChild('date')
      .startAt(Date.parse(startDate))
      .endAt(Date.parse(endDate))
      .once('value', async (snapshot) => {
        const data = snapshot.val() || {};
        await Promise.all(Object.keys(data).sort((a, b) => data[b].date - data[a].date).map(async (key, idx) => {
          const promise1 = AdminLogsRef().child(`adminLogs/rooms/${key}`).once('value');
          const promise2 = RoomsRef.child(`roomsPoints/${key}/points`).once('value');
          const playerImg = {};
          await Promise.all([promise1, promise2]).then(async (promisesRes) => {
            let roomData;
            let roomsPoints;
            const playerData = [];
            await Promise.all(promisesRes.map(async (resp, index) => {
              if (index === 0) {
                //roomData = resp.val();
                roomData = resp.val() || [];
              } else if (index === 1) {
                roomsPoints = resp.val();
              }

              console.log('roomData', roomData);

              const playerNames = roomData[0];
              if (roomData[0] && roomData[1]) {
                const players = roomData[1];
                await Promise.all(map(roomData[1], async (player) => {
                  if (player.type === constants.PLAYERJOINED) {
                    playerData[player.data.joinedPlayerUid] = player.data?.joinedPlayerName || playerNames?.player1;

                    console.log('test photo fetch');
                    await FirebaseRef.child(`users/${player.data.joinedPlayerUid}/photo`).once('value', (snapshot2) => {
                      const playersImg = snapshot2.val();
                      playerImg[player.data?.joinedPlayerName || playerNames.player1] = { photo: playersImg || '' };
                    });
                  }
                }));
              }
              return null;
            }));

            if (roomData[0]) {
              console.log('playerData', { playerData, playerImg });
              rooms[key] = {
                roomIdData: data[key], roomData, roomsPoints, playerImg, playerData, orderDate: data[key].date
              };
            }
          });
        }));
        console.log(rooms, 'room datas');
        return resolve(dispatch({ type: 'TODAY_ROOM_LOGS', data: rooms, isLoading: false }));
      })
      .catch((err) => {
        console.log('err', err);
        // throw err.message;
      });
  });
}

export function setFontMode(fontMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return (dispatch, getState) => new Promise((resolve) => {
    if (!UID) {
      const { member } = getState();
      const { fontMode } = member;
      console.log('fontMode', fontMode);

      return resolve(dispatch({
        type: 'SET_USER_FONT_MODE',
        data: fontMode || constants.FONT_MODE.normal,
      }));
    }
    console.log("actionFontFont", fontMode)
    FirebaseRef.child(`users/${UID}`).update({
      fontMode,
    });
    return resolve(dispatch({ type: 'SET_USER_FONT_MODE', data: fontMode }));
  });
}

export function getFontMode() {
  console.log('getFontMode');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('getFontMode UID', UID);

  if (!UID) return () => new Promise(resolve => resolve());

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/fontMode`).once('value', (snapshot) => {
      const fontMode = snapshot.val() || 'normal';
      resolve(dispatch({ type: 'GET_USER_FONT_MODE', data: fontMode }));
    });
  });
}





export function setDesign(design) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  return (dispatch) => new Promise((resolve) => {
    if (!UID) {
      return resolve();
    }

    console.log("setDesign", design, UID);
    if (design) {
      FirebaseRef.child(`users/${UID}`).update({
        design,
        newDesign: design,
      });
    } else {
      FirebaseRef.child(`users/${UID}`).update({
        design: null,
        newDesign: null
      });
    }

    //  return resolve();
    return resolve(dispatch({ type: 'SET_USER_NEW_DESIGN', data: design }));
  });
}

export function getDesign() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/newDesign`).once('value', (snapshot) => {
      const newDesign = snapshot.val() || false;
      resolve(dispatch({ type: 'GET_USER_NEW_DESIGN', data: newDesign }));
    });
  });
}




export function checkWeeklyData(weeklyDataOpen, weeklyDataCheck) {
  console.log('checkWeeklyData', weeklyDataOpen, weeklyDataCheck);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`).update({
      weeklyDataOpen,
      weeklyDataCheck,
    });
  });
}

export function checkWeeklyDataOpenFunction(weeklyDataOpen) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`)
      .once('value', (playerSnapshot) => {
        const playerData = playerSnapshot.val() || {};
        const weeklyDataCheck = playerData?.weeklyDataCheck;
        let weeklyOpen = playerData?.weeklyDataOpen || false;
        const nowDate = new Date();
        let first = nowDate.setDate(nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() == 0 ? -6 : 1));
        let thisWeek = 0;
        let sameWeek = false;
        if (!weeklyOpen) {
          if (weeklyDataCheck) {
            first = moment(new Date(first));
            const previousCheckDay = moment(new Date(weeklyDataCheck));
            thisWeek = moment.duration(first.diff(previousCheckDay)).asDays();
            sameWeek = moment(previousCheckDay).isSame(new Date(), 'week');
          }
          const dateCreated = moment(new Date(playerData?.dateCreated));
          const allowDateVal = moment(nowDate).diff(dateCreated, "days");

          if (
            (nowDate.getDay() >= 1 && !weeklyDataCheck && !weeklyOpen && allowDateVal >= constants.WEEKLY_VALUE.oneWeek) ||
            (nowDate.getDay() >= 1 && weeklyDataCheck && thisWeek > 6 && !weeklyOpen && allowDateVal >= constants.WEEKLY_VALUE.oneWeek) ||
            (nowDate.getDay() >= 1 && weeklyDataCheck && thisWeek < 6 && !sameWeek && !weeklyOpen && allowDateVal >= constants.WEEKLY_VALUE.oneWeek)
          ) {
            weeklyOpen = true;
            FirebaseRef.child(`users/${UID}`).update({
              weeklyDataOpen: true,
            });
          } else {
            weeklyOpen = false;
            FirebaseRef.child(`users/${UID}`).update({
              weeklyDataOpen: false,
            });
          }
        }
        resolve(dispatch({ type: 'UPDATE_WEEKLY_DATA', data: weeklyOpen }));
      });
  });
}

export function handleSetTheme(screenMode) {
  console.log('handleSetTheme', screenMode);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  const { hostname } = window.location;
  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  console.log('handleSetTheme UID', UID, devMode);

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`).update({
      screenMode,
    });

    const osNameVal = osName;
    const osVersionVal = osVersion;
    const browserNameVal = browserName;
    const browserVersionVal = browserVersion;
    const isMobileVal = isMobile;
    let innerWidthVal = 0;
    let innerHeightVal = 0;

    if (window) {
      innerWidthVal = window.innerWidth || 0;
      innerHeightVal = window.innerHeight || 0;
    }

    console.log('setUserLoginStatistics osNameVal', osNameVal, osVersionVal, browserNameVal, browserVersionVal, isMobileVal, innerWidthVal, innerHeightVal);

    const isInAppFrame = config.isInAppFrame();

    console.log('setUserLoginStatistics isInAppFrame', isInAppFrame);

    let isFullscreen = false;

    if (document && ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen))) {
      isFullscreen = false;
    } else {
      isFullscreen = true;
    }

    const setUserLoginStatisticsFunction = Firebase.app().functions().httpsCallable('setUserLoginStatistics');

    setUserLoginStatisticsFunction({
      updateDesign: null,
      newDesign: true,
      language: null,
      fontMode: null,
      screenMode,
      devMode,
      isInAppFrame,
      isFullscreen,
      osName: osNameVal,
      osVersion: osVersionVal,
      browserName: browserNameVal,
      browserVersion: browserVersionVal,
      isMobile: isMobileVal,
      innerWidth: innerWidthVal,
      innerHeight: innerHeightVal,
    }).then(() => resolve());

    resolve(dispatch({ type: 'UPDATE_SCREEN_MODE', data: screenMode }));
  });
}

export function toggleAutoPlay(disabledAutoPlay) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  console.log("auto test action: ", disabledAutoPlay)
  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`).update({
      disableAutoPlay: disabledAutoPlay,
    });

    resolve(dispatch({ type: 'TOGGLE_AUTO_PLAY', data: disabledAutoPlay }));
  });
}

export function toggleShowHint(showHint) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const checkDailyTip = Date.parse(new Date());
  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`).update({
      showHint,
      checkDailyTip,
    });

    return resolve(dispatch({ type: 'REPLACE_SHOW_HINT', data: showHint }));
    // return resolve(dispatch({ type: 'TOGGLE_SHOW_HINT', data: showHint }));
  });
}

export function toggleShowIgnoredRooms(showIgnoredRooms) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`).update({
      showIgnoredRooms,
    });

    return resolve(dispatch({ type: 'REPLACE_SHOW_IGNORED_ROOMS', data: showIgnoredRooms }));
  });
}

export function getUserAutoPlay() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/disableAutoPlay`).once('value', (snapshot) => {
      const disabledAutoPlay = snapshot.val() || false;
      console.log("get auto play: ", disabledAutoPlay);
      resolve(dispatch({ type: 'REPLACE_AUTO_PLAY', data: disabledAutoPlay }));
    });
  });
}

export function getUserShowHint() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/showHint`).once('value', (snapshot) => {
      const showHint = snapshot.val() || false;
      resolve(dispatch({ type: 'REPLACE_SHOW_HINT', data: showHint }));
    });
  });
}

export function getUserShowIgnoredRooms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}/showIgnoredRooms`).once('value', (snapshot) => {
      const showIgnoredRooms = snapshot.val() || false;
      resolve(dispatch({ type: 'REPLACE_SHOW_IGNORED_ROOMS', data: showIgnoredRooms }));
    });
  });
}

export function reportPlayer(reportReason, reportedPlayerId, reportedPlayerName, reporterId, reporterName, roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  console.log(reportReason, reportedPlayerId, reportedPlayerName, reporterId, roomId, "reportPlayerCheck")
  return dispatch => new Promise((resolve) => {
    Firebase.database().ref(`reportPlayers`).push({
      reportReason,
      reportedPlayerId,
      reportedPlayerName,
      reporterId,
      reporterName,
      roomId,
      reportedTime: Date.now(),
    });

    return resolve(dispatch({
      type: 'ADD_REPORT_PLAYER_REPLACE',
      data: constants.SERVER_STATUS_CODE.success,
    }));
  });
}

export function getReportedPlayersInRoom(roomId, uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise((resolve) => {
    Firebase.database().ref(`reportPlayers`)
      .orderByChild('roomId')
      .equalTo(roomId)
      .once('value', (snapshot) => {
        const reportedPlayers = snapshot.val() || {};
        return resolve(dispatch({ type: 'GET_REPORTED_PLAYERS_REPLACE', data: reportedPlayers }));
      });
  });
}

export function inviteFriendEmail(inviteEmail) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return () => new Promise((resolve) => {
    if (!UID) return null;

    const { hostname } = window.location;
    let devMode = false;

    if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
      devMode = true;
    }

    console.log('inviteFriendEmail inviteUserByEmail', devMode);

    const inviteUserByEmailFunction = Firebase.app().functions('europe-west1').httpsCallable('inviteUserByEmail');

    return inviteUserByEmailFunction({
      inviteEmail: inviteEmail.toLowerCase(),
      devMode,
    }).then((res) => {
      console.log('inviteUserByEmailFunction', res);
      return resolve({ status: res.data.status });
    });

    // Firebase.database().ref(`users`)
    //   .orderByChild('email')
    //   .equalTo(inviteEmail)
    //   .once('value', (snapshot) => {
    //     const existUser = snapshot.val() || {};
    //     console.log(size(existUser), "existCheck")
    //     if (size(existUser) === 0) {
    //       console.log(existUser, "existCheck")
    //       Firebase.database().ref(`inviteFriends`).push({
    //         inviterID: UID,
    //         inviteEmail,
    //         confirm: false,
    //         date: Date.now(),
    //       });

    //       return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    //     } else {
    //       return resolve({ status: constants.SERVER_STATUS_CODE.danger, message: errorMessages.memberExists });
    //     }
    //   });
  });
}

export function getFriends() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  const getDraugiemFriendsFunction = Firebase.app().functions().httpsCallable('getUserFriends');

  return dispatch => new Promise(resolve => {
    dispatch({ type: 'LOADING_FRIEND_LOGS', data: {}, isLoading: true });
    getDraugiemFriendsFunction({}).then((result) => {
      console.log('getUserFriends', result);

      if (result.data.status !== constants.SERVER_STATUS_CODE.error && result.data && result.data.data) {
        return resolve(dispatch({ type: 'FRIENDS', data: result.data.data, isLoading: false }));
      }
      return resolve(dispatch({ type: 'FRIENDS', data: {}, isLoading: false }));
    });
  });

  // return dispatch => new Promise(async (resolve) => {
  //   dispatch({ type: 'LOADING_FRIEND_LOGS', data: {}, isLoading: true });
  //   const getFriendsFunction = Firebase.app().functions().httpsCallable('getUserFriends');

  //   return getFriendsFunction({
  //     UID,
  //   }).then((res) => {
  //     console.log('inviteUserByEmailFunction', res.data.data);
  //     if (res.data.status === constants.SERVER_STATUS_CODE.success) {
  //       return resolve(dispatch({ type: 'FRIENDS', data: res.data.data, isLoading: false }));
  //     }
  //     return resolve(dispatch({ type: 'FRIENDS', data: {}, isLoading: false }));
  //   });
  // });
}

export function addFriend(friendId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise(async (resolve) => {
    const addFriendFunction = Firebase.app().functions().httpsCallable('addFriend');

    return addFriendFunction({
      friendId,
    }).then((res) => {
      console.log('inviteUserByEmailFunction', res);
      if (res.data.status === constants.SERVER_STATUS_CODE.success) {
        const getDraugiemFriendsFunction = Firebase.app().functions().httpsCallable('getUserFriends');
        getDraugiemFriendsFunction({}).then((result) => {
    
          if (result.data.status !== constants.SERVER_STATUS_CODE.error && result.data && result.data.data) {
            dispatch({ type: 'FRIENDS', data: result.data.data, isLoading: false });
          }
          return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
        });
        // return resolve(dispatch({ type: 'FRIENDS', data: res.data.data, isLoading: false }));
      }
      return resolve({ status: constants.SERVER_STATUS_CODE.error, message: constants.SERVER_STATUS_CODE.error });
        // return resolve(dispatch({ type: 'FRIENDS', data: {}, isLoading: false }));
    });
  });
}

export function removeFriend(friendId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise(async (resolve) => {
    FirebaseRef.child(`friends/${UID}/${friendId}`).remove();
    const getDraugiemFriendsFunction = Firebase.app().functions().httpsCallable('getUserFriends');
    return getDraugiemFriendsFunction({}).then((result) => {

      if (result.data.status !== constants.SERVER_STATUS_CODE.error && result.data && result.data.data) {
        return dispatch({ type: 'FRIENDS', data: result.data.data, isLoading: false });
      }

      return dispatch({ type: 'FRIENDS', data: {}, isLoading: false });
    }).catch(e => {
      return dispatch({ type: 'FRIENDS', data: {}, isLoading: false });
    });
  });
}


export function saveErrorCode(error) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('saveErrorCode', error);

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth() 
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;


  let userAgent = null;
  try {
    userAgent = window.navigator.userAgent;
  } catch (err) {
    userAgent = null;
  }

  console.log('saveErrorCode 2', UID, userAgent);

  const { hostname } = window.location;

  let devMode = false;

  if (hostname === 'inbox.dev.spelezoli.lv' || hostname === 'dra.dev.spelezoli.lv' || hostname === 'dev.spelezoli.lv' || hostname === 'localhost') {
    devMode = true;
  }

  if (!error.stack) {
    try {
      // IE requires the Error to actually be thrown or else the 
      // Error's 'stack' property is undefined.
      throw error;
    } catch (error) {
      if (!error.stack) {
        //return 0; // IE < 10, likely
      }
    }
  }

  const stack = error.stack.toString().split(/\r\n|\n/);

  return new Promise((resolve) => {
    Firebase.database().ref(`UIErrors`).push({
      errMessage: error.message || null,
      errCode: error.code || null,
      errStack: stack || null,
      uid: UID || null,
      userAgent: userAgent || null,
      time: Date.now(),
      hostname: hostname || null,
      devMode,
    });

    if (devMode) {
      if (hostname === 'localhost') {
        Firebase.database().ref(`UIErrorsLocalhost`).push({
          errMessage: error.message || null,
          errCode: error.code || null,
          errStack: stack || null,
          uid: UID || null,
          userAgent: userAgent || null,
          time: Date.now(),
          hostname: hostname || null,
        });
      } else {
        Firebase.database().ref(`UIErrorsDev`).push({
          errMessage: error.message || null,
          errCode: error.code || null,
          errStack: stack || null,
          uid: UID || null,
          userAgent: userAgent || null,
          time: Date.now(),
          hostname: hostname || null,
        });
      }
    }

    return resolve();
  });
}

export function checkCursorState() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => {
    const promise1 = FirebaseRef.child(`users/${UID.toString()}/cursor`).once('value');
    const promise2 = FirebaseRef.child(`users/${UID.toString()}/cursorExpire`).once('value');

    Promise.all([promise1, promise2]).then(async (promisesRes) => {
      let cursorStatus;
      let cursorExpire;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          cursorStatus = promiseRes.val() || null;
        } else if (index === 1) {
          cursorExpire = promiseRes.val() || null;
        }
        return null;
      });
      if (cursorStatus && Date.now() > cursorExpire) {
        FirebaseRef.child(`users/${UID.toString()}`).update({
          cursorExpire: null,
          cursor: null,
        });
        FirebaseRef.child(`warningPlayers/${UID.toString()}`).update({
          cursorExpire: null,
          cursor: null,
        });
      }
    });
  });
}

export function getDesignPlatforms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  
  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('designPlatforms')
      .orderByKey()
      .once('value', (userSnapshot) => {
        const designPlatforms = userSnapshot.val() || {};
        return resolve(dispatch({ type: 'GET_DESIGN_PLATFORM', data: designPlatforms }));
      }).catch(() => {
        return resolve(dispatch({ type: 'GET_DESIGN_PLATFORM', data: {} }));
      });
  });
};

export function checkFrequencyModalFunc(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${UID}`).update({
      checkFrequency: Date.now(),
    });
    console.log("call update frequency")

    return resolve();
  });
}

export function getFeedback(devMode, design, screen) {
  console.log('getFeedback');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const checkFeedback = async (key) => {
    const result = await FirebaseRef.child(`feedbackHistory/${key}/${UID}`).once('value');
    return result.val();
  };

  return dispatch => new Promise(async (resolve) => {
    await FirebaseRef.child('feedback').on('value', async (snapshot) => {
      const feedbacks = snapshot.val() || {};

      let updatedLogs = [];

      console.log('feedbacks', feedbacks);

      if (Object.keys(feedbacks).length > 0) {
        const promises = Object.entries(feedbacks).map(async ([key, item]) => {

          console.log('test', Date.now() >= item.startDate, Date.now() < item.endDate, filter(item.designs, (item) => item && item?.value === design)[0], filter(item.platforms, (item) => item && item?.value === screen)[0], (!item.devMode || (item.devMode && item.devMode === devMode)));

          if (Date.now() >= item.startDate && Date.now() < item.endDate && filter(item.designs, (item) => item && item?.value === design)[0] && filter(item.platforms, (item) => item && item?.value === screen)[0] && (!item.devMode || (item.devMode && item.devMode === devMode))) {
            const existingFeedbacks = await checkFeedback(key);

            console.log('existingFeedbacks', existingFeedbacks);

            if (!existingFeedbacks) { //  || (existingFeedbacks && size(filter(existingFeedbacks, (feed) => feed.platform === screen)[0])) === 0
              updatedLogs.push({
                ...item,
                key,
              });
            }
          }
        });
        
        await Promise.all(promises);
      }

      return resolve(dispatch({ type: 'GET_FEEDBACKS', data: updatedLogs[0] ? [updatedLogs[0]] : [] }));
    });
  });
}

export function getSurvey(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('getSurvey', devMode);

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(async (resolve) => {
    await FirebaseRef.child('surveys').orderByChild('endDate').startAt(Date.now()).endAt(Date.now() + (1000 * 60 * 60 * 24 * 365))
      .on('value', async (snapshot) => {
        const surveys = snapshot.val() || {};

      let updatedLogs = [];
      let unStartedLogs = [];
      const checkSurvey = async (key) => {
        const result = await FirebaseRef.child(`surveyHistory/${key}/${UID}`).once('value');
        return result.val();
      };

        console.log('surveyssurveys', surveys);

      const curDate = new Date();
      if (Object.keys(surveys).length > 0) {
        const promises = Object.entries(surveys).map(async ([key, item]) => {
          // key === '-O-7F52dWwZjpqZeErUW' || 
          if (Date.now() >= item.startDate && Date.now() < item.endDate) {
            const isSurvey = await checkSurvey(key);
            if (!isSurvey) {
              updatedLogs.push({
                ...item,
                key,
              });
            }
          } else if (Date.now() < item.startDate) {
            unStartedLogs.push({
              ...item,
              isPlan: true,
              key,
            });
          }
        });

        await Promise.all(promises);
      }

      console.log('updatedLogs', updatedLogs);

      return resolve(dispatch({ type: 'GET_SURVEYS', data: size(updatedLogs) === 0 ? unStartedLogs : updatedLogs }));
    });
  });
}

export function answerForSurveyQuestion(data) {
  console.log('answerForSurveyQuestion', data);
if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const {
    surveyKey,
    questionType,
    userName,
    checkedQuestionAnswer,
    additionalComment,
    // anotherAnswerOption,
    design,
  } = data;
  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`surveyHistory/${surveyKey}/${UID}`)
      .update({
        uid: UID,
        userName,
        surveyKey,
        questionType,
        checkedQuestionAnswer: questionType !== 'type1' ? checkedQuestionAnswer : null,
        // anotherAnswerOption: questionType !== 'type1' ? anotherAnswerOption : null,
        additionalComment: questionType === 'type1' ? additionalComment : null,
        design,
        date: Date.now(),
      }).then(async (res) => {
        await FirebaseRef.child(`surveyHistory/${surveyKey}`).once('value', (userSnapshot) => {
          const surveyResults = userSnapshot.val() || {};

          return resolve({ status: constants.SERVER_STATUS_CODE.success, data: surveyResults });
        })
      }).catch(() => {
        return resolve({ status: constants.SERVER_STATUS_CODE.error, data: {} });
      });
  });
}

export function checkedIsSuveyFunc(surveyKey) {
if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  if (!surveyKey) return () => new Promise(resolve => resolve());
  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`surveyHistory/${surveyKey}/${UID}`)
      .once('value', (userSnapshot) => {
        const userSurvey = userSnapshot.val() || {};
        console.log(userSurvey, "userSurvey============")
        const checkIsSurvey = (!userSurvey || size(userSurvey) === 0) || false;

        return resolve(dispatch({ type: 'CHECK_USER_SURVEY', data: checkIsSurvey }));
      }).catch(() => {
        return resolve(dispatch({ type: 'CHECK_USER_SURVEY', data: false }));
      });
  });
}

export function clickCampaign(campaignKey, location, clickedDesign, devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('clickCampaign', campaignKey, location, clickedDesign, devMode);

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  if (!campaignKey) return () => new Promise(resolve => resolve());

  let platform = '';
  const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
  const isInFacebooxAppFrame = config.isInFacebookAppFrame();
  const isInInboxAppFrame = config.isInInboxAppFrame();

  if (isMobile) {
    platform = constants.MOBILE_PLATFORM;
  } else if (isInDraugiemAppFrame) {
    platform = constants.DRAUGIEM_PLATFORM;
  } else if (isInFacebooxAppFrame) {
    platform = constants.FACEBOOK_PLATFORM;
  } else if (isInInboxAppFrame) {
    platform = constants.INBOX_PLATFORM;
  } else {
    platform= constants.WEB_PLATFORM;
  }

  if (!platform) return; 

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`campaignHistory/${campaignKey}`).push({
      key: campaignKey,
      uid: UID,
      clickedPlatform: platform,
      clickedLocation: location,
      clickedDesign,
      clickedEnv: devMode ? 'dev' : 'prod',
      clickedDate: Date.now(),
    })

    return resolve();
  });
}

export function getNewHelpSections() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(async (resolve) => {
    FirebaseRef.child('helpEditor').once('value', (snapshot) => {
      const helpSections = snapshot.val();
      console.log(helpSections, "=checkHelpSections")

      return resolve(dispatch({ type: 'GET_NEW_HELP_REPLACE', data: helpSections }));

    });
  });
}
