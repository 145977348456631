import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';
import { getTournamentPlayersAlias } from '../../../utils/tournamentUtils';
import PaginationOld from './PaginationOld';

class TournamentLeaderboard extends React.Component {
  static propTypes = {
    leaderboard: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    uid: PropTypes.string,
    tournament: PropTypes.shape(),
  }

  static defaultProps = {
    uid: null,
  }

  constructor(props) {
    super(props);
    this.state = {
    //  currentPage: 0,
    //  pageSize: 20,
      leaderboard: [],
      scrollPos: 0,
      currentPage: 0,
      pageSize: 20,
    };
  }

  componentWillMount() {
    const { leaderboard, uid } = this.props;

    if (leaderboard) {
    //  let playerResult;
      //  const leaderboardArr = [];

      console.log('leaderboard', leaderboard);

      const leaderboardArr = Object.keys(leaderboard).map(key => ({
        uid: key,
        name: leaderboard[key].name,
        photo: leaderboard[key].photo,
        totalRoomPoints: leaderboard[key].totalRoomPoints,
        tournamentPoints: leaderboard[key].tournamentPoints,
        lvl: leaderboard[key].lvl,
        joinTime: leaderboard[key].joinTime,
        winnings: leaderboard[key].winnings,
        finalPosition: leaderboard[key].finalPosition,
        entryFee: leaderboard[key].entryFee || 0,
      }));

      leaderboardArr.sort((a, b) => {
        if (b.tournamentPoints !== a.tournamentPoints) {
          return (b.tournamentPoints - a.tournamentPoints);
        }

        if (b.totalRoomPoints !== a.totalRoomPoints) {
          return (b.totalRoomPoints - a.totalRoomPoints);
        }

        return (a.joinTime - b.joinTime);
      });

      const playerIndex = leaderboardArr.findIndex(element => element.uid === uid);

      let playerResult;
      if (playerIndex !== -1) {
        playerResult = { ...leaderboardArr[playerIndex], pos: playerIndex + 1 };

      //  leaderboardArr.splice(playerIndex, 1);
      }

      this.setState({ leaderboard: [...leaderboardArr], playerLeaderboard: playerResult ? { ...playerResult } : null });
    }
  }


  componentWillReceiveProps(nextProps) {
    const { leaderboard, uid } = nextProps;

    if (leaderboard) {
    //  let playerResult;
      const leaderboardArr = Object.keys(leaderboard).map(key => ({
        uid: key,
        name: leaderboard[key].name,
        photo: leaderboard[key].photo,
        totalRoomPoints: leaderboard[key].totalRoomPoints,
        tournamentPoints: leaderboard[key].tournamentPoints,
        lvl: leaderboard[key].lvl,
        joinTime: leaderboard[key].joinTime,
        winnings: leaderboard[key].winnings,
        finalPosition: leaderboard[key].finalPosition,
        entryFee: leaderboard[key].entryFee || 0,
      }));

      leaderboardArr.sort((a, b) => {
        if (b.tournamentPoints !== a.tournamentPoints) {
          return (b.tournamentPoints - a.tournamentPoints);
        }

        if (b.totalRoomPoints !== a.totalRoomPoints) {
          return (b.totalRoomPoints - a.totalRoomPoints);
        }

        return (a.joinTime - b.joinTime);
      });

      const playerIndex = leaderboardArr.findIndex(element => element.uid === uid);

      let playerResult;
      if (playerIndex !== -1) {
        playerResult = { ...leaderboardArr[playerIndex], pos: playerIndex + 1 };

      //  leaderboardArr.splice(playerIndex, 1);
      }
      let finalPositionsAvailable = 0;
      for (const player of leaderboardArr) {
        if (player.finalPosition) {
          finalPositionsAvailable++;
        }
      }

      this.setState({ finalPositionsAvailable, leaderboard: [...leaderboardArr], playerLeaderboard: playerResult ? { ...playerResult } : null });
    }
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  handlePagination = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  render() {
    const {
      t,
      uid,
      tournament,
    } = this.props;

    const {
    //  currentPage,
    //  pageSize,
      leaderboard,
      scrollPos,
      playerLeaderboard,
      finalPositionsAvailable,
      currentPage,
      pageSize,
    } = this.state;

    //  const pagesCount = Math.ceil(leaderboard.length / pageSize);

    //  leaderboard && leaderboard.slice(
    //    currentPage * pageSize,
    //    (currentPage + 1) * pageSize,
    //  )
    //    .map((leaderboardPlayer, index) => (

    // console.log('call getTournamentPlayersAlias', tournament);

    console.log('leaderboardleaderboard', leaderboard, playerLeaderboard);

    const alias = getTournamentPlayersAlias(tournament);
    let pagesCount = Math.ceil(leaderboard.length / pageSize);

    return (
      <Fragment>
        {/*  <ScrollAreaButtons
          scrollPos={scrollPos}
        //  show={this.tableScrollbar && this.tableScrollbar.state && this.tableScrollbar.state.containerHeight < this.tableScrollbar.state.realHeight ? true : null}
          show={leaderboard && leaderboard.length > 7 ? true : null}
          scroll={this.scroll}
          speed={30}
          rightOffset={13}
          topOffset={2}
          bottomOffset={2}
        />
        <ScrollArea
          speed={0.55}
          className="tournaments-scrollarea"
          contentClassName="tournaments-scrollarea-body"
          smoothScrolling
          stopScrollPropagation
          verticalContainerStyle={{
            background: 'transparent',
            opacity: 1,
            width: 11,
          }}
          verticalScrollbarStyle={{
            background: '#fff',
            borderRadius: 1,
            width: 10,
            minHeight: 10,
            minScrollSize: 25,
          }}
          horizontal={false}
          onScroll={this.updateScrollPos}
          ref={(el) => {
            this.tableScrollbar = el;
          }}
        > */}
        {finalPositionsAvailable === 0 && (!tournament || !tournament.currentState) ? (
          <div className="d-flex align-items-center h-100">
            {t('tournaments.cancelled')}
          </div>
        ) : (
          <>
            <table className="tournaments-leaderboard">
              <thead>
                <tr>
                  <th className="tournaments-leaderboard-table-header-pos">
                    {alias === 'before' ? '#' : t('tournaments.position')}
                  </th>
                  <th className="tournaments-leaderboard-table-header-name">
                    {t('tournaments.name')}
                  </th>
                  <th className="tournaments-leaderboard-table-header">
                    {t('tournaments.tournamentPoints')}
                  </th>
                  <th className="tournaments-leaderboard-table-header">
                    {t('tournaments.totalRoomPoints')}
                  </th>
                  <th className="tournaments-leaderboard-table-header">
                    {t('tournaments.winnings')}
                  </th>
                  {/* <th className="tournaments-leaderboard-table-header">
                    {t('tournaments.entryFee')}
                  </th> */}
                </tr>
              </thead>
              <tbody style={{visibility: 'collapse'}}>
                  {playerLeaderboard && (
                    <tr key={`${playerLeaderboard.uid}-player`} className="tournaments-leaderboard-table-row-user">
                      <td className="tournaments-leaderboard-table-col-pos">
                        {playerLeaderboard.finalPosition ? playerLeaderboard.finalPosition : playerLeaderboard.pos}
                      </td>
                      <td className="tournaments-leaderboard-table-col-name">
                        <div style={{ display: 'inline-block' }}>
                          {playerLeaderboard.name}
                        </div>
                      </td>
                      <td className="tournaments-leaderboard-table-col">
                        {playerLeaderboard.tournamentPoints}
                      </td>
                      <td className="tournaments-leaderboard-table-col">
                        {playerLeaderboard.totalRoomPoints}
                      </td>
                      <td className="tournaments-leaderboard-table-col">
                        {(alias === 'before' || alias === 'active') ? '' : (playerLeaderboard.winnings || 0)}
                      </td>
                      {/* <td className="tournaments-leaderboard-table-col">
                        {playerLeaderboard.entryFee}
                      </td> */}
                    </tr>
                  )}
                  {leaderboard && leaderboard.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((leaderboardPlayer) => {
                    return (
                      <tr key={leaderboardPlayer.uid} className="tournaments-leaderboard-table-row">
                        <td className="tournaments-leaderboard-table-col-pos">
                          {/*  {index + 1} */}
                          {leaderboardPlayer.finalPosition ? leaderboardPlayer.finalPosition : 0}
                        </td>
                        <td className="tournaments-leaderboard-table-col-name">
                          <div style={{ display: 'inline-block' }}>
                            {leaderboardPlayer.name}
                          </div>
                        </td>
                        <td className="tournaments-leaderboard-table-col">
                          {leaderboardPlayer.finalPosition ? leaderboardPlayer.tournamentPoints : 0}
                        </td>
                        <td className="tournaments-leaderboard-table-col">
                          {leaderboardPlayer.finalPosition ? leaderboardPlayer.totalRoomPoints : 0}
                        </td>

                        <td className="tournaments-leaderboard-table-col">
                          {(alias === 'before' || alias === 'active') ? '' : (leaderboardPlayer.winnings || 0)}
                        </td>
                        {/* <td className="tournaments-leaderboard-table-col">
                          {leaderboardPlayer.entryFee || 0}
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <ScrollAreaWrapper
              className="tournaments-results-scrollarea"
              contentClassName="tournaments-scrollarea-body"
              show
              rightOffset={13}
              topOffset={2}
              bottomOffset={2}
            >
              <table className="tournaments-leaderboard">
                <thead style={{visibility: 'collapse'}}>
                  <tr>
                    <th className="tournaments-leaderboard-table-header-pos">
                      {alias === 'before' ? '#' : t('tournaments.position')}
                    </th>
                    <th className="tournaments-leaderboard-table-header-name">
                      {t('tournaments.name')}
                    </th>
                    <th className="tournaments-leaderboard-table-header">
                      {t('tournaments.tournamentPoints')}
                    </th>
                    <th className="tournaments-leaderboard-table-header">
                      {t('tournaments.totalRoomPoints')}
                    </th>
                    <th className="tournaments-leaderboard-table-header">
                      {t('tournaments.winnings')}
                    </th>
                    {/* <th className="tournaments-leaderboard-table-header">
                      {t('tournaments.entryFee')}
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {(playerLeaderboard && (alias === 'active' || alias === 'complete')) && (
                    <tr key={`${playerLeaderboard.uid}-player`} className="tournaments-leaderboard-table-row-user">
                      <td className="tournaments-leaderboard-table-col-pos">
                        {playerLeaderboard.finalPosition ? (playerLeaderboard.finalPosition) : (playerLeaderboard.pos)}
                      </td>
                      <td className="tournaments-leaderboard-table-col-name">
                        <div style={{ display: 'inline-block' }}>
                          {playerLeaderboard.name}
                        </div>
                      </td>
                      <td className="tournaments-leaderboard-table-col">
                        {playerLeaderboard.tournamentPoints}
                      </td>
                      <td className="tournaments-leaderboard-table-col">
                        {playerLeaderboard.totalRoomPoints}
                      </td>
                      <td className="tournaments-leaderboard-table-col">
                        {(alias === 'before' || alias === 'active') ? '' : (playerLeaderboard.winnings || 0)}
                      </td>
                      {/* <td className="tournaments-leaderboard-table-col">
                        {playerLeaderboard.entryFee}
                      </td> */}
                    </tr>
                  )}
                  {leaderboard && leaderboard.map((leaderboardPlayer, index) => {
                    return (
                      <tr key={leaderboardPlayer.uid} className="tournaments-leaderboard-table-row">
                        <td className="tournaments-leaderboard-table-col-pos">
                          {/*  {index + 1} */}
                          {alias === 'before' ? (index + 1) : (leaderboardPlayer.finalPosition ? leaderboardPlayer.finalPosition : 0)}
                        </td>
                        <td className="tournaments-leaderboard-table-col-name">
                          <div style={{ display: 'inline-block' }}>
                            {leaderboardPlayer.name}
                          </div>
                        </td>
                        <td className="tournaments-leaderboard-table-col">
                          {leaderboardPlayer.finalPosition ? leaderboardPlayer.tournamentPoints : 0}
                        </td>
                        <td className="tournaments-leaderboard-table-col">
                          {leaderboardPlayer.finalPosition ? leaderboardPlayer.totalRoomPoints : 0}
                        </td>

                        <td className="tournaments-leaderboard-table-col">
                          {(alias === 'before' || alias === 'active') ? '' : (leaderboardPlayer.winnings || 0)}
                        </td>
                        {/* <td className="tournaments-leaderboard-table-col">
                          {leaderboardPlayer.entryFee || 0}
                        </td> */}
                      </tr>
                    );
                  })}
                  {/*  {Object.keys(leaderboard).length > pageSize && (
                  <div className="pagination-wrapper">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem disabled={currentPage <= 0}>
                        <PaginationLink
                          onClick={e => this.handleClick(e, currentPage - 1)}
                          previous
                          href="#"
                        />
                      </PaginationItem>

                      <PaginationItem disabled={currentPage < 6}>
                        <PaginationLink onClick={e => this.handleClick(e, 0)} href="#">
                            1
                        </PaginationLink>
                      </PaginationItem>

                      {[...Array(pagesCount)].map((page, i) => {
                        if (i > currentPage - 3 && i < currentPage + 3) {
                          return (
                            <PaginationItem active={i === currentPage} key={page}>
                              <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                                {i + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }

                        return null;
                      })}

                      {currentPage < (pagesCount - 3) && (
                      <PaginationItem disabled={currentPage >= pagesCount - 1}>
                        <PaginationLink onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                          {pagesCount}
                        </PaginationLink>
                      </PaginationItem>
                      )}

                      <PaginationItem disabled={currentPage >= pagesCount - 1}>
                        <PaginationLink
                          onClick={e => this.handleClick(e, currentPage + 1)}
                          next
                          href="#"
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                )}  */}
                </tbody>
              </table>
            </ScrollAreaWrapper>
            <PaginationOld
              show={leaderboard.length > pageSize}
              currentPage={currentPage}
              pagesCount={pagesCount}
              handlePagination={this.handlePagination}
            />
          </>
        )}

      </Fragment>
    );
  }
}

export default withTranslation('common')(TournamentLeaderboard);
