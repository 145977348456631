import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';

import coin from '../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../images/redesign/common/rating.svg';

import ScrollAreaNew from '../../Components/ScrollAreaNew';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';
import { SCREEN_MODE } from '../../../../../constants/constants';
import HelpIcon from '../../../../../images/icons/help2.svg';
import HelpLightIcon from '../../../../../images/redesign/light-mode/player/info.svg';
import EmotionIcon from '../../../../../images/redesign/player/emotions.svg';
import EmotionLightIcon from '../../../../../images/redesign/light-mode/player/emotions.svg';
import * as constants from '../../../../../constants/constants';
import { getTournamentPlayersAlias } from '../../../../../utils/tournamentUtils';

import MenuRow from '../../Components/Components/MenuRow';

class TournamentPlayers extends React.Component {
  static propTypes = {
    players: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    uid: PropTypes.string,
    screenMode: PropTypes.string,
    tournament: PropTypes.shape(),
  }

  static defaultProps = {
    uid: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      //  currentPage: 0,
      //  pageSize: 20,
      leaderboard: [],
      scrollPos: 0,
    };
  }

  componentWillMount() {
    const { leaderboard, uid } = this.props;

    /*  if (leaderboard) {
        const leaderboardArr = Object.keys(leaderboard).map(key => ({
          uid: key,
          name: leaderboard[key].name,
          photo: leaderboard[key].photo,
          totalRoomPoints: leaderboard[key].totalRoomPoints,
          tournamentPoints: leaderboard[key].tournamentPoints,
          lvl: leaderboard[key].lvl,
          joinTime: leaderboard[key].joinTime,
          winnings: leaderboard[key].winnings,
        }));
  
        leaderboardArr.sort((a, b) => {
          if (b.tournamentPoints !== a.tournamentPoints) {
            return (b.tournamentPoints - a.tournamentPoints);
          }
  
          if (b.totalRoomPoints !== a.totalRoomPoints) {
            return (b.totalRoomPoints - a.totalRoomPoints);
          }
  
          return (a.joinTime - b.joinTime);
        });
  
        const playerIndex = leaderboardArr.findIndex(element => element.uid === uid);
  
        let playerResult;
        if (playerIndex !== -1) {
          playerResult = { ...leaderboardArr[playerIndex], pos: playerIndex + 1 };
        }
  
        this.setState({ leaderboard: [...leaderboardArr], playerLeaderboard: playerResult ? { ...playerResult } : null });
      }  */
  }


  componentWillReceiveProps(nextProps) {
    const { leaderboard, uid } = nextProps;

    /*  if (leaderboard) {
        const leaderboardArr = Object.keys(leaderboard).map(key => ({
          uid: key,
          name: leaderboard[key].name,
          photo: leaderboard[key].photo,
          totalRoomPoints: leaderboard[key].totalRoomPoints,
          tournamentPoints: leaderboard[key].tournamentPoints,
          lvl: leaderboard[key].lvl,
          joinTime: leaderboard[key].joinTime,
          winnings: leaderboard[key].winnings,
        }));
  
        leaderboardArr.sort((a, b) => {
          if (b.tournamentPoints !== a.tournamentPoints) {
            return (b.tournamentPoints - a.tournamentPoints);
          }
  
          if (b.totalRoomPoints !== a.totalRoomPoints) {
            return (b.totalRoomPoints - a.totalRoomPoints);
          }
  
          return (a.joinTime - b.joinTime);
        });
  
        const playerIndex = leaderboardArr.findIndex(element => element.uid === uid);
  
        let playerResult;
        if (playerIndex !== -1) {
          playerResult = { ...leaderboardArr[playerIndex], pos: playerIndex + 1 };
        }
  
  
        this.setState({ leaderboard: [...leaderboardArr], playerLeaderboard: playerResult ? { ...playerResult } : null });
      }  */
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  render() {
    const {
      t,
      uid,
      players,
      screenMode,
      tournament,
    } = this.props;

    console.log('tournamentplayers players', players);

    //  const {
    //  leaderboard,
    //  scrollPos,
    //  playerLeaderboard,
    //  } = this.state;
    let playersValues = players ? Object.values(players) : [];

    if (tournament && tournament.currentState && tournament.currentState === 'registration' || tournament.currentState === 'pending') {
      playersValues.sort((a, b) => a.joinTime - b.joinTime);
    } else {
      playersValues.sort((a, b) => a.finalPosition - b.finalPosition);
    }
    const currentPlayer = players ? players[uid] : null;

    console.log('call getTournamentPlayersAlias', tournament, playersValues);

    const alias = getTournamentPlayersAlias(tournament);
    let currentPlayerIndex = currentPlayer ? playersValues.findIndex(player => player.uid === currentPlayer.uid) : null;

    return (
      <Fragment>
        {/* <div style={{overflow: 'hidden', scrollbarGutter: 'stable'}}>
           <Row className="tournament-top-row">
          <Col xs="2" className="tournament-top-header-col">{alias === 'before' ? '#' : t('tournaments.position')}</Col>
          <Col xs="4" className="tournament-top-header-col text-left">{t('tournaments.name')}</Col>
          <Col xs="2" className="tournament-top-header-col">{t('tournaments.tournamentPoints')}</Col>
          <Col xs="2" className="tournament-top-header-col">{t('tournaments.totalRoomPoints')}</Col>
          <Col xs="2" className="tournament-top-header-col">{t('tournaments.winnings')}</Col>
        </Row>
        {currentPlayer && (
          <Row key={`${currentPlayer.uid}-player`} className="tournament-top-row">
            <Col xs="2" className={`tournament-top-player-col tournament-top-player-col-${screenMode}`}>
              {alias === "before" ? (currentPlayerIndex + 1) : (currentPlayer.finalPosition ? currentPlayer.finalPosition : 0)}
            </Col>
            <Col xs="4" className={`tournament-top-player-col tournament-top-player-col-${screenMode} text-left`}>
              {currentPlayer.name}
            </Col>
            <Col xs="2" className={`tournament-top-player-col tournament-top-player-col-${screenMode}`}>
              {currentPlayer.finalPosition ? currentPlayer.tournamentPoints : 0}
            </Col>
            <Col xs="2" className={`tournament-top-player-col tournament-top-player-col-${screenMode}`}>
              {currentPlayer.finalPosition ? currentPlayer.totalRoomPoints : 0}
            </Col>
            <Col xs="2" className={`tournament-top-player-col tournament-top-player-col-${screenMode}`}>
              {/* {currentPlayer.finalPosition ? (currentPlayer.winnings || 0) : 0}
            </Col>
          </Row>
        )}
        </div>
        <ScrollAreaNew customClassName="tournaments-players-scrollarea">
          {playersValues.map((player, index) => (
            <Row key={player.uid} className="tournament-top-row-scroll">
              <Col xs="2" className="tournament-top-col">
                {alias === 'before' ? (index + 1) : (player.finalPosition ? player.finalPosition : 0)}
              </Col>
              <Col xs="4" className="tournament-top-col text-left">
                {player.name}
              </Col>
              <Col xs="2" className="tournament-top-col">
                {player.finalPosition ? player.tournamentPoints : 0}
              </Col>
              <Col xs="2" className="tournament-top-col">
                {player.finalPosition ? player.totalRoomPoints : 0}
              </Col>
              <Col xs="2" className="tournament-top-col">
                {/* {player.finalPosition ? (player.winnings || 0) : 0}
              </Col>
            </Row>
          ))}
        </ScrollAreaNew> */}

        <div className={`tournament-leaderboard-top3-scroll-box ${false ? 'tournament-leaderboard-top3-scroll-box-in-app-frame' : 'h-auto'}`}>
            <Row className="tournament-leaderboard-table-wrapper">
              <Col xs="12">

                <MenuRow compactHeight customRowClassName="px-0" contentClassName={`tournament-top-menu-row-inner-wrapper-${screenMode}`}>
                  <Row className="font-weight-bold">
                    <Col xs="2" style={{ maxWidth: '13.5%' }}>
                      <div className="text-center">
                        {t('tournaments.position')}
                      </div>
                    </Col>
                    <Col>
                      {t('tournaments.name')}
                    </Col>
                    <Col className="text-center">
                      {t('tournaments.tournamentPoints')}
                    </Col>
                    <Col className="text-center">
                      {t('tournaments.totalRoomPoints')}
                    </Col>
                  </Row>
                </MenuRow>
                 {(currentPlayer && alias === 'active') && (
                  <div className="tournament-scrollarea tournament-leaderboard" style={{ height: 'unset' }}>
                    <MenuRow key={currentPlayer.uid} compactHeight customRowClassName="px-0" contentClassName={`tournament-top-menu-row-inner-wrapper-${screenMode}-first`}>
                      <Row key={currentPlayer.uid}>
                        <Col xs="2" style={{ maxWidth: '13.5%' }}>
                          <div className="text-center">
                            {currentPlayer.finalPosition}
                          </div>
                        </Col>
                        <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {currentPlayer.name}
                        </Col>
                        <Col className="text-center">
                          {currentPlayer.tournamentPoints}
                        </Col>
                        <Col className="text-center">
                          {currentPlayer.totalRoomPoints}
                        </Col>
                      </Row>
                    </MenuRow>
                  </div>
                )}
                <div
                  className="tournament-players-scrollarea"
                  show
                  rightOffset={15}
                  topOffset={40}
                  bottomOffset={0}
                >
                  {playersValues && playersValues.length ? (playersValues.map((leaderboardPlayer, index) => (
                    <MenuRow key={leaderboardPlayer.uid} compactHeight customRowClassName="px-0" contentClassName={`tournament-top-menu-row-inner-wrapper-${screenMode}`}>
                      <Row key={leaderboardPlayer.uid}>
                        <Col xs="2" style={{ maxWidth: '13.5%' }}>
                          <div className="text-center">
                            {leaderboardPlayer.finalPosition ? leaderboardPlayer.finalPosition : index + 1}
                          </div>
                        </Col>
                        <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {leaderboardPlayer.name}
                        </Col>
                        <Col className="text-center">
                          {leaderboardPlayer.tournamentPoints}
                        </Col>
                        <Col className="text-center">
                          {leaderboardPlayer.totalRoomPoints}
                        </Col>
                      </Row>
                    </MenuRow>
                  ))) : (
                    <div />
                  )}
                </div>
              </Col>
            </Row>
        </div>

        {/* <div
          className="tournament-scrollarea"
          contentClassName="tournament-leaderboard"
        >
          <Row className="tournament-players">
            {players && Object.keys(players).map(key => (
              <Col xs="4" sm="4" key={players[key].uid} className="tournament-players-player">
                <div className="tournament-player-info">

                  <div className="tournament-player-info-background">
                    <div className="tournament-player-info-background-shadow" />
                    <div className="tournament-player-info-background-shadow2" />
                  </div>

                  <div className="tournament-player-info-player">
                    <div className="tournament-player-info-player-background">
                      <div className="tournament-player-info-player-image-wrapper">
                        <div className="tournament-player-info-player-image-background" />
                        <div style={{ backgroundImage: players[key].photo ? `url(${players[key].photo})` : (screenMode === SCREEN_MODE.light ? `url(${lightDefaultImage})` : `url(${defaultImage})`), backgroundPosition: 'center', backgroundSize: players[key].photo ? '100%, 100%' : '80%, 93%' }} className="tournament-player-info-player-image" />
                        <div className="tournament-player-info-player-image-overlay" />
                        <Media src={screenMode === constants.SCREEN_MODE.light ? HelpLightIcon : HelpIcon} className='player-info-help-icon d-none' />
                        <Media src={screenMode === constants.SCREEN_MODE.light ? EmotionLightIcon : EmotionIcon} className='player-info-player-image-emotion d-none' />
                        <div className='player-info-player-mark d-none'>
                            Viduvējs
                        </div>
                      </div>
                      <div className="tournament-player-info-player-name-wrapper">
                        <div className="tournament-player-info-player-name">
                          {players[key] ? players[key].name : ''}
                        </div>
                      </div>
                      <div className="tournament-player-info-player-wrapper">
                        <Media src={coin} className="tournament-player-info-player-balance-icon" />
                        <div className="tournament-player-info-player-balance">
                          {players[key] ? (players[key].balance || 0) : '0'}
                        </div>
                        <Media src={rating} className="tournament-player-info-player-rating-icon" />
                        <div className="tournament-player-info-player-rating">
                          {players[key] ? (players[key].rating || 0) : '0'}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div> */}
      </Fragment>
    );
  }
}

export default withTranslation('common')(TournamentPlayers);
