import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// import Button from 'reactstrap/lib/Button';

const ScoreTableRow = React.memo(({
  player1,
  player2,
  player3,
  player4,
  pule,
  player1Name,
  player2Name,
  player3Name,
  player4Name,
  index,
}) => (
  <Fragment>
    <td style={{ width: '10%' }}><div>{index}</div></td>
    <td style={{ width: '20%' }}>
      <Fragment>
        <div>
          {player1}
        </div>
      </Fragment>
    </td>
    <td style={{ width: '20%' }}>
      <Fragment>
        <div>
          {player2}
        </div>
      </Fragment>
    </td>
    <td style={{ width: '20%' }}>
      <Fragment>
        <div>
          {player3}
        </div>
      </Fragment>
    </td>
    {(player4Name) ? (
      <td style={{ width: '20%' }}>
        <Fragment>
          <div>
            {player4}
          </div>
        </Fragment>
      </td>
    ) : (null)}
    <td style={{ width: '10%' }}>
      {pule === 'P' && (
        <div> P </div>
      )}
      {pule === 'P-' && (
        <span className="strikeout-new-normal"> P </span>
      )}
      {pule === 'player1' && (
        <div>
          { player1Name }
        </div>
      )}
      {pule === 'player1-' && (
        <span className="strikeout-new-normal">
          { player1Name }
        </span>
      )}
      {pule === 'player2' && (
        <div>
          { player2Name }
        </div>
      )}
      {pule === 'player2-' && (
        <span className="strikeout-new-normal">
          { player2Name }
        </span>
      )}
      {pule === 'player3' && (
        <div>
          { player3Name }
        </div>
      )}
      {pule === 'player3-' && (
        <span className="strikeout-new-normal">
          { player3Name }
        </span>
      )}
      {pule === 'player4' && (
        <div>
          { player4Name }
        </div>
      )}
      {pule === 'player4-' && (
        <span className="strikeout-new-normal">
          { player4Name }
        </span>
      )}
    </td>
  </Fragment>
));

ScoreTableRow.propTypes = {
  player1: PropTypes.number,
  player2: PropTypes.number,
  player3: PropTypes.number,
  pule: PropTypes.string,
  player1ShortName: PropTypes.string,
  player2ShortName: PropTypes.string,
  player3ShortName: PropTypes.string,
  index: PropTypes.number,
  roundIndex: PropTypes.number,
};

ScoreTableRow.defaultProps = {
  player1: null,
  player2: null,
  player3: null,
  pule: null,
  player1ShortName: null,
  player2ShortName: null,
  player3ShortName: null,
  index: null,
  roundIndex: null,
};

export default ScoreTableRow;
