import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';

import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';

import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';

import CustomPopover from '../../Components/Components/CustomPopover';

import * as constants from '../../../../../constants/constants';

import coin from '../../../../../images/redesign/common/coin.svg';
import rating from '../../../../../images/redesign/common/rating.svg';
import UpdateProfile from '../../User/UpdateProfile';
import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';
//
const PlayerProfile = React.memo(({
  member,
  requestDeleteUser,
  updateProfile,
  userAvatarFileUpload,
  toggle,
  balance,
  activeGifts,
  gifts,
  screenMode,
  toggleScreenMode,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const STYLE_PLAYER_BALANCE = classNames({
    'player-info-player-balance': balance > constants.PLAYER_ZOLE_RANGE.min,
    'player-info-player-balance-orange': balance >= constants.PLAYER_ZOLE_RANGE.maxmin && balance <= constants.PLAYER_ZOLE_RANGE.min,
    'player-info-player-balance-red': balance < constants.PLAYER_ZOLE_RANGE.maxmin,
  });

  const STYLE_COIN_BLINK = classNames('ml-2', {
    'player-info-player-balance-icon': balance > constants.PLAYER_ZOLE_RANGE.min,
    'player-info-player-balance-icon blink': balance <= constants.PLAYER_ZOLE_RANGE.min,
  });

  const onHover = () => {
    if (balance <= constants.PLAYER_ZOLE_RANGE.min) {
      setPopoverOpen(true);
    }
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  const switchTab = (key) => {
    toggle(key);
  };

  console.log('player profile');

  return (
    <div className={`player-info player-info-${screenMode}`}>
      <div className="player-info-background">
        <div className={`player-info-background-shadow player-info-background-shadow-${screenMode}`} />
        <div className={`player-info-background-shadow2 player-info-background-shadow2-${screenMode}`} />
      </div>


      <div className="player-info-player player-info-player-layout">
        <div className="player-info-player-background">
          <div className="player-info-player-image-wrapper">
            <UpdateProfile screenMode={screenMode} toggleScreenMode={toggleScreenMode} userAvatarFileUpload={userAvatarFileUpload} member={member} requestDeleteUser={requestDeleteUser} updateProfile={updateProfile} checkEmail={true}/>
            <div className="player-info-player-image-background" />
            <div style={{ backgroundImage: member.photo ? `url(${member.photo})` : (screenMode === constants.SCREEN_MODE.light ? `url(${lightDefaultImage})` : `url(${defaultImage})`), backgroundPosition: 'center', backgroundSize: member.photo ? '100%, 100%' : '80%, 93%' }} className="player-info-player-image" />
            {/* <div className="player-info-player-image-overlay" /> */}
          </div>
          <div className="player-info-player-name-wrapper">
            <div className="player-info-player-name">
              {member ? member.name : ''}
            </div>
          </div>
          <div className="player-info-player-wrapper player-info-player-balance-wrapper">
            <div className="player-info-player-balance-wrapper-section" onMouseEnter={() => onHover()} onMouseLeave={() => onHoverLeave()}>
                <Media
                  src={coin}
                  className={STYLE_COIN_BLINK}
                  id="player-info-player-balance-popover"
                />
                <div className={STYLE_PLAYER_BALANCE}>
                  {balance}
                </div>
              <CustomPopover onHover={onHover} onHoverLeave={onHoverLeave} switchTab={switchTab} popoverOpen={popoverOpen} balance={balance} screenMode={screenMode}/>
              <Media src={rating} className="player-info-player-rating-icon" />
              <div className="player-info-player-rating mr-2">
                {member ? (member.rating || 0) : 0}
              </div>
            </div>
          </div>
          {
            gifts && activeGifts && get(activeGifts, 'giftId', '') && (
              <>
                <img className="player-info-player-background-active-gift" id="player-info-active-gift" src={gifts[activeGifts.giftId].image} alt="x" />
                <UncontrolledTooltip
                  className="player-receivedGift-tooltip-new"
                  placement="bottom"
                  target='player-info-active-gift'
                  container={'div > div'}
                  //trigger="click" // use for debug only
                >
                  <div className="player-receivedGift-tooltip-from">
                    {activeGifts.fromName || ''}
                  </div>
                  <div className="player-receivedGift-tooltip-comment">
                    {activeGifts.comment || ''}
                  </div>
                </UncontrolledTooltip>
              </>
            )
          }
        </div>
      </div>

    </div>
  );
});

PlayerProfile.propTypes = {
  member: PropTypes.shape(),
  toggle: PropTypes.func.isRequired,
  balance: PropTypes.number,
  activeGifts: PropTypes.shape({}),
  gifts: PropTypes.shape({}),
  screenMode: PropTypes.string,
  toggleScreenMode: PropTypes.func.isRequired,
};

PlayerProfile.defaultProps = {
  member: null,
  activeGifts: null,
  gifts: null,
};

const mapStateToProps = state => ({
  member: state.member,
  balance: state.member.balance || 0,
  activeGifts: state.member.activeGifts || null,
  gifts: state.rooms.gifts,
});

export default connect(mapStateToProps, null)(PlayerProfile);
